<template>
  <div
    class="horizontal-product-list mx-auto"
    style="width: 100%; max-width: 988px"
  >
    <UiNavigationTitle class="px-4 md:px-0" :navigation="navigation" main>
      <slot name="title">
        {{ $t('categoryName') }}
      </slot>
    </UiNavigationTitle>
    <SamplingPromoListDesktop
      v-if="show && $isDesktop"
      class="mt-5 md:mt-4"
      :products="products"
      :added-from="addedFrom"
      :navigation="navigation"
      @onCardsViewed="$emit('onCardsViewed', $event)"
    />
    <SamplingPromoListMobile v-else :products="products" />
  </div>
</template>

<script>
import UiNavigationTitle from '@ui/UiNavigationTitle.vue';

import SamplingPromoListMobile from '@components/samplings/SamplingPromoListMobile/SamplingPromoListMobile.vue';
import SamplingPromoListDesktop from '@components/samplings/SamplingPromoListDesktop/SamplingPromoListDesktop.vue';

export default {
  name: 'SamplingPromoListWrapper',
  components: {
    UiNavigationTitle,
    SamplingPromoListMobile,
    SamplingPromoListDesktop,
  },
  props: {
    navigation: {
      type: Object,
      default: () => {
        return { name: 'main' };
      },
    },
    products: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      timer: null,
      show: true,
    };
  },
  watch: {
    products: {
      immediate: true,
      deep: true,
      handler() {
        // вот этот костыль для того
        // что б свайпер нормально обновлялся при изменений слайдов
        // возможно нужно пересмотреть когда будет добавлять скелетоны
        this.showAgain();
      },
    },
  },
  methods: {
    showAgain() {
      this.show = false;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.show = true;
      }, 0);
    },
  },
  i18n: {
    messages: {
      ru: {
        categoryName: 'Название категории',
      },
      kk: {
        categoryName: 'Категория аты',
      },
    },
  },
};
</script>

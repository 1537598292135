<template>
  <div
    v-if="priceWithCashback"
    class="px-1 mb-1 mt-2 flex items-baseline freedom-cashback-text"
  >
    <span class="font-medium text-xs"
      >{{ formatPrice(priceWithCashback) }} ₸</span
    >
    <span
      class="text-[8px] font-medium ml-[2px] overflow-hidden text-ellipsis whitespace-nowrap flex-1"
      >{{ $t('withCashback') }}</span
    >
  </div>
</template>
<script>
import { useFreedomCashback } from '@/composables/useFreedomCashback';
import { computed } from 'vue';
import { formatPrice } from '@/utils/formatters/price';

export default {
  props: {
    price: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { calcPriceWithCashback } = useFreedomCashback();

    const priceWithCashback = computed(() =>
      calcPriceWithCashback(props.price)
    );

    return {
      priceWithCashback,
      formatPrice,
    };
  },
  i18n: {
    messages: {
      ru: {
        withCashback: 'с кешбэком',
      },
      kk: {
        withCashback: 'кешбэкпен',
      },
    },
  },
};
</script>
<style>
.freedom-cashback-text {
  background: linear-gradient(
    90deg,
    #0486ba,
    #06c198
  ); /* 90deg = left to right */
  background: -webkit-linear-gradient(
    left,
    #0486ba,
    #06c198
  ); /* Webkit fallback */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>

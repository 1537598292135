import { store } from '@/store';
import { lavkaFavoritesClient as client } from '../../clients/lavka-favorites';
import { createCancelableRequestConfig } from '../../clients/utils';
/**
 * @see https://confluence.chocolife.me/display/RI/lavka-favorites
 * @see https://lavka-favorites.chocodev.kz/swagger/index.html
 * В теле запроса сервис принимает JSON вместо form-data
 */
export function lavkaFavorites() {
  return {
    shoppingHistoryProductsList(params) {
      const { cancelToken, onFinish } = createCancelableRequestConfig();
      const wId = store.getters['delivery/warehouseId'];
      params = {
        warehouse_id: wId,
        ...params,
      };
      return client
        .get(`/lavka-favorites/v1/shopping-history/products/full/list`, {
          params,
          useJSONParser: true,
          cancelToken,
        })
        .then(response => response.data)
        .finally(onFinish);
    },
    favoritesProductsList(params) {
      const sortingOptions = ['frequency:desc', 'last_shopped_at:desc'];
      const wId = store.getters['delivery/warehouseId'];
      params = {
        limit: 100,
        sorting: sortingOptions[0],
        warehouse_id: wId,
        ...params,
      };

      return client
        .get(`/lavka-favorites/v1/favorites/products/full/list`, {
          params,
          useJSONParser: true,
        })
        .then(r => r.data);
    },
    favoritesProductsAdd(productId) {
      return client.post(
        `/lavka-favorites/v1/favorites/products/add`,
        { product_id: productId },
        { useJSONParser: true }
      );
    },
    favoritesProductsRemove(productId) {
      return client.post(
        `/lavka-favorites/v1/favorites/products/remove`,
        { product_id: productId },
        { useJSONParser: true }
      );
    },
  };
}

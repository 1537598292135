import { store } from '@/store';
import { rFetchPopularProducts } from '@shared/services/api/requestsV2/popular-products';
import nativeBridge from '@shared/utils/native-bridge';
import { isCoffeeAvailable } from '@/lib/coffee/is-coffee-available';
import { eventBus } from '@/utils/plugins/event-bus';
import { $log } from '@/utils/plugins/logger';

function loadPopularToStore() {
  const warehouseId = store.getters['delivery/warehouseId'];

  /* prettier-ignore */
  $log('loadPopularToStore', { warehouseId, });

  const conditions = [!nativeBridge.isWebView()];

  if (conditions.some(v => !!v)) {
    store.commit('popularProducts/SET_PRODUCTS', []);
    return Promise.resolve({});
  }

  return rFetchPopularProducts(warehouseId)
    .then(response => {
      const products = response.data
        .map(v => v.product_info)
        .filter(v => {
          return !v.is_sample && (isCoffeeAvailable() || !v.is_coffee);
        });
      store.commit('popularProducts/SET_PRODUCTS', products);
    })
    .catch(err => console.error(err));
}

function init() {
  eventBus.on('eb_on_address_update', loadPopularToStore);
  eventBus.on('eb_on_address_change', loadPopularToStore);
  return loadPopularToStore();
}

export const PopularProductsService = {
  loadPopularToStore,
  init,
};

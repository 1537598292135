<template>
  <div class="">
    <SamplingDeliveryOption
      v-if="sampleProductsWithOutOfStock?.length"
      :products="sampleProducts"
      :disabled="allSamplesOutOfStock"
      @click="onSamplingDeliveryOptionClick"
    />
    <DeliveryOption
      v-model="lessPackages"
      :option="{
        icon: 'less-packages',
        title: $t('asPossibleLessPackages'),
      }"
      @change="setLessPackages"
    />
    <LeaveAtDoor />
    <!-- Промокод -->
    <PromocodeDesktop v-if="$isDesktop" />
    <PromocodeMobile v-else />
    <!-- / Промокод -->
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import nativeBridge from '@shared/utils/native-bridge';
import { createStorage } from '@shared/services/storage';
import { SamplingsService } from '@services/samplings';
import { Logger as LOG } from '@shared/services/logger';
import DeliveryOption from '@pure-ui/components/DeliveryOption/DeliveryOption.vue';
import LeaveAtDoor from '@components/adults/LeaveAtDoor.vue';
import SamplingDeliveryOption from '@components/samplings/SamplingDeliveryOption.vue';

import PromocodeMobile from './promocode/PromocodeMobile.vue';
import PromocodeDesktop from './promocode/PromocodeDesktop.vue';

export default {
  name: 'AdditionalFeatures',
  components: {
    LeaveAtDoor,
    SamplingDeliveryOption,
    DeliveryOption,
    PromocodeMobile,
    PromocodeDesktop,
  },
  inject: ['popup'],
  data: () => ({
    lessPackages: false,
    storage: null,
  }),
  computed: {
    ...mapState('samplings', ['activeSamplings']),
    ...mapGetters('samplings', [
      'sampleProducts',
      'sampleProductsWithOutOfStock',
    ]),
    allSamplesOutOfStock() {
      return (
        !this.sampleProducts?.length &&
        !!this.sampleProductsWithOutOfStock?.length
      );
    },
  },
  mounted() {
    this.storage = createStorage(window.localStorage);

    if (this.storage.get('additionalFeat::lessPackages') === null) {
      this.setLessPackages(false, false);
    }
    this.lessPackages = this.storage.parse('additionalFeat::lessPackages');
  },
  methods: {
    setLessPackages(value, isSwitch = true) {
      if (isSwitch) nativeBridge.hapticSelection();
      this.storage.set('additionalFeat::lessPackages', value);
    },
    onSamplingDeliveryOptionClick() {
      if (!this.allSamplesOutOfStock) {
        return;
      }

      this.openSampleConditions();
    },
    openSampleConditions() {
      const sample = this.sampleProductsWithOutOfStock?.[0];

      if (!sample) {
        return;
      }

      const activeSample = this.activeSamplings.find(
        sampling => sampling.id === sample.sample_id
      );

      if (!activeSample) {
        return;
      }

      const payload = {
        conditions: activeSample,
        sample: activeSample,
        addedProducts: [],
        additionalProducts:
          activeSample?.type === 'category'
            ? []
            : activeSample?.rules
                ?.map(rule => rule?.condition_element)
                ?.filter(Boolean),
        sampling: {},
        params: {
          force: true,
        },
      };
      LOG.event('openSampleConditions payload 1', { payload }, '');

      SamplingsService.openModal(payload);
    },
  },
  i18n: {
    messages: {
      ru: {
        lessPackages: 'Меньше пакетов',
        asPossibleLessPackages: 'Как можно меньше пакетов',
        done: 'Готово',
      },
      kk: {
        lessPackages: 'Қалташалар аз болсын',
        asPossibleLessPackages: 'Қалташалар азырақ болсын',
        done: 'Дайын',
      },
    },
  },
};
</script>

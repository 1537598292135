import { store } from '@/store';
import { computed } from 'vue';
import { isDesktop } from '@/utils/device';
import { AdultsService } from '@services/adults';
import { DevConfigs } from '@/utils/dev-config';
import { hasSupportInlineMedia } from '@/lib/video/has-support-inline-media';

export function useProductCardOptions(product) {
  const isAvailable = computed(() => {
    const q = product.max_quantity;
    return typeof q !== 'undefined' && q > 0;
  });

  const productName = computed(() => {
    if (!product.feature_name) {
      return product.name;
    }

    const arr = product?.feature_name?.split(' ');
    const weight = Number(arr[0].replace(',', '.'));
    const unit = arr[1];
    return `${product.name}, ${weight} ${unit}`;
  });

  const hasVideo = computed(() => {
    return (
      !isDesktop() && product?.video_preview_path && hasSupportInlineMedia()
    );
  });

  const productImage = computed(() => {
    if (hasVideo.value) return product?.video_preview_path;
    return product?.path;
  });

  const checkIsImgBlured = computed(() => {
    return (
      AdultsService.checkIsAdultProduct(product) &&
      !(AdultsService.checkIsAgreed() || store.getters['adults/isAgreed'])
    );
  });

  const fullWidthImage = computed(() => {
    let productsWithFullWidth = DevConfigs.isProd
      ? [18993, 15072]
      : [15072, 5476, 2565, 15691];
    return hasVideo.value || productsWithFullWidth.includes(product.id);
  });

  return {
    isAvailable,
    productName,
    hasVideo,
    productImage,
    checkIsImgBlured,
    fullWidthImage,
  };
}

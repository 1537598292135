export const ADAPTER_NAMES = {
  RYADOM: 'RahmetApp',
  INDRIVE: 'Indrive',
};

export const adapters = {
  [ADAPTER_NAMES.RYADOM]: null,
  [ADAPTER_NAMES.INDRIVE]: null,
};

export const OUTGOING_EVENTS = {
  ON_BACK_PRESSED: 'onBackPressed',
  ON_GEO_DEFINED: 'onGeoDefined',
  ON_NOTIFICATION_DEFINED: 'onNotificationDefined',
  ON_APPLICATION_ACTIVE: 'onApplicationActive',
};

export const OUTGOING_EVENTS_INDRIVE = {
  on_back_pressed: OUTGOING_EVENTS.ON_BACK_PRESSED,
  on_geo_permission_status_defined: OUTGOING_EVENTS.ON_GEO_DEFINED,
  on_notification_permission_status_defined:
    OUTGOING_EVENTS.ON_NOTIFICATION_DEFINED,
  on_application_did_become_active: OUTGOING_EVENTS.ON_APPLICATION_ACTIVE,
};

export const USER_AGENT_KEYS = {
  RYADOM: 'chocosuperapp',
  INDRIVE: 'indrive',
};

export const HAPTIC_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  IMPACT_LIGHT: 'light',
  IMPACT_MEDIUM: 'medium',
  IMPACT_HEAVY: 'heavy',
};

export const HAPTIC_TYPES_INDRIVE = {
  [HAPTIC_TYPES.SUCCESS]: 'notification_success',
  [HAPTIC_TYPES.ERROR]: 'notification_error',
  [HAPTIC_TYPES.WARNING]: 'notification_warning',
  [HAPTIC_TYPES.IMPACT_LIGHT]: 'impact_light',
  [HAPTIC_TYPES.IMPACT_MEDIUM]: 'impact_medium',
  [HAPTIC_TYPES.IMPACT_HEAVY]: 'impact_heavy',
};

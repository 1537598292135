<template>
  <div class="MapView">
    <div id="map" ref="mapContainer" class="MapView__map"></div>
    <MapControls
      :zoom="zoomBtns"
      :geo="geoBtn"
      :geo-loading="geoLoading"
      @onZoomIn="zoom('in')"
      @onZoomOut="zoom('out')"
      @onGeo="defineGeoLocation"
    />
    <MarkerPin
      class="MapView__pin"
      :disabled="pinState === 'disabled'"
      :distant="pinState === 'extended'"
      :loading="pinState === 'loading'"
      :pinup="isMapTouching"
    >
      <slot name="deliveryTime">
        ~ {{ pinState === 'extended' ? 30 : 15 }} минут
      </slot>
    </MarkerPin>
  </div>
</template>

<script>
import YandexMap from './map/yandex-map';
import MarkerPin from '@pure-ui/components/Map/MarkerPin/MarkerPin.vue';
import MapControls from '@pure-ui/components/Map/MapControls/MapControls.vue';
import { debounce, throttle } from 'lodash';

export default {
  components: { MarkerPin, MapControls },
  props: {
    initCoords: {
      type: Array,
      default: () => [43.237242, 76.915075],
    },
    polygons: {
      type: Object,
      default: () => {
        return {
          regular: [],
          extended: [],
        };
      },
    },
    inputCoords: {
      type: Array,
      default: () => [],
    },
    geoBtn: Boolean,
    zoomBtns: Boolean,
  },
  data() {
    return {
      mapInstance: null,
      isMapTouching: false,
      geoLoading: false,
      pinState: 'loading',
      isMobile: false,
      mapInitialized: false,
      pendingPolygonUpdate: false,
      coordsCache: null,
    };
  },
  watch: {
    polygons: {
      handler() {
        if (this.mapInstance && this.mapInitialized) {
          this.setPolygons();
        } else if (this.mapInstance) {
          // Если карта еще не инициализирована, отметим, что полигоны нужно обновить позже
          this.pendingPolygonUpdate = true;
        }
      },
      deep: true,
    },
    inputCoords: {
      handler(coords) {
        if (coords && Array.isArray(coords) && coords.length === 2) {
          // Предотвращаем повторные запросы с теми же координатами
          if (this.coordsCache &&
            Math.abs(this.coordsCache[0] - coords[0]) < 0.0001 &&
            Math.abs(this.coordsCache[1] - coords[1]) < 0.0001) {
            return;
          }

          this.coordsCache = [...coords];

          // Используем debouncedDefineByCoords вместо прямого вызова
          this.debouncedDefineByCoords(coords);
        }
      },
      deep: true,
    },
  },
  created() {
    // Определяем, является ли устройство мобильным
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    // Создаем debounced версию метода defineByCoords
    this.debouncedDefineByCoords = debounce(this.defineByCoords, this.isMobile ? 500 : 100);

    // Создаем throttled версию обновления состояния
    this.throttledUpdatePinState = throttle(this.updatePinState, this.isMobile ? 600 : 200);

    this.mapInstance = new YandexMap(
      this.onMapInit,
      this.onMapClick,
      this.onMapActionEnd,
      this.onMapActionStart,
      this.onMapCenterUpdated
    );
  },
  mounted() {
    // Используем уникальный ID для контейнера карты
    const mapId = `map-${Date.now()}`;
    if (this.$refs.mapContainer) {
      this.$refs.mapContainer.id = mapId;
    }

    // Подбираем оптимальный начальный зум в зависимости от устройства
    const initialZoom = this.isMobile ? 14 : 16;

    // Инициализируем карту с учетом мобильного устройства
    this.mapInstance.initMap(mapId, this.initCoords, initialZoom);

    // Используем throttle для обработчика resize
    this.throttledResize = throttle(this.handleResize, this.isMobile ? 500 : 200);
    window.addEventListener('resize', this.throttledResize);

    // Устанавливаем таймаут для отложенной инициализации на мобильных устройствах
    if (this.isMobile) {
      // На мобильных устройствах отложим тяжелые операции
      setTimeout(() => {
        this.optimizeForMobile();
      }, 300);
    }
  },
  beforeDestroy() {
    // Удаляем обработчик resize при уничтожении компонента
    if (this.throttledResize) {
      window.removeEventListener('resize', this.throttledResize);
    }

    // Очищаем таймеры и дебаунсеры
    if (this.debouncedDefineByCoords) {
      this.debouncedDefineByCoords.cancel();
    }

    if (this.throttledUpdatePinState) {
      this.throttledUpdatePinState.cancel();
    }

    if (this.throttledResize) {
      this.throttledResize.cancel();
    }

    if (this.loadingTimer) {
      clearTimeout(this.loadingTimer);
    }

    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }
  },
  methods: {
    handleResize() {
      if (this.mapInstance && this.mapInstance.map) {
        this.mapInstance.map.container.fitToViewport();
      }
    },
    optimizeForMobile() {
      // Дополнительные оптимизации для мобильных устройств
      if (this.mapInstance && this.mapInstance.map) {
        if (this.mapInstance.map.behaviors) {
          // Отключаем лишние поведения для мобильных
          this.mapInstance.map.behaviors.disable([
            'rightMouseButtonMagnifier',
            'dblClickZoom',  // Двойной клик для зума
            'multiTouch',    // Используем только простой пинч-зум
            'ruler',         // Отключаем линейку
            'routeEditor'    // Отключаем редактор маршрутов
          ]);
        }

        // Уменьшаем количество отображаемых POI
        this.mapInstance.map.options.set('minTileNumber', 10);
        this.mapInstance.map.options.set('maxAnimationQueue', 0);
        this.mapInstance.map.options.set('maxAnimationZoomDifference', 1);
      }
    },
    setPolygons() {
      // Отложенное добавление полигонов для мобильных устройств
      if (this.isMobile) {
        setTimeout(() => {
          this.mapInstance.setPolygons(this.polygons);
          this.$emit('onDeliveryPolygonsSetted');
        }, 300);
      } else {
        this.mapInstance.setPolygons(this.polygons);
        this.$emit('onDeliveryPolygonsSetted');
      }
    },
    updatePinState(data) {
      let {
        isInTerritory,
        isInExtended,
        hasStreet,
        hasBuilding,
        isPolygonsLoaded,
      } = data;

      // Оптимизация: обновляем pinState только если изменилось состояние
      let newPinState;
      let isInDeliveryZone = isInTerritory || isInExtended;
      if (!isPolygonsLoaded) newPinState = 'loading';
      else if (!isInTerritory && !isInExtended || !hasStreet || !hasBuilding) newPinState = 'disabled';
      else if (isInDeliveryZone && isInExtended) newPinState = 'extended';
      else newPinState = 'regular';

      // Обновляем состояние только если оно изменилось
      if (this.pinState !== newPinState) {
        this.pinState = newPinState;
      }
    },
    onMapCenterUpdated(data) {
      let { isPolygonsLoaded } = data;

      if (isPolygonsLoaded) this.$emit('onMapCenterUpdated', data);

      // Используем throttle для обновления состояния пина
      this.throttledUpdatePinState(data);
    },
    onMapClick(options) {
      this.$emit('onMapClick', options);
    },
    onMapActionStart(options) {
      this.$emit('onMapActionStart', options);
      this.isMapTouching = true;

      // Устанавливаем состояние загрузки с небольшой задержкой
      // чтобы избежать мерцания на коротких действиях
      if (this.loadingTimer) clearTimeout(this.loadingTimer);
      this.loadingTimer = setTimeout(() => {
        if (this.isMapTouching) {
          this.pinState = 'loading';
        }
      }, this.isMobile ? 300 : 150);
    },
    onMapActionEnd(options) {
      this.$emit('onMapActionEnd', options);
      this.isMapTouching = false;

      // Очищаем таймер загрузки
      if (this.loadingTimer) {
        clearTimeout(this.loadingTimer);
        this.loadingTimer = null;
      }
    },
    onMapInit(options) {
      this.mapInitialized = true;
      this.$emit('onMapInit', { map: this.mapInstance, ...options });

      // Если были ожидающие обновления полигонов, применяем их
      if (this.pendingPolygonUpdate) {
        setTimeout(() => {
          this.setPolygons();
          this.pendingPolygonUpdate = false;
        }, this.isMobile ? 500 : 0);
      } else {
        setTimeout(() => {
          this.setPolygons();
        }, this.isMobile ? 300 : 0);
      }
    },
    zoom(zoom = 'in') {
      // Предотвращаем множественные вызовы зума
      if (this.zoomInProgress) return;

      this.zoomInProgress = true;
      this.mapInstance.zoom(zoom);

      // Сбрасываем флаг через небольшую задержку
      setTimeout(() => {
        this.zoomInProgress = false;
      }, this.isMobile ? 300 : 100);
    },
    defineByCoords(coords) {
      return this.mapInstance.defineByCoords(coords);
    },
    defineGeoLocation() {
      if (this.geoLoading) return; // Предотвращаем множественные вызовы

      this.geoLoading = true;

      // Увеличиваем таймаут для мобильных устройств
      const timeout = this.isMobile ? 10000 : 5000;

      return this.mapInstance
        .findGeoLocation(timeout)
        .catch(() => this.$emit('onGeoLocationError'))
        .finally(() => {
          // Задержка перед сбросом состояния загрузки
          setTimeout(() => {
            this.geoLoading = false;
          }, this.isMobile ? 300 : 0);
        });
    },
  },
};
</script>

<style scoped>
.MapView {
  position: relative;
  width: 100%;
  height: 100%;
}

.MapView__map {
  width: 100%;
  height: 100%;
  /* Улучшаем производительность рендеринга */
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  will-change: transform;
  backface-visibility: hidden; /* Добавляем для улучшения производительности */
  -webkit-backface-visibility: hidden;
}

.MapView__pin {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  /* Улучшаем производительность рендеринга */
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

/* Добавляем стили для мобильных устройств */
@media (max-width: 768px) {
  .MapView__pin {
    /* Уменьшаем размер трансформации для улучшения производительности */
    transform: translate(-50%, -100%) scale(0.95);
  }

  /* Применяем GPU-ускорение для плавной анимации на мобильных */
  .MapView__map,
  .MapView__pin {
    -webkit-perspective: 1000;
    perspective: 1000;
  }
}
</style>

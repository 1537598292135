<template>
  <div
    class="sampling-promo-card"
    :class="{
      '--desk': $isDesktop,
      ['--desk--' + size]: $isDesktop,
      '--horizontal': isHorizontal,
    }"
    @click="openSampleConditions"
  >
    <!-- Блок с инфой -->
    <div
      class="sampling-promo-card-image-container"
      :class="{ __fullWidth: fullWidthImage }"
    >
      <SamplingPhoto
        :src="productImage"
        :blured="checkIsImgBlured"
        :full-width="fullWidthImage"
        :img-size="imageSize"
        :sticker-position="isHorizontal ? 'top-left' : 'bottom-right'"
        :disabled="!isAvailable"
        class="sampling-promo-card-image"
        sticker
      />

      <VideoIcon v-if="hasVideo" class="product-photo-video" />

      <OverlayMsg v-if="!isAvailable">
        <div class="sampling-promo-card-quantity">
          <p class="--text">{{ $t('ranOut') }}</p>
        </div>
      </OverlayMsg>
    </div>
    <!-- / Блок с инфой -->

    <div class="sampling-promo-card-content">
      <!-- Название товара и бренда -->
      <div class="sampling-promo-card-info">
        <p
          class="sampling-promo-card-title"
          :class="{ '--not-available': !isAvailable }"
        >
          {{ cutTextAddDots(productName, 40) }}
        </p>

        <p
          class="sampling-promo-card-brand-title"
          :class="{ '--not-available': !isAvailable }"
        >
          {{ $t('note') }}
        </p>
      </div>

      <!-- / Название товара и бренда -->

      <!-- Кнопки -->
      <div class="sampling-promo-card-btns">
        <PriceButton class="sampling-promo-card-btn" @click.stop>
          {{ $t('conditions') }}
        </PriceButton>
      </div>
      <!-- / Кнопки -->
    </div>
  </div>
</template>

<script>
import { ProductService } from '@services/product';
import { mapGetters } from 'vuex';
import { useProductCardOptions } from '@shared/components/product/composables/useProductCardOptions';
import { SamplingsService } from '@services/samplings';
import { Logger as LOG } from '@shared/services/logger';

import SamplingPhoto from '@components/samplings/SamplingPhoto.vue';
import PriceButton from '@pure-ui/components/Product/ProductCard/components/PriceButton.vue';
import { cutTextAddDots } from '@/utils/formatters/words';

export default {
  name: 'SamplingPromoCard',
  components: {
    SamplingPhoto,
    PriceButton,
    OverlayMsg: () =>
      import(
        '@pure-ui/components/Product/ProductCard/components/OverlayMsg.vue'
      ),
  },
  props: {
    sample: {
      type: Object,
      required: true,
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      isAvailable,
      productName,
      hasVideo,
      productImage,
      checkIsImgBlured,
      fullWidthImage,
    } = useProductCardOptions(props.sample?.product);

    return {
      isAvailable,
      productName,
      hasVideo,
      productImage,
      checkIsImgBlured,
      fullWidthImage,
    };
  },
  data: () => ({}),
  computed: {
    ...mapGetters('user', ['hasRealBgOfPicture']),
    imageSize() {
      if (this.$isDesktop) {
        return 's:164:164';
      }

      if (this.isHorizontal) {
        return 's:120:120';
      }
      return 's:109:109';
    },
  },
  methods: {
    cutTextAddDots,
    onProductClick(isAppliances = false) {
      if (this.sample.is_sample) return;

      let query = ProductService.buildQueryOnGoToProduct(
        this.sample?.product,
        isAppliances,
        this.addedFrom,
        this.$i18n.locale
      );

      if (this.$isDesktop) {
        this.$router.push({
          name: 'product-full',
          params: {
            id: this.sample?.product?.id.toString(),
          },
          query,
        });
      } else {
        this.$eventBus.emit('eb_open_product_details_modal', {
          id: this.sample?.product?.id,
          query,
        });
      }
    },
    openSampleConditions() {
      const payload = {
        conditions: this.sample,
        sample: this.sample,
        addedProducts: [],
        additionalProducts:
          this.sample?.type === 'category'
            ? []
            : this.sample?.rules
                ?.map(rule => rule?.condition_element)
                ?.filter(Boolean),
        sampling: {},
        params: {
          force: true,
        },
      };
      LOG.event('openSampleConditions payload 1', { payload }, '');

      SamplingsService.openModal(payload);
    },
  },
  i18n: {
    messages: {
      ru: {
        ranOut: 'Ждём поступления',
        maxLimit: 'Больше нет',
        iWant: 'Хочу!',
        toReplace: 'Заменить',
        conditions: 'Условия',
        selectWeight: 'Выбрать вес',
        availableCount: 'в наличии {amount} шт.',
        availableYetCount: 'в наличии ещё {amount} шт.',
        added: 'Добавлено',
        note: 'Подарочный товар',
      },
      kk: {
        ranOut: 'Келуін күтудеміз',
        maxLimit: 'Басқа жоқ',
        iWant: 'Мен қалаймын!',
        toReplace: 'Ауыстыру',
        conditions: 'Шарттары',
        selectWeight: 'Салмақты таңдаңыз',
        availableCount: '{amount} шт. бар',
        availableYetCount: 'тағы {amount} шт. бар',
        added: 'Себетте',
        note: 'Сыйлыққа берілетін тауар',
      },
    },
  },
};
</script>

<style scoped>
.sampling-promo-card {
  @apply flex flex-col
    rounded-2xl
   text-sirius-black-100 cursor-pointer;
  height: 234px;
  background: #ffeccc;
}

.sampling-promo-card-quantity {
  @apply font-bold text-4xl text-center;
  line-height: 44px;
}
.sampling-promo-card-quantity .--count {
  @apply text-28 leading-8;
}
.sampling-promo-card-quantity .--text {
  @apply text-base leading-5;
}
.sampling-promo-card-content {
  @apply flex flex-col flex-1;
}
.--horizontal .sampling-promo-card-content {
  @apply items-start;
  gap: 0.75rem;
}
.--desk.--horizontal .sampling-promo-card-content {
  @apply flex-row justify-between pt-3;
}
.--desk.--desk--sm .sampling-promo-card-content,
.--desk.--desk--xs .sampling-promo-card-content {
  @apply flex-col pt-1;
}
.sampling-promo-card-info {
  @apply px-1 mt-1 flex flex-col h-full;
}

.--horizontal .sampling-promo-card-info {
  @apply px-0;
  gap: 0.25rem;
}
.--desk .sampling-promo-card-info {
  @apply mt-0;
  gap: 0.5rem;
}
.--desk.--desk--sm .sampling-promo-card-info,
.--desk.--desk--xs .sampling-promo-card-info {
  gap: 0.25rem;
}
.sampling-promo-card-image-container {
  @apply relative rounded-2xl overflow-hidden;
  min-height: 105px;
  max-height: 105px;
  min-width: 105px;
  max-width: 105px;
  margin: 2px;
}
.sampling-promo-card:not(.--horizontal) .sampling-promo-card-image-container {
  margin-left: auto;
  margin-right: auto;
}

.--horizontal .sampling-promo-card-image-container {
  min-height: 120px;
  max-height: 120px;
  min-width: 120px;
  max-width: 120px;
  gap: 0.75rem;
  align-items: flex-start;
  margin: 0;
}
.--desk.--horizontal .sampling-promo-card-image-container {
  min-height: 92px;
  max-height: 92px;
  min-width: 92px;
  max-width: 92px;
}

.sampling-promo-card-image {
  @apply w-full h-full;
  height: 109px;
  background: #ffeccc;
}

.--horizontal .sampling-promo-card-image {
  height: 120px;
}

.sampling-promo-card-labels {
  @apply absolute top-1 left-1 z-20;
}

.save-icon {
  @apply absolute top-1 right-1 z-20
    md:top-2 md:right-3
    flex items-start justify-between;
}

.sampling-promo-card-save-btns {
  @apply absolute top-1 right-1 z-20;
}

.sampling-promo-card-title {
  @apply font-normal  text-xs leading-14 line-clamp-3 text-sirius-black-100;
  word-break: break-word;
}

.--horizontal .sampling-promo-card-title {
  font-size: 14px;
  line-height: 18px;
}

.sampling-promo-card-brand-title {
  @apply font-medium md:text-sm text-xxs md:leading-4 leading-3 text-sirius-gray-1000 line-clamp-1;
  word-break: break-word;
}

.--horizontal .sampling-promo-card-brand-title {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.--desk .sampling-promo-card-brand-title {
  font-size: 16px;
  line-height: 20px;
}
.--desk .sampling-promo-card-brand-title,
.--desk.--desk--xs .sampling-promo-card-title {
  font-size: 14px;
  line-height: 18px;
}

.--desk.--desk--xs .sampling-promo-card-title {
  max-height: 36px;
}

.sampling-promo-card-btns {
  @apply px-1 pb-1 flex flex-col mt-auto;
}

.--desk .sampling-promo-card-btns {
  @apply mt-0 p-0;
}

.sampling-promo-card-btn {
  @apply text-xs leading-4;
  min-width: 100px;
}

.--desk .sampling-promo-card-btn {
  @apply text-lg leading-6;
  min-width: 160px;
  border-radius: 1rem;
}
.--desk.--desk--xs .sampling-promo-card-btn {
  min-width: 120px;
}

.--desk.sampling-promo-card {
  @apply rounded-3xl;
  height: 116px;
  width: 100%;
}
.--desk.--desk--sm.sampling-promo-card {
  height: 132px;
}

.--desk.--desk--xs.sampling-promo-card {
  height: 146px;
}

.--desk .sampling-promo-card-quantity {
  line-height: 14px;
  font-size: 12px;
}
.--desk .sampling-promo-card-quantity .--count {
  font-size: 48px;
  line-height: 56px;
}
.--desk .sampling-promo-card-quantity .--text {
  font-size: 12px;
  line-height: 14px;
}

.--desk .sampling-promo-card-image-container {
  @apply rounded-3xl;
  min-height: 92px;
  max-height: 92px;
}

.--desk .sampling-promo-card-image-container.__fullWidth {
  padding: 0;
}

.--desk .sampling-promo-card-image {
  height: 92px;
}

.--desk .sampling-promo-card-labels {
  @apply top-2 left-3;
}

.--desk .save-icon {
  width: 32px;
  height: 32px;
}

.--desk .sampling-promo-card-save-btns {
  @apply top-2 right-3;
}

.--desk .sampling-promo-card-title {
  @apply font-normal text-base leading-tight;
  letter-spacing: 0.005em;
}

.product-photo-video {
  position: absolute;
  right: 4px;
  bottom: 6px;
}

.sampling-promo-card-loyalty {
  position: absolute;
  top: 89px;
  right: 4px;
  z-index: 10;
}

.--horizontal {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.75rem;
  height: auto;
  padding: 8px;
}

.--desk.--horizontal {
  padding: 12px 24px 12px 12px;
  gap: 0.5rem;
}

.--desk.--desk--sm.--horizontal,
.--desk.--desk--xs.--horizontal {
  @apply p-3;
}

@media (max-width: 420px) {
  .--length-6.product-price {
    font-size: 11px;
  }
}
@media (max-width: 374px) {
  .product-price {
    white-space: nowrap;
    font-size: 13px;
  }
  .product-price span {
    font-size: 12px;
  }
  .--length-6.product-price {
    font-size: 10px;
  }
}
@media (max-width: 359px) {
  .--length-6.product-price {
    font-size: 14px;
  }
  .product-price {
    font-size: 14px;
  }
  .product-price span {
    font-size: 14px;
  }
}
</style>

import { isDesktop } from '@/utils/device';

const getHeaderHeight = () => {
  return (
    document.querySelector(isDesktop() ? '.MmDHeader' : '.MmMHeader')
      ?.offsetHeight || 0
  );
};

export const ScrollTo = {
  form() {
    const el = document.querySelector('.MmFormDesktop');
    if (el) {
      window.scrollTo({
        top:
          el.getBoundingClientRect().top +
          window.pageYOffset -
          getHeaderHeight(),
        behavior: 'smooth',
      });
    }
  },
  elementId(id) {
    const el = document.getElementById(id);
    if (el) {
      window.scrollTo({
        top:
          el.getBoundingClientRect().top +
          window.pageYOffset -
          getHeaderHeight() -
          20,
        behavior: 'smooth',
      });
    }
  },
};

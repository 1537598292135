<template>
  <UiTooltip v-if="$isDesktop" position="right-start" round="lg">
    <UiInlineIcon
      :name="$isDesktop ? 'info-icon-desk' : 'info-icon'"
      class="text-sirius-gray-1000 md:hover:text-black"
    />

    <template #tip>
      <slot name="tooltip"> </slot>
    </template>
  </UiTooltip>
  <CheckoutTextConditions v-else />
</template>

<script>
import UiTooltip from '@pure-ui/components/UiTooltip/UiTooltip.vue';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import CheckoutTextConditions from './CheckoutTextConditions.vue';

export default {
  name: 'CheckoutTextTooltip',
  components: {
    CheckoutTextConditions,
    UiTooltip,
    UiInlineIcon,
  },
};
</script>

<style></style>

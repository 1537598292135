import { client } from '../../clients/client';
import fetchActiveBannersMock from './mocks/fetch-active-banners.json';
import { createCancelableRequestConfig } from '../../clients/utils';
/**
 * Получить список активных баннеров
 */
export default function (payload, mock = false) {
  const { cancelToken, onFinish } = createCancelableRequestConfig();
  if (mock) {
    return Promise.resolve(fetchActiveBannersMock);
  }

  return client
    .get('/lavka/v1/active_banners', {
      params: payload,
      cancelToken,
    })
    .then(response => response.data)
    .finally(onFinish);
}

<template>
  <div
    class="SpecialCategory"
    :style="`background-color: ${categoryColor(category)};`"
    :class="category.id === 794 ? 'pr-0 md:pr-4' : ''"
    @click.prevent="navigate"
  >
    <h3 class="font-medium z-10 text-xs md:text-lg">
      {{ category.name }}
    </h3>

    <img
      v-img-proxy="{
        url: compIcon,
        size: $isDesktop ? 's:324:152' : 's:140:120',
        as: 'webp',
      }"
      loading="lazy"
      alt="special-category-img"
      :width="$isDesktop ? '324' : '140'"
      :height="$isDesktop ? '152' : '120'"
      class="SpecialCategory__img"
    />
    <!-- {{ category.icon_big }} -->
  </div>
</template>

<script>
import nativeBridge from '@shared/utils/native-bridge';
import {
  Analitycs,
  EVENTS,
  APPSFLYER_EVENTS,
} from '@shared/services/analitycs';

export default {
  name: 'SpecialCategory',
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  computed: {
    compIcon() {
      /* eslint-disable camelcase */
      const { isDesktop } = this.$store.getters;
      const { icon, icon_big } = this.category;
      return isDesktop ? icon_big || icon : icon;
      /* eslint-enable camelcase */
    },
  },
  methods: {
    categoryColor(category) {
      if (category.color) return category.color;
      return '#F3FFEF';
    },
    navigate() {
      nativeBridge.hapticSelection();
      Analitycs.logEvent(EVENTS.CATEGORY_SELECTED, {
        categoryId: this.category.id.toString(),
        categoryName: this.category.name,
        from: 'main',
      });
      Analitycs.logAppsflyerEvent(APPSFLYER_EVENTS.DOM_NEW_MAIN_CATEGORY_MAIN, {
        dom_type: 'main',
        dom_category_id: this.category.id.toString(),
        dom_category_title: this.category.name,
      });

      this.$router.push({
        name: 'category',
        params: {
          id: this.category.id.toString(),
          route: this.$route,
        },
      });
    },
  },
};
</script>

<style scoped>
.SpecialCategory {
  @apply p-2 md:p-4 bg-cover relative w-full h-full overflow-hidden cursor-pointer rounded-2xl md:rounded-3xl;
  height: 120px;
  min-width: 140px;
  max-width: 140px;
}
.SpecialCategory__img {
  @apply absolute w-full h-full 
    left-0 bottom-0 z-block 
    object-cover 
    md:object-contain md:object-bottom;
}
@media (min-width: 768px) {
  .SpecialCategory {
    object-position: bottom;
    height: 152px;
    width: 100%;
    min-width: unset;
    max-width: unset;
  }
}
</style>

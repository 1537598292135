<template>
  <DeliveryOption
    :value="enabledSamplingDelivery"
    :option="{
      icon: 'cart-sampling-option',
      title: $t('title'),
      descr: disabled ? $t('disabled') : '',
    }"
    :disabled="disabled"
    @change="onChange"
    @click="$emit('click')"
  >
    <template v-if="enabledSamplingDelivery && !disabled">
      <SamplingProductWrapper
        v-for="product in products"
        :key="product.id"
        :product="product"
      />
    </template>
  </DeliveryOption>
</template>

<script>
import { RahmetApp } from '@shared/RahmetApp';
import { mapState } from 'vuex';
import DeliveryOption from '@pure-ui/components/DeliveryOption/DeliveryOption.vue';
import SamplingProductWrapper from '@components/samplings/SamplingProductWrapper.vue';

export default {
  name: 'SamplingDeliveryOption',
  components: {
    DeliveryOption,
    SamplingProductWrapper,
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('samplings', ['enabledSamplingDelivery']),
  },
  methods: {
    saveStateOfToggle(value) {
      this.$store.commit('samplings/SET_ENABLED_SAMPLING_DELIVERY', value);
    },
    onChange(value) {
      RahmetApp.hapticSelection();
      this.saveStateOfToggle(value);
    },
  },
  i18n: {
    messages: {
      kk: {
        title: 'Сыйлыққа берілетін тауар',
        disabled: 'Уақытша қолжетімсіз',
      },
      ru: {
        title: 'Подарочный товар',
        disabled: 'Временно нет в наличии',
      },
    },
  },
};
</script>

<style>
.add-text {
  @apply ml-9 mr-14 mt-0.5 text-sm leading-4 text-sirius-gray-700;
  letter-spacing: 0.07px;
}

.cart-sampling-option__disabled {
  @apply text-sirius-gray-1000 text-sm;
}
</style>

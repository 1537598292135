import { eventBus } from '@/utils/plugins/event-bus';
import { $log } from '@/utils/plugins/logger';

function openWarehouseMigrateModal() {
  return false
}

function init() {
  $log('WarehouseInactiveService', null, 'Init');
  eventBus.on('eb_on_address_change', openWarehouseMigrateModal);
  eventBus.on('eb_on_address_update', openWarehouseMigrateModal);
  eventBus.on('eb_init_page_load_done', openWarehouseMigrateModal);
  openWarehouseMigrateModal();
}

export const WarehouseInactiveService = {
  init,
  openWarehouseMigrateModal,
};

import { Analitycs } from '@shared/services/analitycs';
import { eventBus } from '@/utils/plugins/event-bus';
import { $log } from '@/utils/plugins/logger';
import { DevConfigs } from '@/utils/dev-config';
import nativeBridge from '@shared/utils/native-bridge';

/**
 * Считаем, что билд устарел, если прошло
 * больше 2 недель
 */
// function isOutdated() {
//   const now = dayjs();
//   const releaseDate = import.meta.env.VITE_APP_RELEASE_TIME;

//   const diff = now.diff(releaseDate, 'week', true);
//   return diff >= 2;
// }

// function updateWebview() {
//   Analitycs.logEvent(EVENTS.OUTDATED_BUILD);
//   if (typeof window.RahmetApp?.reloadPage === 'function') {
//     RahmetApp.reloadPage();
//     return;
//   }
//   Analitycs.logEvent(EVENTS.OUTDATED_BUILD_NO_RELOAD);
// }

// function updateSite() {
//   $log('OutdatedBuildWatcherService', null, 'refresh site');
//   Analitycs.logEvent(EVENTS.OUTDATED_BUILD);
//   document.location.reload();
// }

// function refresh() {
//   if (RahmetApp.isWebView()) {
//     updateWebview();
//   } else {
//     updateSite();
//   }
// }

async function checkHasNewRelease() {
  const RELEASE = import.meta.env.VITE_APP_RELEASE;
  if (DevConfigs.isDev) return false;
  $log(
    'OutdatedBuildWatcherService',
    { release: import.meta.env.VITE_APP_RELEASE },
    'checkHasNewRelease'
  );

  return fetch('/releases.json')
    .then(response => response.json())
    .then(data => {
      let hasRelease = !!data.releases.includes(RELEASE);
      $log(
        'OutdatedBuildWatcherService',
        {
          data,
          hasRelease,
        },
        'checkHasNewRelease Stored Releases:'
      );
      return !hasRelease;
    })
    .catch(() => true);
}

function refreshIfOutdated() {
  // if (isOutdated()) refresh();
  checkHasNewRelease().then(res => {
    $log('OutdatedBuildWatcherService', { res }, 'checkHasNewRelease');
    Analitycs.logEvent('checkHasNewRelease', { res });
    // if (res) refresh();
  });
}

function init() {
  $log(
    'OutdatedBuildWatcherService',
    {
      VITE_APP_RELEASE_TIME: import.meta.env.VITE_APP_RELEASE_TIME,
      VITE_APP_RELEASE: import.meta.env.VITE_APP_RELEASE,
    },
    'init'
  );

  window.addEventListener('vite:preloadError', event => {
    throw new Error('vite:preloadError', { event });
  });

  refreshIfOutdated();
  // на сайте слушаем возврат на вкладку
  if (!nativeBridge.isWebView()) {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        refreshIfOutdated();
      }
    });
  }

  eventBus.on('eb_on_reconnect_to_app', () => {
    $log(
      'OutdatedBuildWatcherService eb_on_reconnect_to_app refreshIfOutdated',
      null,
      'EventBus'
    );
    refreshIfOutdated();
  });
}

export const OutdatedBuildWatcherService = {
  init,
};

<template>
  <div>
    <SamplingPromoListGrid v-if="products.length <= 3" :products="products" />
    <SamplingPromoListSlider v-else :products="products" />
  </div>
</template>

<script>
import SamplingPromoListSlider from '@components/samplings/SamplingPromoListDesktop/SamplingPromoListSlider.vue';
import SamplingPromoListGrid from '@components/samplings/SamplingPromoListDesktop/SamplingPromoListGrid.vue';

export default {
  name: 'SamplingPromoListDesktop',
  components: {
    SamplingPromoListSlider,
    SamplingPromoListGrid,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

import { store } from '@/store';
import nativeBridge from '@shared/utils/native-bridge';
import { DevConfigs } from '@/utils/dev-config';
import { createStorage } from '@shared/services/storage';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { eventBus } from '@/utils/plugins/event-bus';

let lastCB = null;
const storage = createStorage(window.localStorage);
/**
 * Это товары которые имеют  analytical_category_id который 21+
 */
const adultProductsList = [
  279, 280, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291, 292, 293, 294,
  295, 308, 309, 327, 345, 349, 96, 141,
];

if (DevConfigs.isDev) adultProductsList.push(121);

function getReminderState() {
  return storage.getItem('adults::reminder-modal-agreed', false);
}
function setReminderState(value = false) {
  store.commit('adults/SET_IS_AGREED', value);
  storage.setItem('adults::reminder-modal-agreed', value, 2 * 60 * 60 * 1000);
}

function runCallback() {
  if (lastCB && typeof lastCB === 'function') lastCB();
}

/**
 * Сохраняем что он потвердил и запускаем колбек
 */
function setAgreed() {
  setReminderState(true);
  runCallback();
  Analitycs.logEvent(EVENTS.ON_ADULTS_MODAL, { status: 'agreed' });
}
function setDisagreed() {
  setReminderState(false);
  Analitycs.logEvent(EVENTS.ON_ADULTS_MODAL, { status: 'disagreed' });
}

/**
 * Проверка согласен ли юзер с тем что ему должно быть 21
 * @returns {Boolean}
 */
function checkIsAgreed() {
  const res = getReminderState();
  store.commit('adults/SET_IS_AGREED', res);
  return getReminderState();
}

/**
 * Открывает модалку и прнимает колбек который должен отработать после того как юзер подтвердит что ему 21 год и выше
 * @param {Function} cb
 */
function openModal(cb) {
  nativeBridge.hapticNotification('warning');
  lastCB = cb;
  eventBus.emit('eb_open_adults_warning_modal');
}

/**
 * Проверка является ли товар 21+
 * @param {Product} product объект товара
 * @returns
 */
function checkIsAdultProduct(product) {
  return !!(
    product.is_alcohol ||
    product.is_tobacco ||
    product.degree_level ||
    adultProductsList.includes(product.analytical_category_id)
  );
}

/**
 * Проверка является ли категория 21+
 * @param {Product} product объект товара
 * @returns
 */
function checkIsAdultCategory(category) {
  return !!category.is_for_adults;
}

function setAdultsLeaveAtDoorModalShownOnMount(value) {
  storage.set('adults::leaveAtDoorModalShown', value);
}
function getAdultsLeaveAtDoorModalShownOnMount() {
  return storage.parse('adults::leaveAtDoorModalShown', false);
}

export const AdultsService = {
  setAdultsLeaveAtDoorModalShownOnMount,
  getAdultsLeaveAtDoorModalShownOnMount,
  checkIsAdultCategory,
  checkIsAdultProduct,
  checkIsAgreed,
  setDisagreed,
  openModal,
  setAgreed,
};

<template>
  <div
    v-if="cashbackPrice"
    class="rounded-xl absolute flex items-center freedom-cashback-badge"
  >
    <UiInlineIcon name="freedom-logo-white" class="mr-1" />
    <span class="text-xs text-white font-medium" size="xs">
      {{ formatPrice(cashbackPrice) }} ₸ +
    </span>
  </div>
</template>
<script>
import { useFreedomCashback } from '@/composables/useFreedomCashback';
import { computed } from 'vue';
import UiInlineIcon from '../UiInlineIcon/UiInlineIcon.vue';
import { formatPrice } from '@shared/utils/formatters/price';

export default {
  components: {
    UiInlineIcon,
  },
  props: {
    price: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { calcCashback } = useFreedomCashback();

    const cashbackPrice = computed(() => calcCashback(props.price));

    return {
      cashbackPrice,
      formatPrice,
    };
  },
};
</script>
<style>
.freedom-cashback-badge {
  background: linear-gradient(90deg, #0486ba 0%, #06c198 100%);
  background: -webkit-linear-gradient(left, #0486ba, #06c198);
  /* padding: 2px 0; */
  @apply px-2;
  border: 2px solid #fff;
  height: 24px;
  box-sizing: border-box;
}
</style>

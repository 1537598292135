export const SamplingsModule = {
  namespaced: true,
  state: {
    samples: [],
    notAvailableSamplings: [],
    samplingPayload: null,
    activeSamplings: [],
    enabledSamplingDelivery: true,
  },
  getters: {
    samples(state) {
      return state.samples;
    },
    appliedSamples(state) {
      return state.samples?.filter(s => s.applied_count);
    },
    sampleProductsWithOutOfStock(state, getters, rootState, rootGetters) {
      const samples = [...state.samples];
      const cartPrice = rootGetters['cart/cartTotalPrice'];

      return samples
        .map(sample => {
          const product = {
            ...rootGetters['productDB/products'][
              `id_${sample.sample_product_id}`
            ],
          };

          let quantity = sample.applied_count;

          if (
            !product?.max_quantity ||
            product?.max_quantity < sample.applied_count
          ) {
            quantity = product?.max_quantity;
          }

          const sampleProduct = {
            ...product,
            quantity,
            sum_to_apply: sample.sum_to_apply,
            price: sample.sample_product_price || 1,
            sample_id: sample.id,
          };
          return sampleProduct;
        })
        .filter(p => {
          return p.sum_to_apply ? p.sum_to_apply <= cartPrice : true;
        });
    },
    sampleProducts(state, getters) {
      return getters.sampleProductsWithOutOfStock.filter(p => p.quantity > 0);
    },
    samplingTotalPrice(state, getters) {
      const products = [...getters.sampleProducts];
      return products.reduce((acc, s) => acc + s.price * s.quantity, 0);
    },
    notAvailableSamplings(state) {
      return state.notAvailableSamplings;
    },
  },
  mutations: {
    SET_SAMPLES(state, value) {
      state.samples = value;
    },
    SET_NOT_AVAILABLE_SAMPINGS(state, value) {
      state.notAvailableSamplings = value;
    },
    SET_SAMPLING_PAYLOAD(state, value) {
      state.samplingPayload = value;
    },
    RESET_ALL(state) {
      state.samples = [];
      state.notAvailableSamplings = [];
    },
    SET_ACTIVE_SAMPLES(state, value) {
      state.activeSamplings = value;
    },
    RESET_ACTIVE_SAMPLES(state) {
      state.activeSamplings = [];
    },
    SET_ENABLED_SAMPLING_DELIVERY(state, value) {
      state.enabledSamplingDelivery = value;
    },
  },
};

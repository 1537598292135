export const getDeviceParams = () => {
  const root = document.documentElement;
  const { fontSize } = window.getComputedStyle(root);
  return {
    screen: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    fontSize,
  };
};

export const isDesktop = () => {
  return window.innerWidth > 767;
};

<template>
  <div class="GiftsPhoto" :class="`--${size} ${disabled ? '__disabled' : ''}`">
    <ProductPhoto class="GiftsPhoto__img" :src="src" mix-blend />
    <div v-if="sticker" class="GiftsPhoto__present">
      <slot>
        <img
          :src="IconPresentBox"
          width="16"
          height="16"
          class="GiftsPhoto__presentBox"
          alt="present-box"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import ProductPhoto from '@pure-ui/components/Product/ProductPhoto/ProductPhoto.vue';

export default {
  name: 'GiftsPhoto',
  components: {
    ProductPhoto,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'sm',
    },
    sticker: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      IconPresentBox: new URL('./img/present-box.svg', import.meta.url).href,
    };
  },
};
</script>

<style scoped>
.GiftsPhoto {
  position: relative;
}

.GiftsPhoto,
.GiftsPhoto__img {
  @apply border-sirius-gray-300;
  border-radius: 24px;
}
.GiftsPhoto {
  border-width: 0.5px;
}

.GiftsPhoto.__disabled {
  filter: grayscale(1);
}
.GiftsPhoto__img {
  overflow: hidden;
}
.--sm.GiftsPhoto {
  border-width: 0;
}
.--sm.GiftsPhoto,
.--sm .GiftsPhoto__img {
  max-width: 48px;
  min-width: 48px;
  max-height: 48px;
  min-height: 48px;
  height: 48px;

  border-radius: 16px;
}
.--regular.GiftsPhoto,
.--regular .GiftsPhoto__img {
  max-width: 60px;
  min-width: 60px;
  max-height: 60px;
  min-height: 60px;
  height: 60px;
}
.--md.GiftsPhoto,
.--md .GiftsPhoto__img {
  max-width: 72px;
  min-width: 72px;
  max-height: 72px;
  min-height: 72px;
  height: 72px;
}
.--lg.GiftsPhoto,
.--lg .GiftsPhoto__img {
  max-width: 88px;
  min-width: 88px;
  max-height: 88px;
  min-height: 88px;
  height: 88px;

  border-radius: 28px;
}
.--xl.GiftsPhoto,
.--xl .GiftsPhoto__img {
  max-width: 140px;
  min-width: 140px;
  max-height: 140px;
  min-height: 140px;
  height: 140px;

  border-radius: 40px;
}

.GiftsPhoto__present {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  font-weight: 700;
  text-align: center;

  width: 100%;
  height: 100%;

  background-image: url('./img/gift-sticker-big-big.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.--sm .GiftsPhoto__present {
  bottom: 0;
  left: 30px;
  max-width: 32px;
  min-width: 32px;
  max-height: 32px;
  min-height: 32px;

  font-size: 10.667px;
  line-height: 12px;
  letter-spacing: 0.053px;
}
.--regular .GiftsPhoto__present,
.--md .GiftsPhoto__present {
  bottom: 0;
  right: -19px;
  max-width: 52px;
  min-width: 52px;
  max-height: 52px;
  min-height: 52px;

  font-size: 17.333px;
  line-height: 19.5px;
  letter-spacing: 0.087px;
}
.--lg .GiftsPhoto__present {
  bottom: 6px;
  right: -28px;

  max-width: 56px;
  min-width: 56px;
  max-height: 56px;
  min-height: 56px;

  font-size: 18.667px;
  line-height: 21px;
  letter-spacing: 0.093px;
}
.--xl .GiftsPhoto__present {
  bottom: 12px;
  right: -37px;
  max-width: 80px;
  min-width: 80px;
  max-height: 80px;
  min-height: 80px;

  font-size: 26.667px;
  line-height: 30px;
  letter-spacing: 0.133px;
}

.--sm .GiftsPhoto__presentBox {
  width: 16px;
  height: 16px;
}
.--regular .GiftsPhoto__presentBox,
.--md .GiftsPhoto__presentBox {
  width: 24px;
  height: 24px;
}
.--lg .GiftsPhoto__presentBox {
  width: 26px;
  height: 26px;
}
.--xl .GiftsPhoto__presentBox {
  width: 36px;
  height: 36px;
}
</style>

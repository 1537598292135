import { DevConfigs } from '@/utils/dev-config';
import RahmetApp from './RahmetApp';
import DevApp from './DevApp';
import { ADAPTER_NAMES, adapters, USER_AGENT_KEYS } from './constants';
import Indrive from './Indrive';

function getAdapter(name) {
  if (!adapters[name]) {
    switch (name) {
      case ADAPTER_NAMES.RYADOM:
        adapters[name] = new RahmetApp();
        break;
      case ADAPTER_NAMES.INDRIVE:
        adapters[name] = new Indrive();
        break;
      default:
        throw new Error(`Unknown adapter: ${name}`);
    }
  }
  return adapters[name];
}

class NativeBridge {
  constructor() {
    if (DevConfigs.isDev && DevConfigs.isMiniApp) {
      this.adapter = new DevApp();
      this._isWebView = true;
      return;
    }

    const userAgent = navigator.userAgent.toLowerCase();
    let detectedApp = null;

    if (userAgent.includes(USER_AGENT_KEYS.RYADOM) || !!window.RahmetApp) {
      detectedApp = ADAPTER_NAMES.RYADOM;
    } else if (userAgent.includes(USER_AGENT_KEYS.INDRIVE)) {
      detectedApp = ADAPTER_NAMES.INDRIVE;
    }

    if (!detectedApp) {
      console.warn('No native app detected. Using fallback adapter.');
      this.adapter = this.getFallbackAdapter();
      this._isWebView = false;
      return;
    }

    this.adapter = getAdapter(detectedApp);
    this._isWebView = true;
  }

  /**
   * Checks is webview or not.
   * @returns {Boolean}
   */
  isWebView() {
    return this._isWebView;
  }

  /**
   * Requests geo access.
   * @returns {Promise<String>}
   */
  requestGeoPermissionStatus() {
    return this.adapter.requestGeoPermissionStatus();
  }

  /**
   * Gets cached geo position.
   *
   * Note: Navigator geo position has issue on Android,
   * decided to use this method for android devices
   */
  getCachedGeoPosition(body) {
    return this.adapter.getCachedGeoPosition(body);
  }

  /**
   * Returns auth version of super app in Choco.
   * @returns {String}
   */
  authVersion() {
    return this.adapter.authVersion();
  }

  /**
   * Launches auth in super app.
   *
   * Note: it's current version auth in Choco app by client ID
   * @param {Object} body
   * @returns {Promise}
   */
  authorizeV2(body) {
    return this.adapter.authorizeV2(body);
  }

  /**
   * Launches auth in super app.
   *
   * Note: it's old (deprecated) version auth in Choco app by track ID
   * @returns {Promise}
   */
  authorize() {
    return this.adapter.authorize();
  }

  /**
   * Checks user is authorized in super app or not.
   * @returns {Boolean}
   */
  checkAuth() {
    return this.adapter.checkAuth();
  }

  /**
   * Sends event to Appsflyer through Choco app
   * @param {String} event
   */
  logAppsflyerEvent(event) {
    return this.adapter.logAppsflyerEvent(event);
  }

  /**
   * Navigates to super app.
   */
  backToNativeApp() {
    return this.adapter.backToNativeApp();
  }

  /**
   * Plays selection haptic.
   */
  hapticSelection() {
    return this.adapter.hapticSelection();
  }

  /**
   * Plays notification haptic.
   */
  hapticNotification(type) {
    return this.adapter.hapticNotification(type);
  }

  /**
   * Plays impact haptic.
   */
  hapticImpact(type) {
    return this.adapter.hapticImpact(type);
  }

  /**
   * Opens external link.
   * @param {Object} body
   */
  openLink(body) {
    return this.adapter.openLink(body);
  }

  /**
   * Opens device settings.
   */
  openAppSettings() {
    return this.adapter.openAppSettings();
  }

  /**
   * Reloads pages.
   */
  reloadPage() {
    return this.adapter.reloadPage();
  }

  /**
   * Requests notification status from device
   *
   * @returns {Promise<String>}
   */
  requestNotificationStatus() {
    return this.adapter.requestNotificationStatus();
  }

  /**
   * Opens app settings and await notification status from device
   *
   * @returns {Promise<String>}
   */
  openAppSettingsAwaitNotificationStatus() {
    return this.adapter.openAppSettingsAwaitNotificationStatus();
  }

  /**
   * Hides navigation bar of super app
   *
   */
  hideNavigationBar() {
    return this.adapter.hideNavigationBar();
  }

  /**
   * Opens payment page.
   *
   * @returns {Promise<String>}
   */
  pay(deeplink) {
    return this.adapter.pay(deeplink);
  }

  /**
   * Detects when application become active.
   *
   * Note: Outgoing action.
   */
  applicationBecomeActive(method) {
    return this.adapter.applicationBecomeActive(method);
  }

  /**
   * Registers outgoing event to local stack.
   * E.g on_back_pressed can be called many times with different callbacks,
   * we need to keep all callbacks in stack and call it when it's needed
   * @param {string} eventName - The event name (e.g., "on_back_pressed").
   * @param {Function} callback - The callback to execute when the event occurs.
   */
  registerOutgoingEvent(methodName, callback) {
    return this.adapter.registerOutgoingEvent(methodName, callback);
  }

  /**
   * Removes outgoing event from local stack.
   * @param {string} eventName - The event name (e.g., "on_back_pressed").
   */
  cancelOutgoingEvent(methodName) {
    return this.adapter.cancelOutgoingEvent(methodName);
  }

  /**
   * Sets dummy data to adapter (for dev mode).
   */
  setMocsForDevelopment() {
    return this.adapter.setMocsForDevelopment();
  }

  /**
   * Defines global object for outgoing actions.
   */
  setupWebViewApi() {
    return this.adapter.setupWebViewApi();
  }

  /**
   * Checks incoming actions in global object.
   */
  hasMethod(methodName) {
    return this.adapter.hasMethod(methodName);
  }

  /**
   * Returns a fallback adapter with no-op functions.
   */
  getFallbackAdapter() {
    return {
      isWebView: () => false,
      requestGeoPermissionStatus: () => Promise.resolve('denied'),
      getCachedGeoPosition: () => Promise.resolve(null),
      authVersion: () => 'unknown',
      authorizeV2: () => Promise.reject(new Error('Not available on desktop')),
      authorize: () => Promise.reject(new Error('Not available on desktop')),
      checkAuth: () => false,
      logAppsflyerEvent: () => {},
      backToNativeApp: () => {},
      hapticSelection: () => {},
      hapticNotification: () => {},
      hapticImpact: () => {},
      openLink: () => {},
      openAppSettings: () => {},
      reloadPage: () => {},
      applicationBecomeActive: () => {},
      registerOutgoingEvent: () => {},
      cancelOutgoingEvent: () => {},
      setMocsForDevelopment: () => {},
      setupWebViewApi: () => {},
      hasMethod: () => false,
      shareFile: () => {},
      shareToInstagram: () => {},
      shareText: () => {},
      requestNotificationStatus: () => Promise.resolve('notAvailable'),
      openAppSettingsAwaitNotificationStatus: () => {},
      pay: () => {},
      hideNavigationBar() {},
    };
  }

  /**
   * Opens share dialog for file.
   *
   * Note: outdated, check before use.
   */
  shareFile(base64, filename, mime) {
    return this.adapter.shareFile(base64, filename, mime);
  }

  /**
   * Opens share dialog for media for instagram.
   *
   * Note: outdated, check before use.
   */
  shareToInstagram(base64, filename, mime) {
    return this.adapter.shareToInstagram(base64, filename, mime);
  }

  /**
   * Opens share dialog for text.
   *
   * Note: outdated, check before use.
   */
  shareText(text) {
    return this.adapter.shareText(text);
  }
}

const nativeBridge = new NativeBridge();
export default nativeBridge;

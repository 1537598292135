<template>
  <Transition name="bottom-sheet">
    <div v-if="isActive" class="bottom-sheet-modal">
      <div
        :style="{ zIndex }"
        class="blackout bottom-sheet-overlay"
        @touchstart.passive="onBlackoutTouchStart"
        @touchend.passive="onBlackoutTouchEnd"
        @click="closeBottomSheet"
      ></div>

      <div :style="{ zIndex }" class="sheet" style="max-height: 95%">
        <div
          aria-modal="true"
          role="dialog"
          class="sheet-shift"
          :style="{ transform: `translateY(${sheetShift}px)` }"
          @touchstart.passive="onSheetTouchStart"
          @touchmove.passive="onSheetTouchMove"
          @touchend="onSheetTouchEnd"
        >
          <div class="shift-content" style="background: #d4d3df"></div>

          <div
            ref="content"
            class="bottom-sheet-content hidden-scrollbar"
            :style="`background: ${backgroundColor}`"
          >
            <slot v-bind="bottomSheet.state.props"></slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import Vue from 'vue';
import nativeBridge from '@shared/utils/native-bridge';
import { useZIndex, TAILWIND_Z_MODAL_INDEX } from '@/composables/useZIndex';
import { OUTGOING_EVENTS } from '@shared/utils/native-bridge/constants';

export function createBottomSheet() {
  const state = Vue.observable({
    activeName: '',
    props: {},
  });

  return {
    state,
    show(name, params) {
      state.activeName = name;
      state.params = params;
      document.body.style.overflow = 'hidden';
    },
    hide() {
      state.activeName = '';
      state.params = {};
      document.body.style.overflow = 'auto';
    },
  };
}

const extractTouch = event => event.changedTouches[0].clientY;

export default {
  name: 'UiBottomSheetV2',
  inject: ['bottomSheet'],
  props: {
    name: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: '#fff',
    },
  },
  setup() {
    const { getNextZIndex, releaseZIndex } = useZIndex();
    return { getNextZIndex, releaseZIndex };
  },
  data: () => ({
    blackoutTouchStarted: false,
    sheetTouchStarted: false,
    sheetTouchStart: 0,
    sheetShift: 0,
    zIndex: TAILWIND_Z_MODAL_INDEX,
  }),
  computed: {
    isActive() {
      return this.bottomSheet.state.activeName === this.name;
    },
  },
  watch: {
    'bottomSheet.state.activeName': function onStateChange(
      newValue,
      prevValue
    ) {
      if (newValue === this.name) {
        nativeBridge.registerOutgoingEvent(
          OUTGOING_EVENTS.ON_BACK_PRESSED,
          this.onCloseFromMobile
        );
        this.$emit('onOpen');
        this.zIndex = this.getNextZIndex();
      }
      if (newValue === '' && prevValue === this.name) {
        this.bottomSheet.hide();
        this.releaseZIndex(this.zIndex); // Release z-index when closed
        nativeBridge.cancelOutgoingEvent(OUTGOING_EVENTS.ON_BACK_PRESSED);
      }
    },
  },
  beforeDestroy() {
    this.bottomSheet.hide();
  },
  methods: {
    onBlackoutTouchStart() {
      this.blackoutTouchStarted = true;
    },
    onBlackoutTouchEnd() {
      if (this.blackoutTouchStarted) {
        this.blackoutTouchStarted = false;
        this.closeBottomSheet();
      }
    },
    onSheetTouchStart(e) {
      this.sheetTouchStarted =
        this.$refs.content &&
        this.$refs.content.scrollTop !== undefined &&
        this.$refs.content.scrollTop === 0;
      this.sheetTouchStart = extractTouch(e);
    },
    onSheetTouchMove(e) {
      if (this.sheetTouchStarted) {
        const shift = extractTouch(e) - this.sheetTouchStart;
        this.sheetShift = Math.max(0, shift);
      }
    },
    onSheetTouchEnd() {
      const shift = parseInt(this.sheetShift, 10);
      if (this.sheetTouchStarted && shift >= 100) {
        this.closeBottomSheet();
      }
      this.sheetTouchStarted = false;
      this.sheetShift = 0;
    },
    closeBottomSheet() {
      this.$emit('onClose');
      this.bottomSheet.hide();
    },
    onCloseFromMobile() {
      this.$emit('onClose');
      this.bottomSheet.hide();
      return true;
    },
  },
};
</script>

<style lang="postcss" scoped>
.blackout {
  @apply inset-0 fixed;
}
.sheet {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;

  &-shift {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transition-duration: 300ms;
  }
}
.shift-content {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 9;
  width: 72px;
  height: 6px;
  margin: 0.5rem 0;
  background-color: #d4d3df;
  border-radius: 2.5rem;
  opacity: 0.4;
  transform: translateX(-50%);
}
.bottom-sheet {
  &-enter-active,
  &-leave-active {
    transition: 500ms;
  }

  &-enter-active > .blackout,
  &-leave-active > .blackout {
    transition: 500ms;
  }

  &-enter > .blackout,
  &-leave-to > .blackout {
    opacity: 0;
  }

  &-enter-active > .sheet,
  &-leave-active > .sheet {
    transition: 200ms ease-in;
  }

  &-enter > .sheet,
  &-leave-to > .sheet {
    transform: translateY(100%);
  }

  &-overlay {
    background: rgba(0, 0, 0, 0.45);
  }

  &-modal {
    @apply inset-0 fixed z-modal;
    display: flex;
  }

  &-content {
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: none;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }

  &-content-buttons {
    @apply px-4 py-3 fixed w-full flex z-modal bottom-0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.9) 67.89%,
      #ffffff 47.78%
    );
    backdrop-filter: blur(12px);
  }
}
</style>

<template>
  <HeaderBaseWrapper hideable>
    <template #header-top>
      <HeaderBase>
        <template #left>
          <IconBack class="header-icon" @click.native="$emit('onBackClick')" />
        </template>
        <MainAddressContext class="header-center" />
        <template #right>
          <IconClose class="header-icon" @click.native="$emit('onClose')" />
        </template>
      </HeaderBase>
    </template>
    <template #header-middle>
      <SearchTrigger disabled @click.native="$emit('onSearch')" />
      <!-- <IconLoyalty
        v-if="$store.getters['loyalty/available']"
        :count="$store.getters['loyalty/count']"
        @click.native="$emit('onLoyalty')"
      /> -->
      <IconFreedomCashback @click.native="$emit('onFreedomCashbackClick')" />
    </template>
  </HeaderBaseWrapper>
</template>

<script>
import MainAddressContext from '@components/address-context/MainAddressContext.vue';
import SearchTrigger from '@pure-ui/components/SearchTrigger/SearchTrigger.vue';
import HeaderBaseWrapper from './components/HeaderBaseWrapper.vue';
import IconBack from './components/IconBack.vue';
import HeaderBase from './components/HeaderBase.vue';
import IconClose from './components/IconClose.vue';
import IconFreedomCashback from './components/IconFreedomCashback.vue';

export default {
  components: {
    MainAddressContext,
    HeaderBaseWrapper,
    SearchTrigger,
    IconBack,
    HeaderBase,
    IconClose,
    IconFreedomCashback,
  },
};
</script>

<style scoped>
.header-icon {
  @apply my-1;
}
.header-center {
  padding-top: 8px;
}
</style>

<template>
  <div class="product-list-mobile">
    <div class="custom-grid pl-2 pr-4 pt-3">
      <SamplingPromoCard
        v-for="(sample, index) in products"
        :key="`${index}_${sample.id}`"
        :sample="sample"
        class="ml-2"
        style="flex-shrink: 0"
      />
    </div>
  </div>
</template>

<script>
import SamplingPromoCard from '@components/samplings/SamplingPromoCard.vue';

export default {
  name: 'SamplingPromoListGrid',
  components: {
    SamplingPromoCard,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.custom-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
}
</style>

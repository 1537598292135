<template>
  <!-- webview only -->
  <MainLayout>
    <template #header>
      <ProfilePageHeader @backToRahmetApp="backToRahmetApp" />
    </template>

    <ProfileCard class="px-4 pt-2" :user="user || {}" />

    <ProductsReviewWrapper
      v-if="hasReviewProducts"
      class="mt-3 mb-1 px-4"
      screen="profile"
    />

    <UserAgreementReminderMobileContainer class="mt-3 mb-4 px-4" />

    <ProfileMenu
      class="px-4"
      :promocodes-count="$store.getters['promocodes/count']"
    />

    <ConfirmClose @confirm="onConfirmClose" @close="popup.hide()" />
  </MainLayout>
</template>

<script>
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { PromocodesService } from '@services/promocodes';
import nativeBridge from '@shared/utils/native-bridge';
import { mapGetters } from 'vuex';

import UserAgreementReminderMobileContainer from '@components/user-agreement/UserAgreementReminderMobileContainer.vue';
import ProductsReviewWrapper from '@components/product-review/ProductsReviewWrapper.vue';
import ConfirmClose from '@shared/components/confirm-close/ConfirmClose.vue';
import MainLayout from '@shared/components/layout/MainLayout.vue';

import ProfilePageHeader from './components/ProfilePageHeader.vue';
import ProfileCard from './components/ProfileCard.vue';
import ProfileMenu from './components/ProfileMenu.vue';

/**
 * miniapp версия страницы /profile
 */
export default {
  name: 'MeView',
  components: {
    UserAgreementReminderMobileContainer,
    ProductsReviewWrapper,
    ProfilePageHeader,
    ConfirmClose,
    ProfileCard,
    ProfileMenu,
    MainLayout,
  },
  inject: ['bottomSheetPopup', 'bottomSheet', 'popup'],

  computed: {
    ...mapGetters('productReview', ['hasReviewProducts']),
    user() {
      const userProfile = this.$store.getters['user/userProfile'];
      if (!userProfile) {
        return null;
      }
      let name = '';
      if (userProfile.first_name || userProfile.last_name) {
        name = `${userProfile.first_name} ${userProfile.last_name}`.trim();
      }
      return {
        name,
        photo: userProfile.avatar,
        phone: userProfile.phone,
        email: userProfile.email,
      };
    },
  },
  mounted() {
    Analitycs.logEvent(EVENTS.REF_PROFILE_PAGE_ENTER);
    PromocodesService.loadActiveToStore();
  },
  methods: {
    backToRahmetApp() {
      if (this.$store.getters['cart/cartSize']) {
        Analitycs.logEvent(EVENTS.CROSS_EXIT_CONFIRM);
        this.popup.show('confirm-close-cart');
      } else {
        this.onConfirmClose();
      }
    },
    onConfirmClose() {
      Analitycs.logEvent(EVENTS.CROSS_EXIT, {
        from: 'profile',
      });
      nativeBridge.hapticSelection();
      nativeBridge.backToNativeApp();
    },
  },
};
</script>

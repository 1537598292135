import { client } from '../../clients/client';
import { createCancelableRequestConfig } from '../../clients/utils';

/**
 * Выдача продуктов категории Акции для мини списка на главной
 */
export default function (params) {
  const { cancelToken, onFinish } = createCancelableRequestConfig();

  return client
    .get('/lavka/v1/sample/active', {
      params,
      cancelToken,
    })
    .then(response => response.data)
    .finally(onFinish);
}

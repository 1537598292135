<template>
  <div class="">
    <transition name="fade">
      <div
        v-if="tipsList"
        class="h-9 flex overflow-scroll hidden-scrollbar gap-2 duration-300"
      >
        <div v-for="(tip, index) in tipsList" :key="index" class="tips-border">
          <div
            class="h-9 px-2 py-1 flex items-center whitespace-nowrap rounded-xl cursor-pointer"
            :class="
              selected === tip.type
                ? 'bg-sirius-orange-100 text-white'
                : 'bg-sirius-gray-200 text-sirius-black-100'
            "
            @click="onClick(tip)"
          >
            {{ getText(tip) }}
          </div>
        </div>
      </div>
    </transition>
    <TipsPopup v-model="showModal" @onChangeCustomTip="onChangeCustomTip" />
  </div>
</template>

<script>
import nativeBridge from '@shared/utils/native-bridge';
import { i18n } from '../i18n';
import TipsPopup from '../TipsPopup.vue';

export default {
  name: 'TipsListView',
  components: {
    TipsPopup,
  },
  props: {
    predefinedTips: {
      type: Array,
      required: true,
    },
    isNotLeaveFirst: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      price: 0,
      customPrice: 0,
      selected: null,
      tipsList: null,
      showModal: false,
    };
  },
  watch: {
    isNotLeaveFirst(newVal) {
      if (
        (this.tipsList && this.tipsList[0].price === 0 && newVal === false) ||
        (this.tipsList && this.tipsList[0].price !== 0 && newVal === true)
      ) {
        this.initList();
      }
    },
  },
  mounted() {
    this.initList();
  },
  methods: {
    getText(tip) {
      if (tip.type === 'CUSTOM_PRICE' && this.customPrice !== 0)
        return `${this.customPrice} ₸`;

      return tip.type !== 'CUSTOM_PRICE' && tip.type !== 'NO_PRICE'
        ? `${tip.price} ₸`
        : this.$t(tip.text);
    },
    initList() {
      this.tipsList = null;
      setTimeout(() => {
        const predifined = [...this.predefinedTips];

        if (this.isNotLeaveFirst) {
          predifined.unshift(predifined.pop());
          this.tipsList = predifined;
          return;
        }

        this.tipsList = predifined;
      }, 300);
    },
    changeTipPrice() {
      this.$emit('onChangeTipPrice', this.price);
      this.$emit('onChangeTipType', this.selected);
    },
    closePopup() {
      this.showModal = false;
    },
    openPopup() {
      this.showModal = true;
    },
    onChangeCustomTip(price) {
      this.closePopup();

      if (!price) {
        this.price = 0;
        this.customPrice = this.price;
        this.selected = null;
        this.changeTipPrice();
        return;
      }
      this.selected = 'CUSTOM_PRICE';
      this.price = parseInt(price, 10);
      this.customPrice = this.price;
      this.changeTipPrice();
    },
    onClick(tip) {
      nativeBridge.hapticSelection();

      if (this.selected === tip.type) {
        this.selected = null;
        this.price = 0;
        this.changeTipPrice();
        return;
      }

      this.selected = tip.type;

      if (this.selected === 'CUSTOM_PRICE') {
        this.openPopup();
        return;
      }
      this.price = tip.price;
      this.changeTipPrice();
    },
  },
  i18n,
};
</script>

<style scoped lang="postcss">
.tips-border {
  &:first-child {
    border-left-width: 20px;
    border-color: transparent;
  }
  &:last-child {
    border-right-width: 20px;
    border-color: transparent;
  }
}
</style>

<template>
  <div class="product-list-desktop">
    <UiSwiper
      :slides="products"
      :settings="settings"
      navigation
      custom-key="product_list"
    >
      <template #slide="slide">
        <div class="products-swiper-slide">
          <SamplingPromoCard :sample="slide" size="xs" is-horizontal />
        </div>
      </template>
    </UiSwiper>
  </div>
</template>

<script>
import SamplingPromoCard from '@components/samplings/SamplingPromoCard.vue';

export default {
  name: 'SamplingPromoListSlider',
  components: {
    SamplingPromoCard,
    UiSwiper: () => import('@pure-ui/components/UiSwiper/UiSwiper.vue'),
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    settings: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      speed: 500,
    },
  }),
};
</script>

<template>
  <HorizontalProductCard
    :name="product.name"
    :img-src="product.path"
    :price="product.price"
    :quantity="product.quantity || 1"
    img-mix-blend
    class="sampling-product __sticker"
    :class="{
      'sampling-product__default-view': isDefaultView,
      'sampling-product__details-view': isDetails,
    }"
    hide-old-price
    @onClick="$emit('onGoProduct', 'block')"
  >
    <template #photo>
      <SamplingPhoto
        class="sampling-product__photo"
        :src="product.path"
        :price="product.price"
        :sticker="sticker"
        :is-default-view="isDefaultView"
        :disabled="isDisabled"
        @click.native.stop="$emit('onGoProduct', 'photo')"
      />
    </template>
    <template #info>
      <div class="sampling-product__info">
        <template v-if="!isDisabled">
          <div class="sampling-product__price">
            <span v-if="!isDefaultView">
              {{ formatPrice(product.price) }} ₸<template v-if="isDetails"
                >/шт</template
              >
            </span>
            <span v-else>
              <span :class="{ __oldPrice: product.old_price }">
                {{ formatPrice(product.price) }} ₸/шт
              </span>
              <span v-if="product.old_price" class="sampling-product__discount">
                {{ formatPrice(product.old_price) }} ₸
              </span>
            </span>
          </div>
          <div class="sampling-product__note">
            × {{ product.quantity }}
            <template v-if="!isDetails">{{ $t('item') }}</template>
          </div>
          <div
            v-if="!isDefaultView && maxLimitReached"
            class="sampling-product__note"
          >
            • {{ $t('disabled') }}
          </div>

          <p v-if="isDetails" class="ml-3 text-sirius-black-100 font-bold flex">
            {{ totalPrice }} ₸
          </p>
        </template>

        <div v-else class="sampling-product__note">
          {{ $t('outOfStock') }}
        </div>
      </div>
    </template>
  </HorizontalProductCard>
</template>

<script>
import { formatPrice } from '@shared/utils/formatters/price';
import HorizontalProductCard from '@pure-ui/components/Product/HorizontalProductCard/HorizontalProductCard.vue';
import SamplingPhoto from '@pure-ui/components/Sampling/SamplingPhoto/SamplingPhoto.vue';

export default {
  name: 'SamplingProduct',
  components: {
    HorizontalProductCard,
    SamplingPhoto,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    isDefaultView: {
      type: Boolean,
      default: false,
    },
    isDetails: {
      type: Boolean,
      default: false,
    },
    sticker: Boolean,
  },
  computed: {
    isDisabled() {
      if (this.isDetails) {
        return false;
      }
      return !this.product.max_quantity;
    },
    maxLimitReached() {
      if (this.isDetails) {
        return false;
      }
      return (
        this.product.max_quantity > 0 &&
        this.product.quantity === this.product.max_quantity
      );
    },
    totalPrice() {
      return formatPrice(this.product.price * this.product.quantity);
    },
  },
  methods: {
    formatPrice,
  },
  i18n: {
    messages: {
      ru: {
        item: 'шт',
        disabled: 'больше нет',
        outOfStock: 'нет в наличии',
      },
      kk: {
        item: 'шт',
        disabled: 'басқа жоқ',
        outOfStock: 'нет в наличии',
      },
    },
  },
};
</script>

<style scoped>
.sampling-product__default-view {
  @apply py-3 border-sirius-gray-200;
  border-top-style: solid;
  border-top-width: 1px;
}
.sampling-product__default-view:last-child {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.sampling-product__info {
  @apply flex flex-row items-center gap-1 h-5 mt-1;
}
.sampling-product__price {
  @apply text-base font-medium leading-5;
}
.sampling-product__default-view .sampling-product__price {
  @apply text-xs;
}
.sampling-product__details-view .sampling-product__price {
  @apply font-normal text-sirius-gray-900 text-base leading-5;
}
.sampling-product__note {
  @apply text-sm leading-4 font-medium text-sirius-gray-1000;
}
.sampling-product__default-view .sampling-product__note {
  @apply text-xs;
}
.sampling-product__details-view .sampling-product__note {
  @apply font-normal text-sirius-gray-900 text-base leading-5;
}
.__oldPrice {
  @apply text-sirius-red-400;
}
.sampling-product__discount {
  @apply mr-1 line-through text-sirius-gray-1000;
}
</style>

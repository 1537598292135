<template>
  <div class="promocode-card" :class="{ 'promocode-card_archive': isArchive }">
    <div class="promocode-card__container">
      <div class="promocode-card__area-discount">
        <div v-if="typeof discount === 'number'" class="promocode-card__title">
          <template v-if="!freeDelivery && !isPercentageDiscount">
            {{ discount }} ₸
          </template>
          <template v-if="freeDelivery">
            {{ $t('zeroTengeForDelivery') }}
          </template>
          <template v-if="isPercentageDiscount"> {{ discount }}% </template>
        </div>
        <div v-if="productId" class="promocode-card__title">
          {{ $t('productGiftTitle') }}
        </div>
        <div v-if="!isArchive" class="promocode-card__until">
          {{ $t('activeUntil', [compUntil]) }}
        </div>
      </div>
      <div class="promocode-card__area-promocode">
        <div class="promocode-card__promocode-value-wrapper">
          <div class="promocode-card__promocode-value">
            {{ promocode }}
          </div>
        </div>
        <div class="promocode-card__reason-value">
          <span v-if="reason === 'bonus'" data-tid="promocode-card-ref-label">
            {{ $t('refBonus') }}
          </span>
        </div>
      </div>
      <div class="promocode-card__area-conditions">
        <button class="promocode-card__conditions" @click="onConditionsClick">
          {{ $t($isDesktop ? 'conditionsDesktop' : 'conditions') }}
          <img class="inline-block" :src="rightIcon" width="16" />
        </button>
      </div>
      <div class="promocode-card__area-status">
        <UiButtonNew
          v-if="!isArchive && !isAppliedInCart"
          variant="gray"
          size="sm"
          class="promocode-card__btn-apply"
          @click="$emit('onActivate', promocode)"
        >
          {{ $t('apply') }}
        </UiButtonNew>
        <span
          v-else-if="isAppliedInCart"
          class="promocode-card__done-label promocode-card__done-label_applied"
        >
          {{ $t('appliedInCart') }}
        </span>
        <span v-else-if="isApplied" class="promocode-card__done-label">
          {{ $t('applied', [compActivatedAt]) }}
        </span>
        <span v-else class="promocode-card__done-label">
          {{ $t('expired', [compUntil]) }}
        </span>
      </div>
    </div>
    <ConditionsModal
      :name="`promocode-conditions-${promocode}`"
      :minimum-sum="minimumSum"
      :max-discount="percentageDiscountLimit"
      :is-archive="isArchive"
      :is-applied="isAppliedInCart"
      :product-name="productName"
      :special-price="specialPrice"
      @onActivate="onConditionsActivate"
      @onClose="onConditionsClose"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { ProductBDService } from '@services/product-db';
import { SentryService } from '@services/sentry';
import UiButtonNew from '@shared/components/ui/UiButtonNew.vue';
import ConditionsModal from './ConditionsModal.vue';

export default {
  name: 'PromocodeCard',
  components: {
    UiButtonNew,
    ConditionsModal,
  },
  inject: ['bottomSheetPopup', 'bottomSheet'],
  props: {
    discount: {
      type: Number,
      default: null,
    },
    until: {
      type: String,
      default: '',
    },
    promocode: {
      type: String,
      required: true,
    },
    /**
     * bonus — за приглашённого друга
     * general — остальное
     */
    reason: {
      type: String,
      default: '',
    },
    /**
     * Минимальная сумма заказа для применения промокода,
     * выводится в модалке Условия
     */
    minimumSum: {
      type: Number,
      default: 0,
    },
    isArchive: {
      type: Boolean,
      default: false,
    },
    /**
     * флаг, что промокод в архиве, потому что был применён
     */
    isApplied: {
      type: Boolean,
      default: false,
    },
    isExpired: {
      type: Boolean,
      default: false,
    },
    /**
     * флаг, что промокод будет применён в корзине
     */
    isAppliedInCart: {
      type: Boolean,
      default: false,
    },
    activatedAt: {
      type: String,
      default: '',
    },
    /**
     * промокод на бесплатную доставку
     */
    freeDelivery: {
      type: Boolean,
      default: false,
    },
    /**
     * промокод со скидкой на процент от суммы заказа
     */
    isPercentageDiscount: {
      type: Boolean,
      default: false,
    },
    /**
     * максимальная сумма скидки если промокод на процент от суммы заказа
     */
    percentageDiscountLimit: {
      type: Number,
      default: 0,
    },
    productId: {
      type: Number,
      default: null,
    },
    specialPrice: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      product: null,
      rightIcon: new URL('@img/icons/arrow-right.svg', import.meta.url).href,
    };
  },
  computed: {
    compUntil() {
      return dayjs(this.until).format('DD.MM.YYYY');
    },
    compActivatedAt() {
      return dayjs(this.activatedAt).format('DD.MM.YYYY');
    },
    productName() {
      if (!this.product) {
        return '';
      }

      if (this.$i18n.locale === 'kk') {
        return this.product?.name_kk || this.product?.name;
      }

      return this.product?.name;
    },
  },
  mounted() {
    this.getProduct();
  },
  methods: {
    onConditionsClick() {
      const { promocode } = this;
      Analitycs.logEvent(EVENTS.REF_PROMOCODE_PAGE_PROMOCODE_CONDITIONS, {
        promocode,
      });
      this.bottomSheetPopup.show(`promocode-conditions-${promocode}`);
    },
    onConditionsActivate() {
      this.bottomSheet.hide();
      this.$emit('onActivate', this.promocode);
    },
    onConditionsClose() {
      this.bottomSheet.hide();
    },
    async getProduct() {
      if (!this.productId) {
        return;
      }

      try {
        const data = await ProductBDService.getProductsByIds([this.productId]);
        this.product = data?.[0] || null;
      } catch (err) {
        SentryService.captureError(err, 'Promocode Product Card');
      }
    },
  },
  i18n: {
    messages: {
      ru: {
        activeUntil: 'до {0}',
        conditions: 'условия',
        apply: 'Применить',
        applied: 'применён {0}',
        expired: 'истёк {0}',
        appliedInCart: 'Применён в корзине',
        conditionsDesktop: 'условия применения',
        refBonus: 'за первый заказ друга',
        zeroTengeForDelivery: '0 ₸ за доставку',
        productGiftTitle: 'товар в подарок',
      },
      kk: {
        activeUntil: '{0} дейін',
        conditions: 'шарттары',
        apply: 'Қолдану',
        applied: '{0} қолданылады',
        expired: '{0} мерзімі өтті',
        appliedInCart: 'Себетте қолданылды',
        conditionsDesktop: 'қолдану шарттары',
        refBonus: 'досыңыздың алғашқы тапсырысы үшін',
        zeroTengeForDelivery: 'Тапсырыс үшін 0 ₸',
        productGiftTitle: 'сыйлық тауар',
      },
    },
  },
};
</script>

<style scoped>
.promocode-card {
  border-radius: 16px;
  border: 2px solid #f1f2f7;
  --discount-color: #000;
}

.promocode-card__container {
  position: relative;
  z-index: 1;
  padding: 14px 14px 20px;
  display: grid;
  row-gap: 32px;
  grid-template-areas:
    'discount   promocode'
    'conditions status';
  grid-template-columns: 164px 1fr;
}

.promocode-card_archive {
  --discount-color: #d4d3df;
}

.promocode-card__area-discount {
  grid-area: discount;
}

.promocode-card__area-promocode {
  grid-area: promocode;
  text-align: right;
}

.promocode-card__area-conditions {
  grid-area: conditions;
  display: flex;
  align-items: center;
}

.promocode-card__area-status {
  grid-area: status;
  text-align: right;
  height: auto;
  min-width: 144px;
}

.promocode-card__title {
  color: var(--discount-color, #000);
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.promocode-card__until {
  @apply text-sirius-gray-1000 text-xs;
  padding-top: 4px;
}

.promocode-card__promocode-value-wrapper {
  height: 24px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
}

.promocode-card__promocode-value {
  @apply text-sirius-gray-900;
  display: inline-block;
  vertical-align: middle;
  max-width: 130px;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.promocode-card__reason-value {
  @apply text-xs pt-1 text-sirius-gray-1000;
}

.promocode-card__conditions {
  font-weight: 500;
  @apply text-sm text-sirius-gray-900;
}

.promocode-card__conditions::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.promocode-card__done-label {
  font-weight: 500;
  padding: 6px 12px 8px;
  font-size: 12px;
  line-height: 14px;
  @apply inline-block
    text-sirius-gray-100
    bg-sirius-gray-200
    rounded-xl;
}

.promocode-card__done-label_applied {
  @apply text-sirius-green-200;
}

.promocode-card__btn-apply {
  position: relative;
  z-index: 1;
  @apply px-4 font-medium;
}

@media (max-width: 370px) {
  .promocode-card__title {
    font-size: 16px;
    line-height: 20px;
  }

  .promocode-card__promocode-value {
    font-size: 14px;
    line-height: 18px;
    max-width: 100px;
  }
}

@media (min-width: 768px) {
  .promocode-card__container {
    gap: 32px 12px;
    grid-template-columns: 116px auto 157px;
    grid-template-areas:
      'discount   promocode   status'
      'conditions conditions  conditions';
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
  }

  .promocode-card__until {
    padding-top: 3px;
  }

  .promocode-card__area-promocode {
    text-align: left;
  }

  .promocode-card__area-status {
    padding-top: 4px;
  }
}
</style>

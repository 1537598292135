import nativeBridge from '@shared/utils/native-bridge';
import { rFetchReferralPromocode } from '@shared/services/api/apis';
import { Logger } from '@shared/services/logger';
import { getReferralMessage } from './referral-message';

function fetchPromocode() {
  return rFetchReferralPromocode().then(data => data.data);
}

/**
 * Вызывает нативное окно шэринга
 * @param {string} promocode
 */
function sharePromocode(promocode, locale) {
  Logger.event('sharePromocode', getReferralMessage(promocode, locale));
  nativeBridge.shareText(getReferralMessage(promocode, locale));
}

/**
 * Когда пользователь переходит по реферальной ссылке —
 * сохраняем его промокод чтобы позже применить в корзине
 * @param {string} promocode
 */
function savePromocode(promocode) {
  window.localStorage.setItem('referral-promocode', promocode);
}

function getPromocode() {
  return window.localStorage.getItem('referral-promocode');
}

function hasPromocode() {
  return !!getPromocode();
}

/**
 * Скрывает и больше не показывает плашку о рефералке
 */
function hideReferralBottomSheet() {
  window.localStorage.setItem('referral-bottomsheet-hidden', true);
}

function isReferralBottomSheetHidden() {
  return window.localStorage.getItem('referral-bottomsheet-hidden');
}

export const ReferralService = {
  fetchPromocode,
  sharePromocode,
  savePromocode,
  getPromocode,
  hasPromocode,
  hideReferralBottomSheet,
  isReferralBottomSheetHidden,
};

import {
  INITIAL_COORDS,
  YMAPS_SETTINGS,
  NEW_POLYGON_SETTINGS,
} from '@services/map/constants';
import { defineGeolocation, geocodeSinglePosition } from '@services/map/utils';
import { validateGeoObject } from '@services/map/utils/validate-geoobject';
import { getDeliveryZoneValidationsOfCoords } from '@services/map/utils/map-polygon';
import { loadYmap } from 'vue-yandex-maps';
import { $log } from '@/utils/plugins/logger';
import { debounce, throttle } from 'lodash';

export default class YandexMap {
  constructor(
    onMapInit,
    onMapClick,
    onMapActionEnd,
    onMapActionStart,
    onMapCenterUpdated
  ) {
    // Используем единые параметры независимо от устройства
    this.onMapInit = onMapInit;
    this.onMapClick = onMapClick;
    this.onMapActionEnd = onMapActionEnd;
    this.onMapActionStart = onMapActionStart;

    // Используем десктопные параметры debounce для всех устройств
    this.onMapCenterUpdated = debounce(onMapCenterUpdated, 200);

    this.polygons = {
      regular: [],
      extended: [],
    };

    // Параметры для контроля состояния карты
    this.isMapMoving = false; // Контроль движения карты
    this.objectManager = null; // Для управления полигонами
    this.lastCoords = null; // Для кеширования последних координат
  }

  getOptions() {
    if (process.env.NODE_ENV !== 'production') {
      $log('YandexMap getOptions', this.map);
    }

    if (!this.map)
      return {
        coords: INITIAL_COORDS,
        zoom: 16,
      };

    const mapCoords = this.map.getCenter();
    return {
      coords: mapCoords,
      zoom: this.map.getZoom(),
    };
  }

  onCenterUpdated() {
    // Прерываем обновление, если карта не движется
    if (this.isMapMoving) return;

    let options = this.getOptions();

    if (process.env.NODE_ENV !== 'production') {
      $log('YandexMap onCenterUpdated', { options });
    }

    // Используем кеширование последних координат
    if (this.lastCoords &&
      Math.abs(this.lastCoords[0] - options.coords[0]) < 0.0001 &&
      Math.abs(this.lastCoords[1] - options.coords[1]) < 0.0001) {
      return; // Пропускаем обновление при минимальном изменении координат
    }

    this.lastCoords = [...options.coords];

    // Используем фиксированный интервал для всех устройств
    if (this.lastGeocodeRequest && (Date.now() - this.lastGeocodeRequest < 1000)) {
      let isPolygonsLoaded =
        this.polygons.regular.length || this.polygons.extended.length;

      this.onMapCenterUpdated({
        ...options,
        isPolygonsLoaded,
      });

      return;
    }

    this.lastGeocodeRequest = Date.now();

    this.getGeoObjectByCoords(options.coords).then(validatedCollection => {
      let isPolygonsLoaded =
        this.polygons.regular.length || this.polygons.extended.length;
      let data = {
        ...options,
        ...validatedCollection,
        isPolygonsLoaded,
      };
      this.onMapCenterUpdated(data);
    });
  }

  getGeoObjectByCoords(coords) {
    if (process.env.NODE_ENV !== 'production') {
      $log('YandexMap getGeoObjectByCoords', { coords });
    }

    return geocodeSinglePosition(window.ymaps, coords).then(collection => {
      let geoObject = collection.featureMember[0];
      const [long, lat] = geoObject.GeoObject.Point.pos.split(' ');

      let geoObjectValidations = validateGeoObject(geoObject);

      let isInDeliveryZoneValidations = getDeliveryZoneValidationsOfCoords(
        window.ymaps,
        [lat, long],
        this.polygons.regular,
        this.polygons.extended
      );

      let validatedGeoObject = {
        ...geoObject,
        ...geoObjectValidations,
        ...isInDeliveryZoneValidations,
      };
      return validatedGeoObject;
    });
  }

  zoom(type = 'in') {
    if (process.env.NODE_ENV !== 'production') {
      $log('YandexMap zoom', { type });
    }

    let currentZoom = this.map.getZoom();
    const maxZoom = 20; // Используем десктопное значение
    const minZoom = 3;

    if (type === 'in' && currentZoom >= maxZoom) return;
    if (type === 'out' && currentZoom <= minZoom) return;

    // Проверяем доступность модуля smooth
    if (window.ymaps && window.ymaps.modules && window.ymaps.modules.require) {
      // Загружаем и используем модуль для плавного зума
      window.ymaps.modules.require(['map.behavior.smooth.ZoomManager'], function(smoothZoom) {
        // Создаем экземпляр менеджера плавного зума, если ещё не создан
        if (!this.smoothZoomManager) {
          this.smoothZoomManager = new smoothZoom(this.map);
        }

        // Определяем дельту зума
        const delta = type === 'in' ? 1 : -1;

        // Устанавливаем параметры анимации (используем десктопные)
        const duration = 400;

        // Выполняем плавное изменение зума
        this.smoothZoomManager.zoom(delta, {
          duration: duration,
          timingFunction: 'ease-in-out'
        });
      }.bind(this));
    } else {
      // Запасной вариант, если модуль недоступен
      const newZoom = type === 'in' ?
        Math.min(currentZoom + 0.5, maxZoom) :
        Math.max(currentZoom - 0.5, minZoom);

      const options = { duration: 500, checkZoomRange: true, useMapMargin: true };

      return this.map.setZoom(newZoom, options);
    }
  }

  defineByCoords(coords) {
    if (process.env.NODE_ENV !== 'production') {
      $log('YandexMap defineByCoords', { coords });
    }

    // Используем плавное перемещение для всех устройств
    const options = { duration: 500, timingFunction: 'ease' };

    return this.map
      .setCenter(coords, 16, options)
      .then(res => {
        if (process.env.NODE_ENV !== 'production') {
          $log('YandexMap defineByCoords then', { res });
        }
      });
  }

  async findGeoLocation(timeout = 0, fallbackCoords) {
    if (process.env.NODE_ENV !== 'production') {
      $log('YandexMap findGeoLocation', { timeout, fallbackCoords });
    }

    const timeoutPromise = new Promise(resolve => {
      if (process.env.NODE_ENV !== 'production') {
        $log('YandexMap timeoutPromise', { timeout });
      }
      return setTimeout(() => resolve(fallbackCoords), timeout);
    });

    const definePromise = new Promise(() => {
      if (process.env.NODE_ENV !== 'production') {
        $log('YandexMap definePromise', { timeout });
      }
      return defineGeolocation(window.ymaps);
    });

    let promises = [definePromise];
    if (timeout !== 0) {
      promises.push(timeoutPromise);
    }

    return Promise.race(promises)
      .then(coords => {
        if (process.env.NODE_ENV !== 'production') {
          $log('YandexMap Promise.race', { coords });
        }
        return this.defineByCoords(coords);
      })
      .then(() => {
        // Без задержки для всех устройств
        this.onCenterUpdated();
      });
  }

  onClick(e) {
    var coords = e.get('coords');
    if (process.env.NODE_ENV !== 'production') {
      $log('YandexMap onClick', { coords });
    }

    // Плавное перемещение для всех устройств
    const options = { duration: 300 };

    return this.map.panTo(coords, options).then(() => {
      this.onMapClick(this.getOptions());
      // Без дополнительной задержки
      this.onCenterUpdated();
    });
  }

  setListeners() {
    if (process.env.NODE_ENV !== 'production') {
      $log('YandexMap setListeners');
    }

    // Стандартное время для throttle
    const throttledActionEnd = throttle(() => {
      this.isMapMoving = false;
      this.onCenterUpdated();
    }, 200);

    // Throttle для обработки кликов
    const throttledClick = throttle(e => this.onClick(e), 200);

    this.map?.events.add('click', throttledClick);

    this.map?.events.add('actionbegin', event => {
      if (process.env.NODE_ENV !== 'production') {
        $log('YandexMap actionbegin', event);
      }
      this.onMapActionStart(this.getOptions());
      this.isMapMoving = true; // Карта начала движение
    });

    this.map?.events.add('actionend', event => {
      if (process.env.NODE_ENV !== 'production') {
        $log('YandexMap actionend', event);
      }

      this.onMapActionEnd(this.getOptions());

      // Используем throttled функцию для предотвращения слишком частых обновлений
      throttledActionEnd();
    });
  }

  createMap(id, coords, zoom) {
    if (process.env.NODE_ENV !== 'production') {
      $log('YandexMap createMap', { id, coords, zoom });
    }

    const mapOptions = {
      yandexMapDisablePoiInteractivity: true,
      suppressMapOpenBlock: true,
    };

    this.map = new window.ymaps.Map(
      id,
      {
        zoom: zoom || 16, // Стандартный зум для всех устройств
        center: coords || INITIAL_COORDS,
        controls: [],
      },
      mapOptions
    );
  }

  createObjectManager() {
    // Создаем ObjectManager для эффективного управления множеством объектов
    this.objectManager = new window.ymaps.ObjectManager({
      clusterize: false,
      gridSize: 64, // Стандартный размер грида для всех устройств
      geoObjectOpenBalloonOnClick: false, // Отключаем балуны
      geoObjectHideIconOnBalloonOpen: false,
      geoObjectBalloonPanelMaxMapArea: 0
    });

    // Добавляем обработчик кликов для ObjectManager
    const throttledClick = throttle(e => this.onClick(e), 200);

    this.objectManager.objects.events.add('click', throttledClick);

    this.map.geoObjects.add(this.objectManager);
  }

  setPolygons(polygons) {
    if (process.env.NODE_ENV !== 'production') {
      $log('YandexMap setPolygons', { polygons });
    }

    if (!this.map) return;
    if (!polygons?.regular?.length) return;

    this.polygons = polygons;

    // Если ObjectManager уже существует, удаляем его
    if (this.objectManager) {
      this.map.geoObjects.remove(this.objectManager);
    }

    // Создаем ObjectManager
    this.createObjectManager();

    // Конвертируем полигоны в формат для ObjectManager
    const features = [];

    // Обрабатываем extended полигоны
    polygons.extended.forEach((polygon, index) => {
      features.push({
        type: 'Feature',
        id: `extended_${index}`,
        geometry: {
          type: 'Polygon',
          coordinates: polygon.coordinates,
        },
        options: NEW_POLYGON_SETTINGS.extended
      });
    });

    // Обрабатываем regular полигоны
    polygons.regular.forEach((polygon, index) => {
      features.push({
        type: 'Feature',
        id: `regular_${index}`,
        geometry: {
          type: 'Polygon',
          coordinates: polygon.coordinates,
        },
        options: polygon.type === 'regularNearExtended'
          ? NEW_POLYGON_SETTINGS.regularNearExtended
          : NEW_POLYGON_SETTINGS.regular
      });
    });

    // Добавляем все объекты в ObjectManager одним вызовом
    this.objectManager.add({
      type: 'FeatureCollection',
      features: features
    });

    // Стандартная задержка для всех устройств
    setTimeout(() => this.onCenterUpdated(), 100);
  }

  async initMap(id, coords, zoom, listeners = true) {
    if (process.env.NODE_ENV !== 'production') {
      $log('YandexMap initMap', { id, coords, zoom, listeners });
    }

    await loadYmap(YMAPS_SETTINGS);

    this.createMap(id, coords, zoom);

    this.onMapInit(this.getOptions());

    // Отложенное получение геообъектов с единой задержкой
    setTimeout(() => {
      this.onCenterUpdated();
    }, 300);

    if (listeners) this.setListeners();
  }
}

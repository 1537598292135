<template>
  <div
    class="MainLayout layout"
    :style="`${stickyHeader ? 'min-height: min-content' : 'height: 100%;'}`"
  >
    <template v-if="$isWebView">
      <header class="w-full fixed z-modal">
        <slot name="header">
          <ToolBar />
        </slot>
      </header>

      <main
        class="layout-main"
        :class="`${headerSpace} ${!withFooter ? '--without-footer' : ''}`"
      >
        <slot></slot>
        <div v-if="hasCheckoutReminder" style="height: 68px"></div>
        <div v-if="isPaidOrder && withCheckoutReminder" class="h-11"></div>
      </main>

      <footer v-if="withFooter" class="layout-footer">
        <transition name="slide-up" @after-enter="paidOrderShown">
          <div
            v-show="isPaidOrder && withCheckoutReminder"
            v-floating-element="'paid-delivery'"
            class="PaidDeliveryWrapperContainer"
            :class="[hasCheckoutReminder ? 'bottom-28' : 'bottom-14']"
          >
            <PaidDeliveryWrapper />
          </div>
        </transition>

        <transition name="slide-up">
          <div
            v-if="hasCheckoutReminder"
            v-floating-element="'go-to-cart-button'"
            class="pb-3 pt-1 px-4 bg-white w-full absolute bottom-14 transition-all duration-500"
            :class="{
              'rounded-t-3xl': !showPaymentConfigBlock,
            }"
          >
            <GoToCartButton
              :class="`${!showPaymentConfigBlock ? 'mt-1' : ''}`"
              :price="totalPrice"
            />
          </div>
        </transition>

        <BottomBar
          v-floating-element="'bottom-bar'"
          class="pt-3 pb-3 transition-all duration-500"
          :class="`${
            !(hasCheckoutReminder || (isPaidOrder && withCheckoutReminder))
              ? 'shadow-checkout-shadow rounded-t-3xl'
              : ''
          }`"
        />
      </footer>
    </template>

    <template v-else>
      <slot name="header-wrapper">
        <header class="w-full fixed z-modal">
          <slot name="header">
            <DeskHeader v-if="$isDesktop" />
            <WebMobileHeader v-else />
          </slot>
        </header>
      </slot>
      <main
        class="pb-20 md:pb-44"
        :class="`${stickyHeader ? 'mt-0' : 'mt-14 md:mt-38'}`"
      >
        <slot></slot>

        <div
          v-if="!$isDesktop"
          class="fixed w-full bottom-0 z-footer"
          style="padding-bottom: env(safe-area-inset-bottom)"
        >
          <transition name="slide-up" @after-enter="paidOrderShown">
            <div
              v-show="isPaidOrder && withCheckoutReminder"
              v-floating-element="'paid-delivery'"
              class="PaidDeliveryWrapperContainer"
              :class="[hasCheckoutReminder ? 'bottom-14' : 'bottom-0']"
            >
              <PaidDeliveryWrapper />
            </div>
          </transition>

          <transition name="slide-up">
            <div
              v-if="hasCheckoutReminder"
              v-floating-element="'go-to-cart-button'"
              class="px-4 pt-1 pb-3 bg-white w-full absolute bottom-0 z-minus transition-all duration-500"
              :class="`${!showPaymentConfigBlock ? 'rounded-t-3xl shadow-checkout-shadow' : ''}`"
            >
              <GoToCartButton
                :class="`${!showPaymentConfigBlock ? 'mt-1' : ''}`"
                :price="totalPrice"
              />
            </div>
          </transition>
        </div>
      </main>
      <FooterDesk
        :class="[
          !$isDesktop && hasCheckoutReminder
            ? 'pb-28 md:pb-15'
            : 'pb-12 md:pb-15',
        ]"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { Analitycs, EVENTS } from '@shared/services/analitycs';
import DeskHeader from '@components/header/desk-header/DeskHeader.vue';
import WebMobileHeader from '@components/header/WebMobileHeader.vue';
import ToolBar from '@shared/components/layout/ToolBar.vue';
import BottomBar from '@layout/BottomBar.vue';
import { DeliveryConfigService } from '@services/delivery-config';
import FooterDesk from './FooterDesk.vue';
import GoToCartButton from '../checkout/GoToCartButton.vue';
export default {
  name: 'MainLayout',
  components: {
    ToolBar,
    BottomBar,
    FooterDesk,
    DeskHeader,
    GoToCartButton,
    WebMobileHeader,
    PaidDeliveryWrapper: () =>
      import('@components/paid-delivery/PaidDeliveryWrapper.vue'),
  },
  props: {
    withFooter: {
      type: Boolean,
      default: true,
    },
    withBottomToolbar: {
      type: Boolean,
      default: false,
    },
    withCheckoutReminder: {
      type: Boolean,
      default: false,
    },
    stickyHeader: {
      type: Boolean,
      default: false,
    },
    headerSpaceClass: {
      type: String,
      default: 'mt-14',
    },
  },
  data: () => ({
    timer: null,
    showPaymentConfigBlock: false,
  }),
  computed: {
    ...mapState('paidOrder', ['type']),
    ...mapGetters(['isAuthorized']),
    ...mapGetters('cart', [
      'checkoutReminder',
      'cartTotalPriceWithDiscount',
      'promocode',
    ]),
    ...mapGetters('paidOrder', [
      'paymentConfigs',
      'activePaymentConfig',
      'showPaymentConfig',
    ]),
    ...mapGetters('samplings', ['samplingTotalPrice']),
    ...mapGetters('promocodes', ['availablePromocodeGiftProduct']),
    headerSpace() {
      if (this.withBottomToolbar) return 'mt-30';
      return this.headerSpaceClass;
    },
    hasCheckoutReminder() {
      return this.checkoutReminder && this.withCheckoutReminder;
    },
    isPaidOrder() {
      return this.showPaymentConfigBlock;
    },
    deliveryPrice() {
      return this.activePaymentConfig?.delivery_price;
    },
    totalPrice() {
      return (
        this.cartTotalPriceWithDiscount +
        this.samplingTotalPrice +
        (this.availablePromocodeGiftProduct?.price || 0)
      );
    },
  },
  watch: {
    showPaymentConfig: {
      immediate: true,
      handler(newVal) {
        this.onChangePaymentConfig(newVal);
      },
    },
    deliveryPrice: {
      immediate: true,
      handler(val) {
        if (DeliveryConfigService.checkPriceChanged(val)) {
          this.logChangePaymentConfigEvent();
        }
      },
    },
  },
  methods: {
    onChangePaymentConfig(value) {
      clearTimeout(this.timer);

      if (value) {
        this.showPaymentConfigBlock = true;
        return;
      }

      this.timer = setTimeout(() => {
        this.showPaymentConfigBlock = false;
      }, 1500);
    },
    paidOrderShown() {
      Analitycs.logEvent(EVENTS.PAID_ORDER_SHOWN);
    },
    logChangePaymentConfigEvent() {
      const cartPrice =
        this.cartTotalPriceWithDiscount +
        this.samplingTotalPrice +
        (this.availablePromocodeGiftProduct?.price || 0);
      const deliveryPrice = this.activePaymentConfig?.delivery_price;

      Analitycs.logEvent(EVENTS.DELIVERY_CONDITIONS_CHANGED, {
        deliveryPrice,
        cartPrice,
        deliveryFrom: this.activePaymentConfig?.price_from,
        deliveryTo: this.activePaymentConfig?.price_to,
        analyticType: this.type,
        appliedPromocodes: this.promocode,
      });
    },
  },
};
</script>

<style scoped>
.layout {
  @apply flex flex-col text-sirius-black-100;
}
.layout-main {
  padding-bottom: calc(5rem + env(safe-area-inset-bottom));
}
.--without-footer {
  padding-bottom: calc(4rem + env(safe-area-inset-bottom));
}
.layout-footer {
  @apply z-footer w-full bottom-0 fixed;
  padding-bottom: env(safe-area-inset-bottom);
}
.PaidDeliveryWrapperContainer {
  @apply pt-1 pb-3 px-4 
    w-full absolute z-minus 
    bg-white 
    rounded-t-3xl shadow-checkout-shadow 
    transition-all duration-500;
}
</style>

<template>
  <BottomSheetPopup
    :name="name"
    style="--modal-width: 472px"
    close-btn
    size="lg"
    with-back-button
    with-title
  >
    <template #title>
      <p class="w-full text-center font-bold text-lg mx-auto">
        {{ $t('promocodeApplied') }}
      </p>
    </template>
    <div class="px-4 pb-4 pt-2 md:px-8 md:pt-8 md:pb-10">
      <p>
        <span v-html="$t('promocodeAppliedText')" />
      </p>
      <div class="pt-6">
        <PromocodeLabel type="success" />
      </div>
      <div class="pt-6">
        <UiButtonNew class="w-full font-bold" size="lg" @click="onReady">
          {{ $t('gotIt') }}
        </UiButtonNew>
      </div>
    </div>
  </BottomSheetPopup>
</template>

<script>
import nativeBridge from '@shared/utils/native-bridge';
import BottomSheetPopup from '@ui/bottom-sheet-popup/BottomSheetPopup.vue';
import UiButtonNew from '@ui/UiButtonNew.vue';
import PromocodeLabel from './PromocodeLabel.vue';

export default {
  name: 'PromocodeAppliedModal',
  components: {
    BottomSheetPopup,
    PromocodeLabel,
    UiButtonNew,
  },
  inject: ['bottomSheetPopup'],
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  methods: {
    onReady() {
      nativeBridge.hapticSelection();
      this.bottomSheetPopup.hide();
    },
  },
  i18n: {
    messages: {
      ru: {
        promocodeApplied: 'Промокод применён',
        promocodeAppliedText: `
          Ура! Ваш реферальный промокод применён в&nbsp;корзине
        `,
        nice: 'Отлично!',
        gotIt: 'Понятно',
      },
      kk: {
        promocodeApplied: 'Промокод қолданыста',
        promocodeAppliedText: `
          Алақай! Рефералды промокод себетте қолданылады
        `,
        nice: 'Жарайсың!',
        gotIt: 'Түсінікті',
      },
    },
  },
};
</script>

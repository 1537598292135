import { isDesktop } from '@/utils/device';

/**
 * Добавляет глобальный проп $isDesktop
 */

let state;

export default {
  install(VueLocal) {
    state = VueLocal.observable({ value: isDesktop() });

    window.addEventListener('resize', () => {
      state.value = isDesktop();
    });

    VueLocal.prototype.isDesktop = state.value;
    Object.defineProperty(VueLocal.prototype, '$isDesktop', {
      get() {
        return state.value;
      },
    });
  },
};

import { client } from '../../clients/client';
import { createCancelableRequestConfig } from '../../clients/utils';
import { $log } from '@/utils/plugins/logger';
/**
 * Выдача заказов юзера
 */

// eslint-disable-next-line camelcase
export default function ({ tab, page = 1, limit = 20, is_paid = 1 }) {
  $log(
    'Creating fetch-orders request',
    { tab, page, limit, is_paid },
    'Orders'
  );
  const { cancelToken, onFinish } = createCancelableRequestConfig();

  const params = { tab, page, limit, is_paid };
  return client
    .get(`/lavka/v2/orders`, { params, cancelToken })
    .then(res => {
      $log(
        'Fetch-orders request completed',
        { tab, page, limit, is_paid },
        'Orders'
      );
      return res.data;
    })
    .catch(err => {
      $log(
        'Fetch-orders request failed',
        {
          tab,
          page,
          limit,
          is_paid,
          error: err.message,
        },
        'Orders'
      );
      throw err;
    })
    .finally(() => {
      $log(
        'Fetch-orders request finished',
        {
          tab,
          page,
          limit,
          is_paid,
        },
        'Orders'
      );
      onFinish();
    });
}

import { ref } from 'vue';

export const TAILWIND_Z_MODAL_INDEX = 70;
const MAX_SAFE_ARRAY_SIZE = 1000;

const activeZIndexes = ref([]); // Track active modals

export function useZIndex() {
  const getNextZIndex = () => {
    if (activeZIndexes.value.length >= MAX_SAFE_ARRAY_SIZE) {
      console.warn('Too many active z-indexes, resetting to base value');
      activeZIndexes.value = [];
      return TAILWIND_Z_MODAL_INDEX;
    }

    let nextZIndex = TAILWIND_Z_MODAL_INDEX;
    if (activeZIndexes.value.length) {
      const maxZIndex = Math.max(...activeZIndexes.value);
      nextZIndex = maxZIndex + 1;
    }
    activeZIndexes.value.push(nextZIndex);
    return nextZIndex;
  };

  const releaseZIndex = zIndex => {
    const index = activeZIndexes.value.indexOf(zIndex);
    if (index !== -1) {
      activeZIndexes.value.splice(index, 1); // Remove zIndex when modal closes
    }
  };

  return { getNextZIndex, releaseZIndex };
}

import nativeBridge from '@shared/utils/native-bridge';
import { WebApp } from '@shared/WebApp';
import { DevConfigs } from '@/utils/dev-config';

/**
 * @typedef IPaymentMiniAppPayload
 * @prop {string} deeplink only for native payment link for payment
 *
 * @typedef IPaymentWebAppPayload
 * @prop {string} backlink only for web payment, link for payment
 * @prop {string} successUrl only for web payment, return to href on success
 * @prop {string} failureUrl only for web payment, return to href on failure
 * @prop {string} isSucces only for web dev payment, return to href on success
 */

/**
 * Эмулирует оплату в браузере на тестовом окружении, т.к. на тесте не работает оплата — проблема экосистемы
 *
 * Посути просто перенаправляет на failureUrl или же successUrl через какое то время
 * @param {IPaymentWebAppPayload} options
 */
function webDevPay(options) {
  const { href } = window.location;
  const { origin } = new URL(href);

  const successUrl = options.successUrl
    ? `${origin}${options.successUrl}`
    : origin;

  const failureUrl = options.failureUrl
    ? `${origin}${options.failureUrl}`
    : href;

  setTimeout(() => {
    if (options.isSuccess) window.location.href = successUrl;
    else window.location.href = failureUrl;
  }, 5000);
}

/**
 * Оплата
 * @param {IPaymentMiniAppPayload|IPaymentWebAppPayload} payload
 * @returns
 */
function pay(payload) {
  const isMiniApp = nativeBridge.isWebView();
  const isWebApp = !nativeBridge.isWebView();

  if (isMiniApp && DevConfigs.isDev) return Promise.resolve('success');

  if (isMiniApp) {
    return nativeBridge.pay(payload.deeplink).then(res => {
      return Promise.resolve(res);
    });
  }

  if (isWebApp && DevConfigs.isDev) {
    Object.assign(payload, { isSuccess: true });
    webDevPay(payload);
    return Promise.resolve();
  }

  if (isWebApp) {
    return new Promise(resolve => {
      WebApp.pay(payload);
      return resolve();
    });
  }

  return Promise.reject(new Error('PaymentService: unexpected error'));
}

export const PaymentService = {
  pay,
};

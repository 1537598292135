import { client } from '../../clients/client';
import { isAuthorizedUrl } from '../../utils/is-authorized-url';
import { createCancelableRequestConfig } from '../../clients/utils';

export default function (coords, isAuthorized = false) {
  const { cancelToken, onFinish } = createCancelableRequestConfig();
  return client
    .get(
      `/lavka/v1${isAuthorizedUrl(isAuthorized)}/warehouses/get_by_coordinate`,
      {
        params: {
          lat: coords.lat,
          long: coords.long,
        },
        cancelToken,
      }
    )
    .then(res => res.data)
    .finally(onFinish);
}

<template>
  <UiButton class="AddAddressButtonbtn" size="LG" @click="$emit('onCreate')">
    <IconPlus /> <span>{{ $t('addAddress') }}</span>
  </UiButton>
</template>

<script>
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import IconPlus from './components/IconPlus.vue';

export default {
  components: { UiButton, IconPlus },
  i18n: {
    messages: {
      ru: { addAddress: 'Добавить новый адрес' },
      kk: { addAddress: 'Жаңа мекенжай қосу' },
    },
  },
};
</script>

<style scoped>
.btn {
  @apply font-bold text-lg whitespace-nowrap;
}

.btn span {
  @apply ml-1;
}
</style>

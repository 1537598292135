<template>
  <MainLayout class="NewYearView__layout" header-space-class="mt-13">
    <template #header>
      <ToolBar v-if="$isWebView">
        <p v-html="$t('xmasFatherNear')" />
      </ToolBar>
    </template>
    <div class="NewYearView__main">
      <TopHero class="md:mb-14" />
      <ContestSponsor class="" />
      <TopMenu
        class="pt-12 TopMenu"
        :show-score="showScore"
        :score="currentUserScore"
        @onScrollTo="scrollToId($event)"
      />
      <!--    <ContestDone />-->
      <CurrentScore
        v-if="isAuthorized && currentUserScore"
        class="CurerntScore"
        :score="currentUserScore"
        :place="currentUserPlace"
        :participate="currentUserScore >= 50"
        @onScrollTo="scrollToId('ny-table')"
      />
      <ContestConditions v-if="!contestEnded" class="mt-20 pb-20 md:pb-32" />
      <div v-else class="mb-16"></div>
      <ContestPrizes class="pb-20 md:pb-32" />
      <ContestTable
        class="ContestTable px-4 pb-14 md:pb-6"
        :score="currentUserScore"
        :place="currentUserPlace"
        :list="winnersList"
        @onAuthBtnClick="authorize"
      />
      <IphoneLottery v-if="contestEnded" class="mb-20 md:mb-32" />
      <ContestRules @onReadFull="onReadFull" />
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from '@layout/MainLayout.vue';
import ToolBar from '@shared/components/layout/ToolBar.vue';
import { mapGetters } from 'vuex';
import nativeBridge from '@shared/utils/native-bridge';
import { AuthService } from '@shared/services/auth';
import { NyContestService } from '@services/ny-contest';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { RULES_LINK_RU, RULES_LINK_KK } from '@services/ny-contest/config';

import ContestRules from '@/views/ny-2024-contest/components/ContestRules/ContestRules.vue';
import TopHero from './components/TopHero/TopHero.vue';
import CurrentScore from './components/CurrentScore/CurrentScore.vue';
import ContestPrizes from './components/ContestPrizes/ContestPrizes.vue';
import ContestTable from './components/ContestTable/ContestTable.vue';
import TopMenu from '@/views/ny-2024-contest/components/TopMenu/TopMenu.vue';
import ContestConditions from '@/views/ny-2024-contest/components/ContestConditions/ContestConditions.vue';
import ContestSponsor from '@/views/ny-2024-contest/components/ContestSponsor/ContestSponsor.vue';
import IphoneLottery from './components/IphoneLottery/IphoneLottery.vue';

export default {
  name: 'NY2024ContestView',
  components: {
    ContestSponsor,
    ContestConditions,
    TopMenu,
    ContestRules,
    ContestTable,
    ContestPrizes,
    MainLayout,
    ToolBar,

    TopHero,
    CurrentScore,
    IphoneLottery,
  },
  data: () => ({
    ratingData: null,
  }),
  computed: {
    ...mapGetters(['isAuthorized']),
    ...mapGetters('nyContest', [
      'showScore',
      'winnersList',
      'currentUserScore',
      'currentUserPlace',
      'contestEnded',
    ]),
  },
  mounted() {
    Analitycs.logEvent(EVENTS.NY_CONTEST_2023_PAGE_VIEWED, {
      from: this.$route.query.from,
    });
    NyContestService.loadDataToStore();

    this.prevBg = document.body.style.backgroundColor;
    document.body.style.backgroundColor = '#e9eeed';
  },
  beforeDestroy() {
    document.body.style.backgroundColor = this.prevBg;
  },
  methods: {
    scrollToId(id) {
      console.log('scrollToId', id);

      const el = document.getElementById(id);

      const getHeaderHeight = () => {
        if (this.$isDesktop) return 173;
        if (!this.$isWebView) return 72;
        return 52;
      };

      if (el) {
        const top =
          el.getBoundingClientRect().y +
          window.pageYOffset -
          getHeaderHeight() -
          20;
        window.scrollTo({ top, behavior: 'smooth' });
      }
    },
    authorize() {
      AuthService.authorize();
    },
    onReadFull() {
      //TODO: links
      let url = this.$i18n.locale === 'ru' ? RULES_LINK_RU : RULES_LINK_KK;
      if (this.$isWebView) {
        nativeBridge.openLink({ url });
      } else {
        window.open(url);
      }
    },
  },
  i18n: {
    messages: {
      ru: {
        xmasFatherNear: 'Дед&nbsp;Мороз&nbsp;Рядом',
      },
      kk: {
        xmasFatherNear: 'Рядом&nbsp;Аяз&nbsp;Атасы',
      },
    },
  },
};
</script>

<style scoped>
.NewYearView__layout {
  background: #e9eeed;
}
.NewYearView__main {
  @apply relative;
  background: #e9eeed;
  overflow-x: hidden;
  padding-bottom: 124px;
}
.NewYearView__lottery {
  margin-top: 32px;
}
.CurerntScore {
  @apply md:pb-12;
}
.ContestTable {
  @apply md:pb-32;
}
.TopMenu {
  @apply mb-10 md:mb-14;
}
</style>

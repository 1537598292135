export function useFreedomCashback() {
  const CASHBACK_RATE = 0.1;

  const calcPriceWithCashback = price => {
    if (!price || typeof price !== 'number' || price < 10) return null;
    return Math.ceil(price - price * CASHBACK_RATE);
  };

  const calcCashback = price => {
    if (!price || typeof price !== 'number' || price < 10) return null;

    return Math.floor(price * CASHBACK_RATE);
  };

  return {
    calcPriceWithCashback,
    calcCashback,
  };
}

<template>
  <div class="SpecialCategoryCatalogBanner px-4 mb-6" @click="goToCategory">
    <div class="SpecialCategoryCatalogBanner__container">
      <span v-html="$t('title')" />
      <img alt="banner_image" src="./images/twoplusone.png">
    </div>
  </div>
</template>

<script>
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { RahmetApp } from '@shared/RahmetApp';

export default {
  name: 'SpecialCategoryCatalogBanner',
  data() {
    return {
      categoryId: 1346,
      categoryName: 'Бери больше, плати меньше'
    }
  },
  methods: {
    goToCategory() {
      RahmetApp.hapticSelection();
      Analitycs.logEvent(EVENTS.CATEGORY_SELECTED, {
        categoryId: this.categoryId,
        categoryName: this.categoryName,
        from: 'catalog',
      });
      this.$router.push(`/category/${this.categoryId}`);
    }
  },
  i18n: {
    messages: {
      ru: {
        title: 'Бери больше — плати&nbsp;меньше',
      },
      kk: {
        title: 'Көбірек алып — аз&nbsp;төле',
      },
    },
  },
};
</script>

<style scoped>
.SpecialCategoryCatalogBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88px;
  width: 100%;
}

.SpecialCategoryCatalogBanner__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFE1B3;
  border-radius: 16px;
  width: 100%;
}

.SpecialCategoryCatalogBanner__container span{
  @apply text-sirius-black-700 text-base font-bold w-48 ml-3;
  line-height: 20px;
}

.SpecialCategoryCatalogBanner img {
  height: 88px;
}

</style>

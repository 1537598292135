import amplitude from 'amplitude-js';
import { ClientService } from '@shared/services/client';
import { SegmentService } from '@services/segment';
import { Logger } from '@shared/services/logger';
import nativeBridge from '@shared/utils/native-bridge';
import { CityService } from '@services/city';
import { store } from '@/store';
import { isDesktop } from '@/utils/device';
import {
  AMPLITUDE_KEY,
  AMPLITUDE_ECOSYSTEM_INSTANCE_NAME,
  firebaseEvents,
  segmentEvents,
  appsflyerEvents,
} from './config';

const state = {
  pushToken: undefined,
};

/**
 *
 * @returns {Number | false}
 */
function getServiceFee() {
  const price = store.getters['paidOrder/serviceFeePrice'] ?? false;
  return {
    serviceFee: price,
  };
}
/**
 *
 * @returns {Object{isPaidDelivery: Boolean}}
 */
function getPaidDelivery() {
  const isPaidDelivery = !!store.getters['paidOrder/paymentConfigs'];
  let paidDelivery = null;
  let paidDeliveryType = null;
  let deliveryPrice = 0;
  let isHighPrice = false;

  if (isPaidDelivery) {
    const activePaymentConfig = store.getters['paidOrder/activePaymentConfig'];
    deliveryPrice =
      (activePaymentConfig && activePaymentConfig.delivery_price) || 0;

    paidDeliveryType = store.getters['paidOrder/paidDeliveryType'];
    isHighPrice = store.getters['paidOrder/isHighPrice'];

    paidDelivery = {
      price: deliveryPrice,
      type: paidDeliveryType,
      isHighPrice,
    };
  }
  return {
    paidDeliveryType,
    isPaidDelivery,
    deliveryPrice,
    paidDelivery,
  };
}

export const Analitycs = {
  init() {
    if (import.meta.env.VITE_DEPLOYMENT !== 'PRODUCTION') return;
    SegmentService.init();
    amplitude.getInstance().init(AMPLITUDE_KEY);
    amplitude
      .getInstance(AMPLITUDE_ECOSYSTEM_INSTANCE_NAME)
      .init(import.meta.env.VITE_AMPLITUDE_ECOSYSTEM_KEY);
  },
  getDeviceId() {
    return ClientService.getContext().deviceId;
  },
  getCurrentApp() {
    const isWebView = nativeBridge.isWebView();

    if (isWebView) return 'miniApp';
    return isDesktop() ? 'desktop' : 'adaptive';
  },
  setPushToken(value) {
    state.pushToken = value;
  },

  logEvent(event, payload = {}) {
    const { deviceId, sessionId, v, platform, lang } =
      ClientService.getContext();
    const userId = ClientService.getUserId();
    const currentApp = this.getCurrentApp();
    const release = import.meta.env.VITE_APP_RELEASE;
    const user = store.getters['user/userProfile'];
    const surge = store.getters['delivery/minAmount'];
    const cartWeight = store.getters['cart/cartWeight'];
    const location = store.getters['user/userLocation'];
    const isDistant = store.getters['paidOrder/isDistant'];
    const warehouseId = store.getters['delivery/warehouseId'];
    const userMinCheck = store.getters['delivery/userMinAmount'];
    const cartPrice = store.getters['cart/cartTotalPriceWithDiscount'];

    const paidDelivery = getPaidDelivery();
    const serviceFee = getServiceFee();

    const addressId = store.getters['user/deliveryAddress']?.id ?? 'unknown';
    const userProperties = {
      push_token: state.pushToken,
      deviceId,
      userId,
    };

    if (user) {
      Object.assign(userProperties, {
        name: `${user.first_name ?? ''} ${user.last_name ?? ''}`,
        averageCheck: user.average_check,
        ordersCount: user.orders_count,
        birthDay: user.bday,
        gender: user.gender,
        phone: user.phone,
        email: user.email,
        city: CityService.getCityData().name,
      });
    }

    let currentPage = {
      path: window?.location?.pathname || '/',
      query: window?.location?.search || '',
    };

    const data = {
      ...payload,
      ...location,
      user_agent: window.navigator.userAgent,
      session_id: sessionId || 'localhost',
      cart: store.getters['cart/items'],
      env: import.meta.env.VITE_DEPLOYMENT,
      operating_system: platform,
      app_version: v,

      ...paidDelivery,
      ...serviceFee,
      userMinCheck,
      currentPage,
      warehouseId,
      currentApp,
      cartWeight,
      addressId,
      cartPrice,
      isDistant,
      release,
      userId,
      surge,
      lang,
    };

    Logger.event(
      `Event: ${event}`,
      { payload: { ...payload, currentApp } },
      'Amplitude'
    );

    if (import.meta.env.VITE_DEPLOYMENT !== 'PRODUCTION') return;

    if (userId) {
      amplitude.getInstance().setUserId(userId);
      amplitude.getInstance().setUserProperties(userProperties);
    }
    amplitude.getInstance().logEvent(event, data);
  },
  logSegmentEvent(event, payload = {}) {
    Logger.event(`Event: ${event}`, payload, 'Segment');
    if (import.meta.env.VITE_DEPLOYMENT === 'DEVELOPMENT') return;

    const addressId = store.getters['user/deliveryAddress']?.id ?? 'unknown';
    const { sessionId, v, platform } = ClientService.getContext();
    const userId = ClientService.getUserId();
    const currentApp = this.getCurrentApp();
    const user = store.getters['user/userProfile'];
    const location = store.getters['user/userLocation'];

    const data = {
      operating_system_language: window.navigator.language,
      user_agent: window.navigator.userAgent,
      session_id: sessionId || 'localhost',
      env: import.meta.env.VITE_DEPLOYMENT,
      operating_system: platform,
      addressId,
      /**
       * Версия нативного приложения
       */
      app_version: v,
      user_id: userId,
      currentApp,
      ...location,
      ...payload,
      ...user,
    };

    SegmentService.trackEvent(event, data);
  },
  logAppsflyerEvent(event, payload = {}) {
    const userId = ClientService.getUserId();
    const data = {
      event,
      body: {
        af_customer_user_id: userId,
        ...payload,
      },
    };
    Logger.event(`Event: ${event}`, data, 'Appsflyer');
    if (import.meta.env.VITE_DEPLOYMENT === 'DEVELOPMENT') return;
    nativeBridge.logAppsflyerEvent(JSON.stringify(data));
  },
  /**
   * Задача от команды экосистемы, хотят логировать
   * получение токенов по code через oauth в вебе
   * в своей амплитуде
   */
  logEcosystemAuthByCode() {
    const userId = ClientService.getUserId();
    const instance = amplitude.getInstance(AMPLITUDE_ECOSYSTEM_INSTANCE_NAME);
    instance.setUserId(userId);
    instance.logEvent('auth_token_succeeded');
  },
};

export const EVENTS = firebaseEvents;
export const SEGMENT_EVENTS = segmentEvents;
export const APPSFLYER_EVENTS = appsflyerEvents;

<template>
  <div class="CategoryCard" :style="`grid-column: ${grid}`">
    <a
      class="CategoryCard__block"
      :style="`background-color: #F7F3F1`"
      :href="resolvedRoute.href"
      @click.prevent="verifyNavigation('category')"
    >
      <img
        v-img-proxy="{
          url: imgSrc,
          height: $isDesktop ? 'h:152' : 'h:120',
          as: 'webp',
        }"
        loading="lazy"
        :height="$isDesktop ? '152' : '120'"
        alt="category-icon"
        class="CategoryCard__img"
      />

      <h3 class="CategoryCard__title">
        {{ category.name }}
      </h3>
      <div
        v-if="!category.has_product"
        class="absolute top-0 left-0 w-full h-full rounded-2xl flex items-center justify-center text-center font-bold text-white z-10 bg-sirius-black-300"
        :class="{ 'text-2xl': category.block_size !== '1/3' }"
      >
        {{ $t('ranOut') }}
      </div>
    </a>
  </div>
</template>
<script>
import {
  Analitycs,
  EVENTS,
  SEGMENT_EVENTS,
  APPSFLYER_EVENTS,
} from '@shared/services/analitycs';
import nativeBridge from '@shared/utils/native-bridge';

import { AdultsService } from '@services/adults';
import { configureRouteParams } from '../utils';

export default {
  name: 'CategoryCard',
  inject: ['bottomSheetPopup'],
  props: {
    category: {
      type: Object,
      required: true,
    },
    grid: {
      type: String,
      default: '1/7',
    },
    from: {
      type: String,
      default: 'main',
    },
  },
  data() {
    return {
      analyticsParams: {
        categoryId: this.category.id.toString(),
        categoryName: this.category.name,
        from: 'main',
      },
      segmentAnalytics: {
        screenName: 'Main Page',
        categoryId: this.category.id.toString(),
      },
      appsflyerAnalytics: {
        dom_type: 'main',
        dom_category_id: this.category.id.toString(),
        dom_category_title: this.category.name,
      },
    };
  },
  computed: {
    imgSrc() {
      if (this.$isDesktop && this.category.icon_big)
        return this.category.icon_big;
      return this.category.icon;
    },
  },
  created() {
    this.setRouteParams();
  },
  methods: {
    setRouteParams() {
      this.routeParams = configureRouteParams(
        this.category.type,
        this.category.id.toString()
      );

      this.resolvedRoute = this.$router.resolve(this.routeParams);
    },
    /**
     * Проверяем продукт если тобачка или алкоголь то открываем модалку
     * И перекидываем колбек который должен сработать после подтверждения
     */
    checkIsAgreedAdultProduct() {
      if (
        AdultsService.checkIsAdultCategory(this.category) &&
        !(
          AdultsService.checkIsAgreed() ||
          this.$store.getters['adults/isAgreed']
        )
      ) {
        AdultsService.openModal(this.onAdultProduct);
        return true;
      }
      return false;
    },

    verifyNavigation(card, id = null) {
      if (!this.category.has_product) return;
      if (card === 'subcategory' && id) {
        this.routeParams = Object.assign(this.routeParams, {
          query: { tag: id },
          params: { route: this.$route },
        });
        this.analyticsParams = {
          ...this.analyticsParams,
          tag_id: id,
        };
        this.segmentAnalytics = {
          ...this.segmentAnalytics,
          subcategoryId: id,
        };
      } else {
        delete this.routeParams.query;
        delete this.analyticsParams.tag_id;
        delete this.segmentAnalytics.subcategoryId;
      }

      this.analyticsParams.from = this.from;
      this.appsflyerAnalytics.dom_type = this.from;

      if (this.checkIsAgreedAdultProduct()) return;

      nativeBridge.hapticSelection();
      Analitycs.logEvent(EVENTS.CATEGORY_SELECTED, this.analyticsParams);
      Analitycs.logSegmentEvent(
        SEGMENT_EVENTS.CLICK_CATEGORY,
        this.segmentAnalytics
      );
      Analitycs.logAppsflyerEvent(
        APPSFLYER_EVENTS.DOM_NEW_MAIN_CATEGORY_MAIN,
        this.appsflyerAnalytics
      );
      this.$router.push(this.routeParams);
    },
    onAdultProduct() {
      Analitycs.logEvent(EVENTS.CATEGORY_SELECTED, this.analyticsParams);
      Analitycs.logSegmentEvent(
        SEGMENT_EVENTS.CLICK_CATEGORY,
        this.segmentAnalytics
      );
      Analitycs.logAppsflyerEvent(
        APPSFLYER_EVENTS.DOM_NEW_MAIN_CATEGORY_MAIN,
        this.appsflyerAnalytics
      );
      this.$router.push(this.routeParams);
    },
  },
  i18n: {
    messages: {
      ru: {
        areYou21OrMore: 'Вам 21 год или больше?',
        alcoholLaw: `
          В соответствии с законом Республики Казахстан о реализации алкогольной
          продукции и табака продажа данной продукции не предназначена лицам, не
          достигшим возраста 21 года.
          <br /><br />
          При нажатии кнопки “Да, есть” вы подтверждаете, что вам 21 год и более
          берёте на себя всю ответственность.
          <br /><br />
          Рекомендуем не злоупотреблять данной продукцией.
        `,
        no: 'Нет',
        yesIam: 'Да, есть',
        ranOut: 'Разобрали',
      },
      kk: {
        areYou21OrMore: 'Сіз 21 жаста немесе одан үлкенсіз бе?',
        alcoholLaw: `
          Қазақстан Республикасының алкоголь өнімімен темекі өнімдерін сату туралы
          заңына сәйкес бұл өнімді сату 21 жасқа  толмаған адамдарға арналмаған!
          "ИӘ, бар" батырмасын басқан кезде Сіз 21 жастан асқаныңызды және барлық
          жауапкершілікті өз мойыныңызға алатыныңызды растайсыз! Бұл өнімді теріс
          пайдаланбауға кеңес береміз!
        `,
        no: 'Жоқ',
        yesIam: 'Иә, бар',
        ranOut: 'Қалған жоқ',
      },
    },
  },
};
</script>

<style scoped>
.CategoryCard {
  height: 120px;
}
.CategoryCard__block {
  @apply p-2 relative block h-full 
    overflow-hidden rounded-2xl
    md:p-4 md:rounded-3xl;
}
.CategoryCard__img {
  @apply h-full absolute 
    left-0 bottom-0 z-block 
    object-left-bottom object-cover;
}
.CategoryCard__title {
  @apply absolute font-medium text-xs md:text-lg z-10 pr-2;
}
@media (min-width: 768px) {
  .CategoryCard {
    height: 152px;
  }

  .CategoryCard__title {
    line-height: 20px !important;
  }
}
</style>

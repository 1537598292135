import { client } from '../../clients/client';
import { createCancelableRequestConfig } from '../../clients/utils';

export default function () {
  const { cancelToken, onFinish } = createCancelableRequestConfig();
  return client
    .get(`api/v3/user`, { cancelToken })
    .then(res => res.data)
    .finally(onFinish);
}

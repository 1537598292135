<template>
  <RenderlessProduct
    v-if="product"
    v-slot="{
      isPending,
      addToCart,
      quantity,
      removeFromCart,
      removeProductFromCart,
    }"
    :product="product"
    id-key="product_id"
  >
    <div class="">
      <DeliveryOption
        v-model="isappliances"
        class="py-3"
        :option="{
          icon: 'cart-appliances',
          title: $t('addAppliences'),
          descr: `${formatPrice(product.price)} ₸/шт`,
        }"
        @change="setAppliances(addToCart, removeProductFromCart)"
      >
        <template v-if="isappliances">
          <div class="flex justify-between space-x-2 mt-3">
            <div class="">
              <p class="text-sirius-gray-700 text-sm">
                {{ $t('disposableСutlery') }}
              </p>
              <p class="text-sm text-sirius-gray-100 mt-1">
                {{ formatPrice(product.price) }} ₸/шт
                <span
                  class="font-medium ml-1 text-base leading-5 text-sirius-black-100"
                >
                  {{ formatPrice(product.price * product.quantity) }}
                  ₸
                </span>
              </p>
            </div>
            <UiButton
              v-if="isAuthorized && isPending"
              size="sm"
              variant="gray"
              style="width: 100px; max-width: 100px; height: 40px"
            >
              <UiLoader size="sm" variant="gray" />
            </UiButton>
            <ProductCounter
              v-else
              style="width: 100px; max-width: 100px"
              :model-value="quantity"
              :max="product.max_quantity || 0"
              :min="1"
              variant="secondary"
              size="sm"
              @onIncrement="onIncrement(addToCart)"
              @onDecrement="onDecrement(removeFromCart)"
              @onMaxLimit="onMaxLimit"
            />
          </div>
        </template>
      </DeliveryOption>
    </div>
  </RenderlessProduct>
</template>

<script>
import { mapGetters } from 'vuex';
import nativeBridge from '@shared/utils/native-bridge';
import { formatPrice } from '@shared/utils/formatters/price';
import { RenderlessProduct } from '@shared/components/product/RenderlessProduct';
import DeliveryOption from '@pure-ui/components/DeliveryOption/DeliveryOption.vue';
import ProductCounter from '@pure-ui/components/Product/ProductCounter/ProductCounter.vue';
import UiLoader from '@pure-ui/components/UiLoader/UiLoader.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

export default {
  name: 'AppliancesFeat',
  components: {
    RenderlessProduct,
    ProductCounter,
    DeliveryOption,
    UiLoader,
    UiButton,
  },
  data() {
    return {
      isappliances: false,
    };
  },
  computed: {
    ...mapGetters('cart', ['getFromCart', 'appliances']),
    ...mapGetters(['isAuthorized']),
    product() {
      if (!this.appliances) return null;

      const appliance = this.getFromCart(`${this.appliances.id}_0`);
      if (appliance) return appliance;

      return {
        ...this.appliances,
        product_id: this.appliances.id,
        quantity: 0,
      };
    },
    option() {
      return {
        icon: 'cart-appliances',
        title: this.$t('addAppliences'),
        descr: `${this.formatPrice(this.product.price)} ₸/шт`,
      };
    },
  },
  mounted() {
    if (this.product) {
      this.isappliances = this.product.quantity !== 0;
    }
  },
  methods: {
    formatPrice,
    setAppliances(addToCart, removeProductFromCart) {
      nativeBridge.hapticSelection();
      if (this.isappliances) {
        addToCart('appliancesFeat');
        this.$emit('isToggled', true);
        return;
      }
      this.$emit('isToggled', false);
      removeProductFromCart();
    },
    onIncrement(addToCart) {
      addToCart('appliancesFeat');
    },
    onDecrement(removeFromCart, quantity) {
      if (quantity === 1) {
        this.isappliances = false;
        this.$emit('isToggled', false);
      }
      removeFromCart();
    },
    onMaxLimit() {
      nativeBridge.hapticNotification('error');
    },
  },
  i18n: {
    messages: {
      kk: {
        addAppliences: 'Ас құралдары қажет пе?',
        disposableСutlery: 'Бір реттік ас құралдары',
      },
      ru: {
        addAppliences: 'Добавить приборы?',
        disposableСutlery: 'Одноразовые столовые приборы',
      },
    },
  },
};
</script>

<style></style>

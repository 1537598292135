import * as Sentry from '@sentry/vue';
import OutgoingEventsHandler from './helpers/OutgoingEventsHandler';
import { OUTGOING_EVENTS } from './constants';

class RahmetApp {
  constructor() {
    this.eventHandler = new OutgoingEventsHandler();
  }

  requestGeoPermissionStatus() {
    return new Promise(resolve => {
      this.callNativeMethod('requestGeoPermissionStatus');
      window.RahmetWebApp.onGeoPermissionStatusDefined = status => {
        resolve(status);
      };
    });
  }

  getCachedGeoPosition(body) {
    return this.callNativeMethod('getCachedGeoPosition', body);
  }

  authVersion() {
    if (
      window.RahmetApp &&
      window.RahmetApp.authorizeV2 &&
      window.RahmetWebApp.onAuthSuccessV2 &&
      typeof window.RahmetApp.authorizeV2 === 'function' &&
      typeof window.RahmetWebApp.onAuthSuccessV2 === 'function'
    ) {
      return 'v2';
    }
    return 'v1';
  }

  authorizeV2(body) {
    return new Promise(resolve => {
      this.callNativeMethod('authorizeV2', JSON.stringify(body));
      window.RahmetWebApp.onAuthSuccessV2 = (code, state) =>
        resolve({ code, state });
      window.RahmetWebApp.onAuthDismissed = () => resolve({ code: undefined });
      window.RahmetWebApp.onAuthError = () => resolve({ code: undefined });
    });
  }

  authorize() {
    return new Promise(resolve => {
      this.callNativeMethod('authorize');
      window.RahmetWebApp.onAuthSuccess = trackId => resolve({ code: trackId });
      window.RahmetWebApp.onAuthDismissed = () => resolve({ code: undefined });
    });
  }

  requestNotificationStatus() {
    return new Promise(resolve => {
      if (this.hasMethod('getNotificationStatus')) {
        this.callNativeMethod('getNotificationStatus');
        window.RahmetWebApp.didGetIsNotificationStatus = status =>
          resolve(status);
      } else {
        resolve('notDetermined');
      }
    });
  }

  openAppSettingsAwaitNotificationStatus() {
    return new Promise(resolve => {
      if (this.hasMethod('requestNotificationStatus')) {
        this.callNativeMethod('requestNotificationStatus');
        window.RahmetWebApp.didGetIsNotificationStatus = status =>
          resolve(status);
      } else {
        resolve('notDetermined');
      }
    });
  }

  pay(deeplink) {
    return new Promise(resolve => {
      this.callNativeMethod('pay', deeplink);
      window.RahmetWebApp.onPaySuccess = () => resolve('success');
      window.RahmetWebApp.onNativePayViewClosed = () => resolve('closed');
    });
  }

  checkAuth() {
    return this.callNativeMethod('checkAuth');
  }

  logAppsflyerEvent(event) {
    this.callNativeMethod('logAppsflyerEvent', event);
  }

  shareReferralCode(text) {
    this.callNativeMethod('shareReferralCode', text);
  }

  shareFile(base64, filename = 'name', mime = 'image/png') {
    this.callNativeMethod(
      'loadBase64File',
      JSON.stringify({ base64, filename, mime })
    );
  }

  shareToInstagram(base64, filename = 'name', mime = 'image/png') {
    this.callNativeMethod(
      'shareImage',
      JSON.stringify({ base64, filename, mime })
    );
  }

  shareText(text) {
    this.callNativeMethod('shareText', text);
  }

  backToNativeApp() {
    this.callNativeMethod('backToRahmetApp');
  }

  hapticSelection() {
    this.callNativeMethod('hapticSelection');
  }

  hapticNotification(type) {
    this.callNativeMethod('hapticNotification', type);
  }

  hapticImpact(type) {
    this.callNativeMethod('hapticImpact', type);
  }

  openLink(body) {
    if (this.hasMethod('openLink')) {
      window.RahmetApp.openLink(JSON.stringify(body));
      return true;
    }
    return false;
  }

  openAppSettings() {
    if (this.hasMethod('openAppSettings')) {
      window.RahmetApp.openAppSettings();
      return true;
    }
    return false;
  }

  applicationBecomeActive(method) {
    window.RahmetWebApp.didBecomeActive = method;
  }

  registerOutgoingEvent(methodName, callback) {
    this.eventHandler.register(methodName, callback);
  }

  cancelOutgoingEvent(methodName) {
    this.eventHandler.cancel(methodName);
  }

  reloadPage() {
    this.callNativeMethod('reloadPage');
  }

  hideNavigationBar() {}

  setupWebViewApi() {
    window.RahmetWebApp = {
      onAuthSuccess: () => {},
      onAuthSuccessV2: () => {},
      onAuthDismissed: () => {},
      onPaySuccess: () => {},
      onGeoPermissionStatusDefined: () => {},
      onNativePayViewClosed: () => {},
      didBecomeActive: () => {},
      loadReferralSuccess: () => {},
      onKeyboardClosed: () => {},
      didFailReferralCode: () => {},
      didGetRemoteConfigProperty: () => {},
      onBackPressed: () => {
        const responseToNative = !!this.eventHandler.eventStacks.get(
          OUTGOING_EVENTS.ON_BACK_PRESSED
        );
        this.eventHandler.handleEvent(OUTGOING_EVENTS.ON_BACK_PRESSED);
        return responseToNative;
      },
      didGetIsNotificationStatus: () => {},
    };
  }

  hasMethod(methodName) {
    return typeof window.RahmetApp?.[methodName] === 'function';
  }

  callNativeMethod(method, ...args) {
    try {
      if (!this.hasMethod(method)) {
        Sentry.captureException(`[Choco super app] Missing method: ${method}`, {
          extra: { message: 'Native app methods' },
        });
        return;
      }
      return window.RahmetApp[method](...args);
    } catch (error) {
      Sentry.captureException(`[Choco super app] Error calling: ${method}`, {
        extra: { message: 'Native app methods' },
      });
    }
  }
}

export default RahmetApp;

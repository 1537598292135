class OutgoingEventsHandler {
  constructor() {
    this.eventStacks = new Map(); // Stores eventName -> stack of callbacks
  }

  /**
   * Registers an event handler in a stack.
   * @param {string} eventName - The event name (e.g., "on_back_pressed").
   * @param {Function} callback - The callback to execute when the event occurs.
   */
  register(eventName, callback) {
    if (!this.eventStacks.has(eventName)) {
      this.eventStacks.set(eventName, []);
    }
    this.eventStacks.get(eventName).push(callback);
  }

  /**
   * Removes the last registered handler for the given event.
   * @param {string} eventName - The event name to remove the latest callback for.
   */
  cancel(eventName) {
    if (this.eventStacks.has(eventName)) {
      const stack = this.eventStacks.get(eventName);
      stack.pop();
      if (stack.length === 0) {
        this.eventStacks.delete(eventName);
      }
    }
  }

  /**
   * Triggers the latest registered callback for a specific event.
   * @param {string} eventName - The event to trigger.
   * @param {*} payload - Data to pass to the callback.
   */
  handleEvent(eventName, payload) {
    if (this.eventStacks.has(eventName)) {
      const stack = this.eventStacks.get(eventName);
      if (stack.length > 0) {
        const latestCallback = stack[stack.length - 1];
        latestCallback(payload);
      }
    }
  }
}

export default OutgoingEventsHandler;

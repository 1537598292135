import { client } from '../../clients/client';
import { createCancelableRequestConfig } from '../../clients/utils';
/**
 * Получить список опросов (только с авторизацией)
 */
export function rFetchSurveys() {
  const { cancelToken, onFinish } = createCancelableRequestConfig();
  return client
    .get(`/ryadom-review/api/v1/review/categories`, { cancelToken })
    .then(res => res.data)
    .finally(onFinish);
}

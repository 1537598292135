import { client } from '../../clients/client';
import { isAuthorizedUrl } from '../../utils/is-authorized-url';
import { createCancelableRequestConfig } from '../../clients/utils';
export default function (isAuthorized = false) {
  const { cancelToken, onFinish } = createCancelableRequestConfig();
  return client
    .get(`/lavka/v1${isAuthorizedUrl(isAuthorized)}/user_addresses`, {
      useDeviceId: true,
      cancelToken,
    })
    .then(response => response.data)
    .finally(onFinish);
}

<template>
  <div class="h-full bg-white font-sirius leading-tight">
    <template v-if="loading || appLoader">
      <LoaderAnimation v-if="$isWebView" />
      <DesktopAdaptiveLoader v-else />
    </template>

    <!-- TODO:
     можно перевести в v-show
     так компоненты и все картинки уже будут загружаться,

     Но есть одно но точно:(может быть и больше)
     1) ProductDetails не будет работать
     нужно будет продумать когда точно loading закончится
     тогда только имитить событие открытие модалки деталей продукта
     она сейчас имитится в product-full/view.vue
     это можно решить через ивент eb_init_page_load_done
    -->
    <router-view v-else />

    <template v-if="!loading && !appLoader">
      <!-- Active Orders -->
      <MiniOrderStatus v-if="!$isDesktop" />
      <CustomerReviews />
      <!-- Active Orders -->

      <UiFullScreenLoader v-if="isPromocodeLoading || isAddressLoading" />

      <template v-if="hasReminders">
        <NoDeliveryReminderContainer />
        <OpenTimeContainer />
      </template>
    </template>
    <AppModalsContainer />

    <template v-if="hasConsoleBtns">
      <UiButton
        size="sm"
        class="fixed z-overlay -right-8 px-3"
        style="transform: rotateZ(-90deg); bottom: calc(200px + 76px)"
        @click="onLoggerTap"
      >
        Console
      </UiButton>
    </template>
    <Logger />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import nativeBridge from '@shared/utils/native-bridge';
import { UserService } from '@services/user';
import { SurgeService } from '@services/surge';
import { OrderService } from '@services/orders';
import { DevConfigs } from '@/utils/dev-config';
import { AuthService } from '@shared/services/auth';
import { SocketService } from '@shared/services/ws';
// import { CashbackService } from '@services/cashback';
import { ClientService } from '@shared/services/client';
import { HighPriceService } from '@services/high-price';
import { PromocodesService } from '@services/promocodes';
import { ERR_NO_DELIVERY } from '@shared/config/delivery';
// import { NewProductsService } from '@services/new-products';
import { CategoryNpsService } from '@services/category-nps';
import { NotificationService } from '@services/notifications';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { QuestionnaireService } from '@services/questionnaire';
import { OutdatedBuildWatcherService } from '@services/outdated-build-watcher';
import { WarehouseInactiveService } from '@services/warehouse-inactive';
import { CatalogService } from '@services/catalog';
import {
  cancelAllPendingRequests,
  pendingTokens,
} from '@shared/services/api/clients/utils/axios-request-manager';

export default {
  name: 'App',
  components: {
    UiButton: () => import('@pure-ui/components/UiButton/UiButton.vue'),
    UiFullScreenLoader: () => import('@ui/UiFullScreenLoader.vue'),
    AppModalsContainer: () =>
      import('@components/app-modals/AppModalsContainer.vue'),
    CustomerReviews: () =>
      import('@shared/components/feedback/CustomerReviews.vue'),
    MiniOrderStatus: () =>
      import('@/views/order/components/MiniOrderStatus.vue'),
    DesktopAdaptiveLoader: () =>
      import('@components/init-loaders/DesktopAdaptiveLoader.vue'),
    LoaderAnimation: () =>
      import('@components/init-loaders/LoaderAnimation.vue'),
    Logger: () => import('@shared/components/logger/Logger.vue'),
    OpenTimeContainer: () =>
      import('@components/open-time/OpenTimeContainer.vue'),
    NoDeliveryReminderContainer: () =>
      import(
        '@components/address/NoDeliveryReminder/NoDeliveryReminderContainer.vue'
      ),
  },
  inject: ['toast', 'bottomSheet', 'popup', 'bottomSheetPopup'],

  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'isAddressLoading', 'isDeveloper']),
    ...mapGetters('promocodes', ['isPromocodeLoading']),
    ...mapGetters('logger', ['isButtonShown']),
    ...mapGetters(['appLoader']),
    hasConsoleBtns() {
      return (
        DevConfigs.isStage ||
        DevConfigs.isDev ||
        this.isDeveloper ||
        this.isButtonShown
      );
    },
    hasReminders() {
      let routes = [
        'main',
        'catalog',
        'category',
        'favorites',
        'cart',
        'category-search',
        'search',
        'shopping-history',
      ];
      return routes.includes(this.$route.name);
    },
  },
  watch: {
    loading(v) {
      this.appLoadingHandler(v);
    },
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
    window.removeEventListener('resize', this.onResize);
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    );
  },
  mounted() {
    this.setIsWebView(nativeBridge.isWebView());
    PromocodesService.init();
    OutdatedBuildWatcherService.init();
    this.init();
    OrderService.init();
    SocketService.init();
    SurgeService.init();
    HighPriceService.init();
    QuestionnaireService.init();
    WarehouseInactiveService.init();
    // NewProductsService.init();
    // CashbackService.init();
    CatalogService.init();

    window.addEventListener('beforeunload', this.beforeWindowUnload);
    this.onResize();
    window.addEventListener('resize', this.onResize);

    // Add visibility change handler for both web and webview environments
    document.addEventListener('visibilitychange', this.handleVisibilityChange);

    NotificationService.init();
  },
  methods: {
    ...mapMutations({
      setIsWebView: 'SET_IS_WEB_VIEW',
    }),
    ...mapMutations('logger', {
      openLogger: 'OPEN_LOGGER',
    }),
    ...mapActions('catalog', {
      fetchMainCategories: 'FETCH_MAIN_CATEGORIES',
    }),
    init() {
      // если перешли на url, доступный только с авторизацией,
      // авторизация уже запущена в src/router/auth-guard.js,
      // нет смысла запускать её заново
      if (this.$store.getters['appLoader']) {
        this.loading = false;
        return;
      }

      const { state } = this.$route.query;
      let { track_id: trackId, code } = this.$route.query;

      if (trackId && trackId.length > 36) {
        trackId = undefined;
        code = this.$route.query.track_id;
      }

      this.$store.dispatch('cart/INIT_LOCAL_CART');

      const authPayload = {
        trackId,
        state,
        code,
        isInit: true,
      };
      this.$log('AppVue Auth');

      return AuthService.authorize(authPayload)
        .then(res => {
          if (!this.$isWebView && code) {
            Analitycs.logEcosystemAuthByCode();
            this.$log('Analitycs.logEcosystemAuthByCode');
          }
          this.$log('AppVue Auth res', res);
          return this.authorizedFlow(state).then(() => {
            this.$eventBus.emit('eb_init_page_load_done');
          });
        })
        .catch(err => {
          this.$log('AppVue Auth err', err);
          console.error('AppVue Auth err', err);
          UserService.loadUnauthorizedProfile();
          return this.initDeviceIdFlow().then(() => {
            this.$eventBus.emit('eb_init_page_load_done');
          });
        });
    },
    initDeviceIdFlow() {
      const { deviceId } = ClientService.getContext();
      this.$log('initDeviceIdFlow', { deviceId });

      if (deviceId !== undefined) {
        return this.$store
          .dispatch('INIT_DEVICE_ID_FLOW')
          .then(() => {
            Analitycs.logEvent(EVENTS.INIT_DEVICE_ID_FLOW, {
              addresses: this.$store.state.user.addresses,
            });
            return Promise.resolve();
          })
          .catch(err => {
            console.error(err);
            Analitycs.logEvent(EVENTS.INIT_DEVICE_ID_FLOW, {
              error: {
                message: err.message,
                stack: err.stack,
              },
            });
            if (
              err.message !== 'Не удалось определить адрес доставки' &&
              err.message !== 'Не удалось определить зону доставки' &&
              err.message !== ERR_NO_DELIVERY
            ) {
              this.toast.show(err.message, 'error');
            }
            if (this.$route.name === 'auth') {
              this.$router.push({ name: 'main' });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
      this.loading = false;
      return Promise.resolve();
    },
    authorizedFlow(state = '') {
      this.$log('authorizedFlow', { state });

      return this.$store
        .dispatch('INIT_AUTHORIZED_FLOW')
        .then(() => {
          this.loading = false;

          if (this.$route.name !== 'main') {
            this.fetchMainCategories();
          }

          const route = state ? AuthService.parseOauthStateRoute(state) : null;
          if (route) this.$router.push(route);
        })
        .then(() => {
          return Promise.all([
            this.$store.dispatch('favorites/FETCH_FAVORITES_IDS'),
            CategoryNpsService.loadSurveys(),
          ]);
        })
        .then(() => {
          Analitycs.logEvent(EVENTS.INIT_AUTHORIZED_FLOW, {
            addresses: this.$store.state.user.addresses,
          });
          return Promise.resolve();
        })
        .catch(err => {
          Analitycs.logEvent(EVENTS.INIT_AUTHORIZED_FLOW, {
            error: {
              message: err.message,
              stack: err.stack,
            },
          });
          this.$log('authorizedFlow err', err);
          this.initDeviceIdFlow();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    retryAuth() {
      // по какой-то причине на ios, если перейти по пушу в то время,
      // как приложение было свёрнуто, то происходит странное:
      // страница загружается с самого начала, но нативка дополнительно
      // вызывает "didBecomeActive", что кажется нелогичным
      if (this.$store.getters['appLoader']) {
        this.loading = false;
        return;
      }
      return AuthService.authorize({ isRefresh: true })
        .then(() => this.authorizedFlow('', false))
        .catch(() => (this.loading = false));
    },
    beforeWindowUnload() {
      Analitycs.logEvent(EVENTS.CLOSED_APP);
    },
    onLoggerTap() {
      this.openLogger();
    },
    onResize() {
      this.$store.commit('SET_IS_DESKTOP', window.innerWidth > 768);
    },
    appLoadingHandler(value) {
      this.$store.commit('SET_APP_INIT_LOADER', value);
    },
    handleVisibilityChange() {
      const visibilityState = document.visibilityState;
      const pendingCount = pendingTokens.size;
      this.$log(
        'handleVisibilityChange',
        {
          visibilityState,
          pendingCount,
          pendingTokens: Array.from(pendingTokens).map(
            source => source.token.reason
          ),
        },
        'App'
      );

      if (document.visibilityState === 'hidden') {
        this.$log(
          'Cancelling all pending requests',
          {
            count: pendingCount,
          },
          'App'
        );
        cancelAllPendingRequests();
      }
    },
  },
  eventBusEvents: {
    eb_on_reconnect_to_app() {
      this.$log('retryAuth', null, 'EventBus');
      this.retryAuth();
    },
    eb_open_address_create_container(data) {
      let id = data && data.id ? data.id.toString() : 'create';
      let from = data.from;

      Analitycs.logEvent(EVENTS.GEO_ADRESS_SCREEN, {
        from,
        type: id === 'create' ? 'creating' : 'editing',
      });

      if (this.$isDesktop) {
        this.$eventBus.emit('eb_open_address_desk_container', { id, from });
      } else {
        this.$router.push({ name: 'address', params: { id, from } });
      }
    },
    // TODO: изменить на eb_on_warehouse_change после выктки фикса
    eb_on_address_update() {
      this.fetchMainCategories();
      this.$store.dispatch('delivery/FETCH_EXPECTED_DELIVERY_TIME');
    },
    // TODO: изменить на eb_on_warehouse_change после выктки фикса
    eb_on_address_change() {
      this.fetchMainCategories();
      this.$store.dispatch('delivery/FETCH_EXPECTED_DELIVERY_TIME');
    },
    eb_init_page_load_done() {},
  },
};
</script>

<style lang="postcss">
.desk {
  @apply hidden md:block;
}
.mob {
  @apply block md:hidden;
}
</style>

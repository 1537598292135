<template>
  <div
    class="product-list-mobile overflow-x-auto overflow-y-hidden hidden-scrollbar"
    @scroll="onScrollDebounced"
  >
    <div ref="slidesContainer" class="inline-flex pl-2 pr-4 pt-3">
      <SamplingPromoCard
        v-for="(sample, index) in products"
        :key="`${index}_${sample.id}`"
        :sample="sample"
        class="ml-2"
        style="width: 109px; flex-shrink: 0"
      />
    </div>
  </div>
</template>

<script>
import SamplingPromoCard from '@components/samplings/SamplingPromoCard.vue';
import debounce from 'debounce';

export default {
  name: 'SamplingPromoListSlider',
  components: {
    SamplingPromoCard,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    viewed: 0,
  }),
  watch: {
    viewed(v) {
      this.$emit('onCardsViewed', v);
    },
  },
  mounted() {
    this.countViewed();
    this.$emit('onCardsViewed', this.viewed);
  },
  methods: {
    countViewed() {
      const { children } = this.$refs.slidesContainer;
      const count = children.length;
      for (let i = count - 1; i > 0; i -= 1) {
        if (i <= this.viewed) {
          break;
        }
        const box = children[i].getBoundingClientRect();
        if (box.x > 0) {
          this.viewed = i;
          break;
        }
      }
    },
    // eslint-disable-next-line prefer-arrow-callback
    onScrollDebounced: debounce(function onScrollDebounced() {
      this.countViewed();
    }, 100),
  },
};
</script>

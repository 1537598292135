<template>
  <MainLayout
    class="text-sirius-black-100"
    with-checkout-reminder
    with-bottom-toolbar
    :sticky-header="!$isWebView && hasReferralPlank"
  >
    <template v-if="$isWebView" #header>
      <MainHeader
        @onSupport="openSupport"
        @onClose="onClose"
        @onSearch="goToSearch"
        @onFreedomCashbackClick="openFreedomCashbackModal"
      />
    </template>
    <!-- <template v-else-if="!$isDesktop" #header>
      <WebMobileHeaderMain />
    </template> -->
    <template v-if="!$isWebView" #header-wrapper>
      <ReferralPlankDesk
        v-if="hasReferralPlank"
        class="main-referral-plank-desk"
        @click.native="goToReferral"
      />
      <!-- Здесь есть баг когда есть реферальная плашка и шапка стики
      когда юзер возращяется на главную страницу
      то у него скрол ставится на прошле место где был
      и хедер становится стики и в этот момент иногда происходит баг со скролом
     -->
      <header
        class="main-header-desk"
        :class="[hasReferralPlank ? '--sticky' : '--fixed']"
      >
        <DeskHeader v-if="$isDesktop" />
        <WebMobileHeaderMain v-else />
      </header>
    </template>

    <div class="flex flex-col container mt-4 md:mt-16" style="max-width: 988px">
      <div class="">
        <NoAddressReminderContainer v-if="!$isDesktop" />
        <AddressConfirmationPopoverWrapper v-if="!$isDesktop" />
        <CommercialBannersWrapper ref="commercialBanners" />
        <MainSpecialCategories
          v-if="$isWebView"
          class="mt-9"
        ></MainSpecialCategories>
        <ProductsReviewWrapper
          v-if="hasReviewProducts && !$isDesktop"
          class="mt-6 px-4"
          screen="main"
        />
      </div>

      <UiRippleLoader v-if="!hasMainCategories" class="mx-auto mt-16" />

      <div v-else class="mt-6 md:mt-16">
        <SaleCategory class="mb-7 md:mb-16" />
        <SpecialCategories class="mb-7 md:mb-16" />
        <SamplingCategory class="mb-7 md:mb-16" />
        <CustomProductListWrapper class="mb-4 md:mb-7" />

        <!-- Новая главная для миниаппки -->
        <template v-if="$store.getters['showNewMainPageAndCatalog']">
          <div v-if="hasReferralPlank" class="px-4 mt-6">
            <ReferralPlank @click.native="goToReferral" />
          </div>
          <TopCategories class="mt-6" />
          <SpecialCategorySliderWrapper></SpecialCategorySliderWrapper>
          <PopularProducts class="mt-6" />
        </template>
        <!-- Контрольная группа, старая главная для всех -->
        <template v-else>
          <SimpleCategories :categories="simpleCategories">
            <template #after-group="{ group }">
              <div v-if="group.id === 18 && $isWebView" class="px-4 mt-6">
                <ReferralPlank @click.native="goToReferral" />
              </div>
            </template>
          </SimpleCategories>
        </template>
      </div>
    </div>

    <ReferralWelcomeModalWrapper />
    <PromoModalWrapper />
    <ConfirmClose @confirm="closeMiniApp" @close="popup.hide()" />
    <UpButton />
    <WishButton :show-button="false" />
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  Analitycs,
  EVENTS,
  SEGMENT_EVENTS,
  APPSFLYER_EVENTS,
} from '@shared/services/analitycs';
import { eventBus, BUS_EVENTS } from '@shared/utils/event-bus';
import { Logger as LOG } from '@shared/services/logger';
import nativeBridge from '@shared/utils/native-bridge';

import ReferralPlankDesk from '@pure-ui/components/Referral/ReferralPlank/ReferralPlankDesk.vue';

import SimpleCategories from '@/components/SimpleCategories/SimpleCategories.vue';
import TopCategories from '@/components/TopCategories/TopCategories.vue';
import DeskHeader from '@components/header/desk-header/DeskHeader.vue';
import MainHeader from '@/components/header/MainHeader.vue';
import MainLayout from '@layout/MainLayout.vue';
import CommercialBannersWrapper from './components/CommercialBannersWrapper.vue';
import WebMobileHeaderMain from '@/components/header/WebMobileHeaderMain.vue';
import SpecialCategories from './components/SpecialCategories.vue';
import SaleCategory from './components/SaleCategory.vue';
import SamplingCategory from './components/SamplingCategory.vue';
import PopularProducts from './components/PopularProducts.vue';
import MainSpecialCategories from '@pure-ui/components/MainSpecialCategories/MainSpecialCategories.vue';
import SpecialCategorySliderWrapper from '@pure-ui/components/SpecialCategorySlider/SpecialCategorySliderWrapper.vue';
import CustomProductListWrapper from '@components/custom-product-list/CustomProductListWrapper.vue';

export default {
  name: 'MainView',
  components: {
    CustomProductListWrapper,
    SpecialCategorySliderWrapper,
    MainSpecialCategories,
    CommercialBannersWrapper,
    WebMobileHeaderMain,
    SpecialCategories,
    ReferralPlankDesk,
    SimpleCategories,
    PopularProducts,
    TopCategories,
    SaleCategory,
    SamplingCategory,
    MainLayout,
    MainHeader,
    DeskHeader,

    WishButton: () => import('@shared/components/catalog/WishButton.vue'),
    UpButton: () => import('@shared/components/product/UpButton.vue'),
    UiRippleLoader: () => import('@ui/UiRippleLoader.vue'),
    ReferralWelcomeModalWrapper: () =>
      import('@components/referral/ReferralWelcomeModalWrapper.vue'),
    ProductsReviewWrapper: () =>
      import('@components/product-review/ProductsReviewWrapper.vue'),
    ConfirmClose: () =>
      import('@shared/components/confirm-close/ConfirmClose.vue'),
    AddressConfirmationPopoverWrapper: () =>
      import('@/views/main/components/AddressConfirmationPopoverWrapper.vue'),
    PromoModalWrapper: () =>
      import('@components/promo-modal/PromoModalWrapper.vue'),
    NoAddressReminderContainer: () =>
      import('@components/address/NoAddressReminderContainer.vue'),
    ReferralPlank: () =>
      import('@pure-ui/components/Referral/ReferralPlank/ReferralPlank.vue'),
  },
  inject: ['toast', 'popup', 'bottomSheet'],

  beforeRouteLeave(to, from, next) {
    if (from.name === 'main' && to.name === 'category') {
      to.query.top =
        document.documentElement.scrollTop || document.body.scrollTop;
    }
    next();
  },
  data() {
    return {
      hasMiniBanners: 0,
      hasBanners: 0,
    };
  },
  computed: {
    ...mapGetters('user', [
      'shouldRequireAddress',
      'userOrdersCount',
      'deliveryAddress',
    ]),
    ...mapGetters('cart', ['checkoutReminder', 'minAmountReminder']),
    ...mapGetters('delivery', ['warehouseId', 'warehouse']),
    ...mapGetters('productReview', ['hasReviewProducts']),
    ...mapGetters('catalog', ['hasMainCategories']),
    ...mapGetters('paidOrder', ['paymentConfigs']),
    ...mapGetters('abtest', ['getGroupByCode']),

    ...mapState('catalog', ['simpleCategories']),

    hasReferralPlank() {
      return this.$store.getters['user/hasReferralProgram'];
    },
  },
  watch: {
    warehouseId() {
      this.fetchCategories();
    },
  },
  created() {
    LOG.event('Main View', { hasMainCategories: this.hasMainCategories });
    if (this.hasMainCategories) {
      this.configureFromState();
    } else {
      this.fetchCategories();
    }
  },
  beforeDestroy() {
    eventBus.$off(BUS_EVENTS.LOCALE_CHANGE);
  },
  mounted() {
    setTimeout(() => {
      return this.shouldRequireAddress
        ? Analitycs.logEvent(EVENTS.HOME_1)
        : Analitycs.logEvent(EVENTS.HOME_2, {
            address_id: this.deliveryAddress?.id || 'not defined',
          });
    }, 1500);

    Analitycs.logEvent(EVENTS.VIEWED_MAIN_PAGE);
    Analitycs.logSegmentEvent(SEGMENT_EVENTS.VIEW_MAIN_PAGE);
    Analitycs.logAppsflyerEvent(APPSFLYER_EVENTS.DOM_VIEWED_MAIN_PAGE, {
      dom_orders_count: this.userOrdersCount,
      dom_home: this.shouldRequireAddress ? 'home_1' : 'home_2',
      dom_warehouse_id: this.warehouseId,
    });

    eventBus.$on(BUS_EVENTS.LOCALE_CHANGE, this.onLocaleChange);
  },
  methods: {
    ...mapActions('catalog', {
      fetchMainCategories: 'FETCH_MAIN_CATEGORIES',
    }),
    scrollBackToCategory() {
      if (!this.$route.query.top) return;
      const { top } = this.$route.query;
      this.$nextTick(() => {
        window.scrollTo({ top });
      });
    },

    fetchCategories() {
      this.fetchMainCategories()
        .catch(err => {
          console.error(err);
          this.toast.show(err.message);
        })
        .finally(() => {
          this.scrollBackToCategory();
        });
    },
    configureFromState() {
      this.scrollBackToCategory();
    },
    calcGrid(parentCategory, category) {
      const sizes = {
        1: 6,
        '1/2': 3,
        '1/3': 2,
        '2/3': 4,
      };
      let result = '1/7';
      if (parentCategory.block_size) {
        const add = parentCategory.block_size + sizes[category.block_size];
        if (add > 6) {
          parentCategory.block_size = sizes[category.block_size];
          result = `1/${sizes[category.block_size] + 1}`;
        } else {
          result = `${parentCategory.block_size + 1}/${add + 1}`;
          parentCategory.block_size = add;
        }
      } else {
        parentCategory.block_size = sizes[category.block_size];
        result = `1/${parentCategory.block_size + 1}`;
      }
      return result;
    },
    miniBannersLength(value) {
      this.hasMiniBanners = value;
    },
    bannersLength(value) {
      this.hasBanners = value;
    },
    onLocaleChange() {
      console.log('onLocaleChange');

      this.fetchCategories();
    },

    openSupport() {
      nativeBridge.hapticSelection();
      this.$eventBus.emit('eb_open_support_modal');
    },
    closeMiniApp() {
      Analitycs.logEvent(EVENTS.CROSS_EXIT, { from: 'main' });
      nativeBridge.hapticSelection();
      nativeBridge.backToNativeApp();
    },
    onClose() {
      nativeBridge.hapticSelection();
      if (this.$store.getters['cart/cartSize']) {
        Analitycs.logEvent(EVENTS.CROSS_EXIT_CONFIRM);
        this.popup.show('confirm-close-cart');
        return;
      }

      this.closeMiniApp();
    },
    goToSearch() {
      nativeBridge.hapticSelection();
      Analitycs.logEvent(EVENTS.SEARCH_CLICKED, { page: 'main' });
      this.$router.push({ name: 'search' });
    },
    goToReferral() {
      this.$router.push({ name: 'referral-share' });
    },
    // onLoyaltyClick() {
    //   if (this.$store.getters['isAuthorized']) {
    //     this.$eventBus.emit('eb_open_loyalty_info_modal', {
    //       count: this.$store.getters['loyalty/count'],
    //     });
    //   } else {
    //     AuthService.authorize().then(() => {
    //       this.$store.dispatch('INIT_AUTHORIZED_FLOW');
    //     });
    //   }
    // },
    openFreedomCashbackModal() {
      this.$eventBus.emit('eb_open_freedom_cashback_modal');
    },
  },
  i18n: {
    messages: {
      ru: {},
      kk: {},
    },
  },
};
</script>

<style scoped>
.main-referral-plank-desk {
  @apply w-full;
}
.main-header-desk {
  @apply w-full sticky top-0 z-modal;
}
.--fixed.main-header-desk {
  @apply fixed;
}
</style>

import dayjs from 'dayjs';
import nativeBridge from '@shared/utils/native-bridge';
import { store } from '@/store';

/**
 * Так как вся лояльность реализована на фронте,
 * предполагаем, что ТОЛЬКО на заказы, оформленные с этой даты
 * начислен кешбек лояльности
 */
const LOYALTY_START = '2024-11-05';

/**
 *
 * @param order
 * @param byTotalOnly Ох... На странице заказов активные заказы приходят сначала
 *                    из общего эндпоинта, который не возвращает товары в заказе,
 *                    а затем для каждого заказа отправляется запрос уже
 *                    на конкретный заказ.
 *                    Если не передавать это, то на странице заказов количество
 *                    бонусов будет прыгать
 * @return {number}
 */
export function getOrderLoyaltyCount(order = {}, byTotalOnly = false) {
  if (!nativeBridge.isWebView() || !store.getters['loyalty/available'])
    return 0;

  if (!order) return 0;

  const { status, price = 0, delivery_price = 0, service_fee = 0 } = order;

  if (dayjs(order.paid_at).isBefore(LOYALTY_START)) return 0;

  if ([8, 9].includes(status)) return 0;

  /**
   * За товары, за которые вернули деньги, баллы лояльности не начисляются.
   * Но на странице со списком заказов список товаров в заказе не возращается,
   * поэтому для этого корнер кейса пока возвращаем
   */
  let orderSum = price;
  if (order.items && !byTotalOnly) {
    orderSum = order.items.reduce(
      // если поле статус не null, значит товар в процессе или за него уже вернули деньги
      // — за него бонусы не начисляются
      (acc, it) => (it.status ? acc : acc + it.price * it.quantity),
      0
    );
  }

  return Math.round((orderSum + delivery_price + service_fee) * 0.01);
}

import { store } from '@/store';
import { WebApp } from '@shared/WebApp';
import nativeBridge from '@shared/utils/native-bridge';
import { DevConfigs } from '@/utils/dev-config';
import { $log } from '@/utils/plugins/logger';

export const GeoStatuses = ['granted', 'notDetermined', 'denied'];

let lastGeoStatus = '';
let lastGeoPosition = null;

/**
 * Сохраняем локацию юзера в Store
 * @param {Object} location
 */
function setUserLocationToStore(location) {
  store.commit('user/SET_USER_LOCATION', location);
}

/**
 * @typedef Location
 * @prop {number} latitude
 * @prop {number} longitude
 * @prop {number} accuracy
 *
 * Получаем локацию юзера
 * @returns {Promise<Location>}
 */
function getGeoLocation() {
  return new Promise((resolve, reject) => {
    if (nativeBridge.hasMethod('getCachedGeoPosition')) {
      let res = nativeBridge.getCachedGeoPosition(
        JSON.stringify({ maximum_age: 5 * 60 * 1000 })
      );
      $log('getCachedGeoPosition res', res);
      if (res && res !== 'denied') {
        res = JSON.parse(res);
        let pos = {
          latitude: res.latitude,
          longitude: res.longitude,
          timestamp: res.timestamp,
        };
        lastGeoPosition = pos;
        setUserLocationToStore(pos);
        return resolve(pos);
      }
    }
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        let pos = {
          latitude: coords.latitude,
          longitude: coords.longitude,
          accuracy: coords.accuracy,
        };
        lastGeoPosition = pos;
        setUserLocationToStore(pos);
        resolve(pos);
      },
      err => {
        reject(err);
      },
      {
        // В вебвью хрома на андроиде не учитывается
        enableHighAccuracy: true,
        // Если поставить маленькое значение, то если браузер не успел получить
        // его за этот промежуток, вернётся ошибка.
        // На тестовом устройстве время получения от 1 до 3 секунд.
        timeout: 5000,
        // Разрешаем 5 минутный кеш.
        // В итоге это нигде не работает:
        // на ios запрос гео идёт постоянно в фоне, поэтому работает быстро,
        // а андроид хром не учитывает опцию maximumAge
        // https://issues.chromium.org/issues/41346868
        maximumAge: 1000 * 60 * 10,
      }
    );
  });
}

/**
 * Делает запрос на разрешение сбора геолокации
 * @returns {Promise<String>}
 */
function requestPermission() {
  if (DevConfigs.isDev) {
    return WebApp.requestGeoPermissionStatus();
    // return new Promise(resolve => {
    //   setTimeout(resolve('granted'), 1000);
    // });
  }

  if (nativeBridge.isWebView())
    return nativeBridge.requestGeoPermissionStatus();

  return WebApp.requestGeoPermissionStatus();
}

function requestGeoPermission() {
  return requestPermission()
    .then(res => {
      lastGeoStatus = res;
      return res;
    })
    .catch(() => {
      lastGeoStatus = 'denied';
      return 'denied';
    });
}

/**
 * Возвращает информацию о локации юзера, или же ошибку
 *
 * @prop {number} latitude
 * @prop {number} longitude
 * @prop {number} accuracy
 *
 * @returns {Promise<Location|Error>}
 */
function getUserLocation() {
  return requestPermission().then(res => {
    if (res === 'granted' || res === 'notDetermined') return getGeoLocation();
    return Promise.reject(new Error('Geo denied'));
  });
}

function getLastGeoStatus() {
  if (lastGeoStatus) return Promise.resolve(lastGeoStatus);
  return requestGeoPermission();
}

function getLastGeoPosition() {
  if (lastGeoPosition) return Promise.resolve(lastGeoPosition);
  return getUserLocation();
}

export const GeoService = {
  requestGeoPermission,
  getUserLocation,
  getLastGeoStatus,
  getLastGeoPosition,
};

import { client } from '../../clients/client';
import { createCancelableRequestConfig } from '../../clients/utils';
export function rFetchPopularProducts(warehouseId = 1, limit = 102) {
  const { cancelToken, onFinish } = createCancelableRequestConfig();
  return client
    .get(`/lavka-favorites/v1/bestsellers`, {
      params: {
        warehouse_id: warehouseId,
        limit,
      },
      cancelToken,
    })
    .then(response => response.data)
    .finally(onFinish);
}

<template>
  <div
    class="product-list-mobile overflow-x-auto overflow-y-hidden hidden-scrollbar"
    @scroll="onScrollDebounced"
  >
    <div ref="slidesContainer" class="inline-flex pl-2 pr-4 pt-4">
      <ProductCardWrapper
        v-for="(product, index) in products"
        :key="`${index}_${product.id}`"
        :product="product.product_info ? product.product_info : product"
        :added-from="addedFrom"
        :is-sample="isSample"
        class="ml-2"
        style="width: 109px; flex-shrink: 0"
      />
      <SeeMore
        class="ml-2"
        style="width: 109px; height: 234px; flex-shrink: 0"
        :navigation="navigation"
      />
    </div>
  </div>
</template>

<script>
import ProductCardWrapper from '@shared/components/product/ProductCardWrapper.vue';
import debounce from 'debounce';

import SeeMore from './SeeMore.vue';

export default {
  name: 'ProductListMobile',
  components: {
    ProductCardWrapper,
    SeeMore,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    addedFrom: {
      type: String,
      required: true,
    },
    navigation: {
      type: Object,
      default: () => {
        return { name: 'main' };
      },
    },
    isSample: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    viewed: 0,
  }),
  watch: {
    viewed(v) {
      this.$emit('onCardsViewed', v);
    },
  },
  mounted() {
    this.countViewed();
    this.$emit('onCardsViewed', this.viewed);
  },
  methods: {
    countViewed() {
      const { children } = this.$refs.slidesContainer;
      const count = children.length;
      for (let i = count - 1; i > 0; i -= 1) {
        if (i <= this.viewed) {
          break;
        }
        const box = children[i].getBoundingClientRect();
        if (box.x > 0) {
          this.viewed = i;
          break;
        }
      }
    },
    // eslint-disable-next-line prefer-arrow-callback
    onScrollDebounced: debounce(function onScrollDebounced() {
      this.countViewed();
    }, 100),
  },
};
</script>

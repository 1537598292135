<template>
  <div
    class="FAQView faq-view"
    :class="$isWebView ? 'mt-6 md:mt-0' : 'mt-2 md:mt-0'"
  >
    <CommercialBanners
      v-if="banners.length"
      class="faq-banners"
      :banners="banners"
      :desktop-max-per-screen="4"
      @onStoryEnd="$emit('onStoryEnd', $event)"
      @onBannerClick="$emit('onBannerClick', $event)"
      @onCategoryButtonClick="$emit('onCategoryButtonClick', $event)"
    />
    <div v-else class="animate-pulse flex pl-4">
      <div
        v-for="item in 3"
        :key="item"
        class="h-[126px] w-[103px] mr-3 bg-sirius-gray-200 rounded-xl"
      ></div>
    </div>
    <div id="faq-content">
      <div v-if="faqs.length" class="faq-content">
        <FAQCategory
          v-for="(cat, catId) in faqs"
          :id="cat.category_id"
          :key="`cat_${catId}`"
          class="faq-category"
        >
          <template #title>
            <p>{{ cat.title }}</p>
          </template>
          <FAQQuestion
            v-for="question in cat.questions"
            :key="question.id"
            :question-id="question.id"
            :anchor-id="anchorId"
            @open="onQuestionOpen(question)"
          >
            <template #question>{{ question.question }}</template>
            <template #answer>
              <div v-html="question.answer"></div>
            </template>
          </FAQQuestion>
        </FAQCategory>
      </div>
      <div v-else class="px-4 mt-11">
        <div
          class="animate-pulse h-4 bg-sirius-gray-200 rounded-2xl w-2/3 mb-6"
        ></div>
        <div
          v-for="item in 8"
          :key="item"
          class="animate-pulse pt-4 pb-5 border-b-1 border-sirius-gray-200 flex items-center"
        >
          <div class="h-4 bg-sirius-gray-200 rounded-2xl w-2/3"></div>
          <div
            class="w-6 h-6 bg-sirius-gray-200 rounded-full animate-pulse ml-auto"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommercialBanners from '@pure-ui/components/CommercialBanners/CommercialBanners.vue';
import FAQCategory from './components/FAQCategory.vue';
import FAQQuestion from './components/FAQQuestion.vue';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
export default {
  name: 'FAQView',
  components: { CommercialBanners, FAQCategory, FAQQuestion },
  props: {
    banners: {
      type: Array,
      default: () => [],
    },
    faqs: {
      type: Array,
      default: () => [],
    },
    anchorId: {
      type: String,
      default: null,
    },
  },
  methods: {
    onQuestionOpen(question) {
      Analitycs.logEvent(EVENTS.FAQ_QUESTION_VIEWED, {
        topic: question.question,
      });
    },
  },
};
</script>

<style scoped>
/* .faq-view {
  @apply;
}
.faq-banners {
  @apply;
} */
.faq-content {
  @apply mt-12 px-4;
}
.faq-category {
  @apply mt-10;
}
</style>

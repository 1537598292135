export const capitalize = word => {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
};

/**
 * Функция для того что б срезать текст по его длине и добавить троеточие
 * @param {String} text string which will cutted
 * @param {Number} end end index of string
 * @param {Number} start start index of string
 * @returns
 */
export const cutTextAddDots = (text, end = 28, start = 0) => {
  if (text?.length > end) return `${text.slice(start, end)}...`;
  return text;
};

import { client } from '../../clients/client';
import { createCancelableRequestConfig } from '../../clients/utils';
export default function (warehouseId) {
  const { cancelToken, onFinish } = createCancelableRequestConfig();
  return client
    .get(`lavka/v1/user/profile?warehouse_id=${warehouseId}`, {
      useDeviceId: true,
      cancelToken,
    })
    .then(res => res.data)
    .finally(onFinish);
}

import { $log } from '@/utils/plugins/logger';
import axios from 'axios';

export const pendingTokens = new Set();
const tokenIds = new WeakMap();

export function createCancelableRequestConfig() {
  const source = axios.CancelToken.source();
  pendingTokens.add(source);

  // Generate a unique ID for this token
  const id = `token_${Math.random().toString(36).substr(2, 9)}`;
  tokenIds.set(source, id);

  $log(
    'Created cancelable request',
    {
      pendingCount: pendingTokens.size,
      tokenId: id,
      stack: new Error().stack,
    },
    'RequestManager'
  );

  return {
    cancelToken: source.token,
    onFinish: () => {
      const wasInSet = pendingTokens.has(source);
      pendingTokens.delete(source);
      const id = tokenIds.get(source);

      $log(
        'Request finished',
        {
          pendingCount: pendingTokens.size,
          tokenId: id,
          wasInSet,
          stack: new Error().stack,
        },
        'RequestManager'
      );
    },
  };
}

export function cancelAllPendingRequests() {
  const count = pendingTokens.size;
  const sources = Array.from(pendingTokens);

  $log(
    'Starting to cancel all pending requests',
    {
      count,
      tokenIds: sources.map(s => tokenIds.get(s)),
    },
    'RequestManager'
  );

  for (const source of sources) {
    source.cancel('Request cancelled');
    const id = tokenIds.get(source);
    $log(
      'Cancelled request',
      {
        tokenId: id,
      },
      'RequestManager'
    );
  }
  pendingTokens.clear();

  $log(
    'Cancelled all pending requests',
    {
      cancelledCount: count,
      tokenIds: sources.map(s => tokenIds.get(s)),
    },
    'RequestManager'
  );
}

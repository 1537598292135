<template>
  <div class="ProductsReviewWrapper">
    <ProductReviewReminder :imgs="images" @click.native="openModal" />

    <ProductReviewModal
      ref="reviewModal"
      v-model="showModal"
      :products="reviewProducts"
      :more="hasMore"
      @onDone="onDone"
      @onChangeSlide="onChangeSlide"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { ProductReviewService } from '@services/product-review';
import ProductReviewReminder from '@pure-ui/components/ProductReview/ProductReviewReminder/ProductReviewReminder.vue';
import ProductReviewModal from '@pure-ui/components/ProductReview/ProductReviewModal/ProductReviewModal.vue';
import NoPhoto from '@img/no-photo.png';
import nativeBridge from '@shared/utils/native-bridge';

export default {
  name: 'ProductsReviewWrapper',
  components: { ProductReviewReminder, ProductReviewModal },
  inject: ['toast'],
  props: {
    screen: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters('productReview', ['reviewProducts', 'hasMore']),
    images() {
      return [...this.reviewProducts].splice(0, 3).map(p => {
        if (p.path) return p.path;
        if (p.images && p.images[0]) return p.images[0].path;
        return NoPhoto;
      });
    },
    reviewProductIds() {
      return this.reviewProducts.map(r => r.id);
    },
  },
  mounted() {
    this.logAnalytics(EVENTS.REVIEW_SHOWN);
  },
  methods: {
    logAnalytics(event, data) {
      Analitycs.logEvent(event, {
        type: 'multiple',
        screen: this.screen,
        reviewProductIds: this.reviewProductIds,
        ...data,
      });
    },
    validate(reviewResults) {
      return reviewResults.some(r => r.review);
    },
    openModal() {
      this.showModal = true;
      this.logAnalytics(EVENTS.REVIEW_OPEN);
    },
    closeModal() {
      this.showModal = false;
    },
    onDone(reviewResults, more) {
      if (!more) this.closeModal();
      let hasChanges = true;
      if (!this.validate(reviewResults)) hasChanges = false;

      this.$log('onDone', reviewResults, more);

      reviewResults.forEach(r => {
        if (hasChanges) {
          this.logAnalytics(EVENTS.REVIEW_DONE, {
            product_id: r.id,
            product_name: r.name,
            review: r.review,
            comment: r.comment,
            callBack: r.callBack,
            isMore: more,
            isSkipped: r.isSkipped,
          });
        }
      });

      // setTimeout нужен для то го что б подождать закрытия модалки(анимация)
      setTimeout(() => {
        ProductReviewService.submitReview(reviewResults).then(() => {
          if (hasChanges)
            this.toast.show(this.$t('thanksForReview'), 'success');

          this.logAnalytics(EVENTS.REVIEW_CLOSE, {
            isMore: more,
            hasReviews: hasChanges,
          });
          if (this.$refs.reviewModal) this.$refs.reviewModal.reset();
        });
      }, 300);
    },
    onChangeSlide() {
      if (this.$isWebView) nativeBridge.hapticSelection();
    },
  },
  i18n: {
    messages: {
      kk: {
        thanksForReview: 'Бағалағаныңызға рақмет!',
      },
      ru: {
        thanksForReview: 'Спасибо за отзыв!',
      },
    },
  },
};
</script>

<style></style>

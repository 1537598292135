<template>
  <div
    class="flex rounded-2xl p-4 pb-5 freedom-cashback-banner-wrapper"
    @click="$eventBus.emit('eb_open_freedom_cashback_modal', { from })"
  >
    <div>
      <p
        class="font-medium text-sm leading-4 freedom-cashback-banner-wrapper__title"
        v-html="$t('mainText')"
      ></p>
      <p class="font-medium text-sm leading-4 text-sirius-gray-1000 mt-1">
        {{ $t('addText') }}
      </p>
    </div>
    <UiInlineIcon class="text-sirius-gray-1000" name="arrow-right-md" />
  </div>
</template>
<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  components: {
    UiInlineIcon,
  },
  props: {
    from: {
      type: String,
      default: null,
    },
  },
  i18n: {
    messages: {
      kk: {
        mainText:
          'Осы тапсырысқа Freedom Bank картасымен төлесеңіз, 10%-дан 17%-ға дейін үнемдейсіз.',
        addText: 'Сома картаға кешбэк түрінде қайтарылады.',
      },
      ru: {
        mainText:
          'Вы экономите от 10% до 17% на этом заказе при&nbsp;оплате картой Freedom Bank.',
        addText: 'Сумма вернётся на карту в виде кешбэка.',
      },
    },
  },
};
</script>
<style>
.freedom-cashback-banner-wrapper {
  background: linear-gradient(
    139.92deg,
    rgba(220, 247, 250, 0.3) -2.18%,
    rgba(157, 206, 205, 0.3) 91.8%
  );
}
.freedom-cashback-banner-wrapper__title {
  background: linear-gradient(
    90deg,
    #0486ba,
    #06c198
  ); /* 90deg = left to right */
  background: -webkit-linear-gradient(
    left,
    #0486ba,
    #06c198
  ); /* Webkit fallback */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>

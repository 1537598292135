import { client } from '@shared/services/api/clients/client';
import { createCancelableRequestConfig } from '@shared/services/api/clients/utils';
import { store } from '@/store';
import { eventBus } from '@/utils/plugins/event-bus';
import { BUS_EVENTS, eventBus as eventbus } from '@shared/utils/event-bus';
function getProductsByCategoryId(payload) {
  const { cancelToken, onFinish } = createCancelableRequestConfig();
  return client
    .get('/lavka/v1/catalog', {
      params: payload,
      cancelToken,
    })
    .then(response => response.data.data)
    .finally(onFinish);
}

function fetchSpecialCategory() {
  const wId = store.getters['delivery/warehouseId'];
  if (wId === 5) {
    store.dispatch('specialCategory/FETCH_SPECIAL_CATEGORY');
  }
}

function init() {
  fetchSpecialCategory();
  eventBus.on('eb_on_reconnect_to_app', fetchSpecialCategory);
  eventbus.$on(BUS_EVENTS.LOCALE_CHANGE, fetchSpecialCategory);
  eventBus.on('eb_on_address_change', fetchSpecialCategory); // переделать на eb_on_warehouse_change после фикса
  eventBus.on('eb_on_address_update', fetchSpecialCategory); // переделать на eb_on_warehouse_change после фикса
  eventBus.on('eb_init_page_load_done', fetchSpecialCategory);
}

export const CatalogService = {
  getProductsByCategoryId,
  init,
};

<template>
  <div class="px-1 flex items-center cursor-pointer">
    <UiInlineIcon
      :name="$isDesktop ? 'info-icon-desk' : 'info-icon'"
      class="text-sirius-gray-1000 md:hover:text-black"
    />
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'CheckoutTextConditions',
  components: {
    UiInlineIcon,
  },
  data() {
    return {
      hovered: false,
    };
  },
  methods: {
    mouseOver() {
      this.hovered = true;
    },
    mouseLeave() {
      this.hovered = false;
    },
  },
};
</script>

import nativeBridge from '@shared/utils/native-bridge';
import { getAuthUrl, getRedirectUri } from './utils';

function authorizeV1() {
  return nativeBridge.authorize().then(res => {
    return { trackId: res };
  });
}

function authorizeV2() {
  const body = {
    client_id: import.meta.env.VITE_CHOCO_GATEWAY_CLIENT_ID,
    redirect_uri: getAuthUrl(),
    response_type: 'code',
    state: getRedirectUri(),
  };
  return nativeBridge.authorizeV2(body).then(res => {
    return { code: res.code };
  });
}

const login = () => {
  const isV2 = nativeBridge.authVersion() === 'v2';
  return isV2 ? authorizeV2() : authorizeV1();
};

export const MobAuthService = {
  login,
};

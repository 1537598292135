<template>
  <UiButton size="LG" class="flex justify-between" @click="onClick">
    <p class="pl-1">{{ $t('goToCheckout') }}</p>
    <p class="ml-auto pr-1">
      {{ formatPrice(price) }}
      ₸
    </p>
    <FreedomCashbackBadge
      v-if="$isWebView"
      class="right-6 top-12"
      :price="price"
    />
  </UiButton>
</template>

<script>
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { formatPrice } from '@shared/utils/formatters/price';
import nativeBridge from '@shared/utils/native-bridge';
import { mapGetters } from 'vuex';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import FreedomCashbackBadge from '@pure-ui/components/FreedomCashback/FreedomCashbackBadge.vue';

export default {
  name: 'GoToCartButton',
  components: {
    UiButton,
    FreedomCashbackBadge,
  },
  props: {
    price: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('cart', ['cartTotalPriceWithDiscount']),
    ...mapGetters('samplings', ['samplingTotalPrice']),
    ...mapGetters('promocodes', ['availablePromocodeGiftProduct']),
  },
  methods: {
    formatPrice,
    onClick() {
      nativeBridge.hapticSelection();
      Analitycs.logEvent(EVENTS.GO_TO_CART, {
        pageName: this.$route.name,
        from: 'Button Checkout Reminder',
      });
      this.$router.push({ name: 'cart' });
    },
  },
  i18n: {
    messages: {
      ru: {
        goToCheckout: 'Перейти в корзину',
      },
      kk: {
        goToCheckout: 'Себетке өту',
      },
    },
  },
};
</script>

import { store } from '@/store';
import {
  rFetchReviewProducts,
  rSubmitReviewProducts,
} from '@shared/services/api/requestsV2';
import { $log } from '@/utils/plugins/logger';
import nativeBridge from '@shared/utils/native-bridge';
import { ProductBDService } from '@services/product-db';
import { AUTH_EVENTS, authEmitter } from '@shared/services/auth/events';

function setReviewsData(response) {
  store.commit('productReview/SET_REVIEWS_DATA', response);
}
function getReviewsData() {
  return store.getters['productReview/reviewsData'];
}

/**
 * Возравщяет товар если по этому товару можно оставить отзыв, иначе undefined
 * @param {Number} id
 * @returns {Product | undefined}
 */
function getReviewableProduct(id) {
  return getReviewsData().find(r => r.product_id === id);
}

function hasReviews(reviews) {
  return reviews.some(r => r.review);
}

/**
 * @typedef ReviewProduct
 * @prop {number} id product_id
 * @prop {string} name_kk product name kz
 * @prop {string} name product name
 * @prop {string} img 'https://lavka.object.pscloud.io/static/upload/images/lavka/products/1653474715-791.png'
 * @prop {string} review 'like' | 'dislike' | ''
 * @prop {string} comment ''
 * @prop {boolean} callBack true/false
 * @prop {boolean?} isSkipped true/false если юзер увидел и пропустил будет true
 *
 */
/**
 * Метод нужен для того что бы сохранить всю оценку по товарам который юзер сделал с валидацией,
 * даже если пропустил должны сохранить isSkipped, и в этои сессиий ее не показывать
 *
 * И возвращяет только те эелеменыты которые были обновлены для того что б сделать сабмит в бек
 * @param {ReviewProduct[]} newReviewProducts это вся оценка товаров
 * @returns {ReviewProduct[]} возвращяет только обновленные оценки для тоого что б за сабмитить
 */
function updateReviewsData(newReviewProducts) {
  let updatedReviewsPayloadForBackEnd = [];
  let hasChanges = hasReviews(newReviewProducts);
  let updatedList = getReviewsData().map(oldReview => {
    let reviewPayload = newReviewProducts.find(
      pr => pr.id === oldReview.product_id
    );
    if (reviewPayload) {
      let payload = {
        id: oldReview.id,
        product_id: oldReview.product_id,
        review: reviewPayload.review,
        comment: reviewPayload.comment || '',
        callBack:
          reviewPayload.review !== 'like' && (reviewPayload.callBack || false),
        isSkipped: reviewPayload.isSkipped === true,
      };
      updatedReviewsPayloadForBackEnd.push({ ...payload });
      // если юзер не отметил никакой из товаров
      // то мы эти товары не убираем из виду
      // но отправляем в бэк как skipped
      if (!hasChanges) payload.isSkipped = false;

      return payload;
    }
    return oldReview;
  });

  setReviewsData(updatedList);
  return updatedReviewsPayloadForBackEnd;
}

/**
 * Тело для запроса
 */
function buildPayload(reviews) {
  let reviewMap = {
    like: 'positive',
    dislike: 'negative',
    '': 'skipped',
  };
  let updated = updateReviewsData(reviews);
  return (
    updated
      /** нужно что б не сохранять в базе те оценки до который юзер даже не проскролил */
      .filter(r => r.review || r.isSkipped)
      .map(r => {
        return {
          ...r,
          review: reviewMap[r.review],
          call_back: r.callBack,
        };
      })
  );
}

/**
 * Отправляем отзыв пользователя по товару
 *
 * - Формируем body
 * - Матчим айдишки отзывов с айдишками товара
 * - И сам ответ пользователя review, comment, call_back
 * @param {Review[]} reviews
 * @returns
 */
function submitReview(reviews) {
  $log('ProductReviewService submitReview', reviews);

  let body = buildPayload(reviews);

  $log('ProductReviewService body', body);
  return rSubmitReviewProducts(body)
    .then(res => {
      $log('ProductReviewService then', res);
    })
    .catch(err => {
      $log('ProductReviewService catch', err);
    });
}

/** Фильтрует те отзывы по которым есть информация о продукте */
function filterReviews(reviews, products) {
  return reviews.filter(r => products.find(p => p.id === r.product_id));
}

/** Заменяет старые отзывы на новые,
 * если есть в старых какие то изменения то их сохраняет
 *
 * Нужно что б все что выбрал юзер сохранялось
 * а то при каждом сварачиваний и возвращений
 * пользователь снова получает данные по отзывам и они заменяют старые данные
 * такого не должно быть */
function mergeReviews(oldReviews, newReviews) {
  $log('ProductReviewService mergeReviews', { oldReviews, newReviews });
  if (!newReviews || !newReviews.length) return [];
  oldReviews = oldReviews || [];
  newReviews = newReviews || [];

  const oldReviewMap = new Map();
  const reviewMap = new Map();
  oldReviews.forEach(review => {
    oldReviewMap.set(review.id, review);
  });
  newReviews.forEach(review => {
    let old = oldReviewMap.get(review.id);
    if (old) reviewMap.set(review.id, { ...old, ...review });
    else reviewMap.set(review.id, review);
  });

  const mergedReviews = Array.from(reviewMap.values());
  return mergedReviews;
}

function fetchReviewProducts() {
  $log('ProductReviewService rFetchReviewProducts');
  let reviewsData = [];

  let isMock = window.localStorage.getItem('product-review::mocks') || false;

  rFetchReviewProducts(isMock)
    .then(res => {
      let ids = res.data.items.map(r => r.product_id);
      reviewsData = res.data.items;
      $log('ProductReviewService rFetchReviewProducts', { res, ids });
      return ProductBDService.loadProductsByIds(ids);
    })
    .then(products => {
      let oldReviews = getReviewsData();
      let filteredReviews = filterReviews(reviewsData, products);
      let mergedReviews = mergeReviews(oldReviews, filteredReviews);
      $log('ProductReviewService products', {
        products,
        reviewsData,
        oldReviews,
        filteredReviews,
        mergedReviews,
      });
      setReviewsData(mergedReviews);
    })
    .catch(err => {
      console.error(err);
    });
}

/**
 * Получает и сохраняет товары по которым есть доступ для оставления отзывов.
 *
 * - В запросе получаем только айдишки заявки на отзыв и айдишки продукта
 * - Сохраняем респонс от бека потому что нужен будет для матчинга айдишек заявки на отзыв с айдишками продукта
 * - Cоответсвенно инфу о продукте получаем от сервиса ProductBDService
 * - И сохранаем инфу продуктов для отзыва в Store
 * - Работает только для Миниапки
 */
function init() {
  if (!nativeBridge.isWebView()) return;

  authEmitter.on(AUTH_EVENTS.ON_AUTH, fetchReviewProducts);
  if (!store.state.isAuthorized) return;

  fetchReviewProducts();
}

/**
 * Сервис для отзывов товара
 */
export const ProductReviewService = {
  getReviewableProduct,
  fetchReviewProducts,
  updateReviewsData,
  submitReview,
  mergeReviews,
  init,
};

<template>
  <div
    class="GiftsPhoto"
    :class="`--${size} ${disabled ? '__disabled' : ''} ${$isDesktop ? '--desk' : ''}`"
  >
    <ProductPhoto class="GiftsPhoto__img" :src="src" />
    <div
      v-if="sticker"
      class="GiftsPhoto__present"
      :class="`--${stickerPosition}`"
    />
  </div>
</template>

<script>
import ProductPhoto from '@pure-ui/components/Product/ProductPhoto/ProductPhoto.vue';

export default {
  name: 'SamplingPhoto',
  components: {
    ProductPhoto,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'sm',
    },
    sticker: Boolean,
    stickerPosition: {
      type: String,
      default: 'bottom-right',
      validator: value => ['bottom-right', 'top-left'].includes(value),
    },
    disabled: Boolean,
  },
  data() {
    return {
      IconPresentBox: new URL('./img/present-box.svg', import.meta.url).href,
    };
  },
};
</script>

<style scoped>
.GiftsPhoto {
  position: relative;
}

.GiftsPhoto,
.GiftsPhoto__img {
  @apply border-sirius-gray-300;
  border-radius: 24px;
}
.GiftsPhoto {
  border-width: 0.5px;
}

.GiftsPhoto.__disabled :deep(.GiftsPhoto__img),
.GiftsPhoto.__disabled .GiftsPhoto__present {
  filter: grayscale(1);
}
.GiftsPhoto__img {
  overflow: hidden;
}
.--sm.GiftsPhoto {
  border-width: 0;
}
.GiftsPhoto,
.GiftsPhoto__img {
  border-radius: 16px;
}

.GiftsPhoto__present {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 8px;
  right: 8px;

  color: white;
  font-weight: 700;
  text-align: center;

  width: 32px;
  height: 32px;

  background-image: url('./img/sampling-sticker.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.--desk .GiftsPhoto__present {
  width: 24px;
  height: 24px;
}

.--top-left.GiftsPhoto__present {
  top: 4px;
  left: 4px;
  bottom: auto;
  right: auto;
}
</style>

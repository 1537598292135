<template>
  <div class="product-list-mobile">
    <div class="flex gap-3">
      <SamplingPromoCard
        v-for="(sample, index) in products"
        :key="`${index}_${sample.id}`"
        :sample="sample"
        :size="size"
        is-horizontal
      />
    </div>
  </div>
</template>

<script>
import SamplingPromoCard from '@components/samplings/SamplingPromoCard.vue';

export default {
  name: 'SamplingPromoListGrid',
  components: {
    SamplingPromoCard,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    size() {
      if (this.products?.length === 2) {
        return 'sm';
      } else if (this.products?.length === 3) {
        return 'xs';
      }
      return 'normal';
    },
  },
};
</script>

<template>
  <SamplingProduct
    :product="product"
    :sticker="!isDetails"
    class="SamplingProductWrapper"
    :is-details="isDetails"
    @onGoProduct="openSampleConditions"
  />
</template>

<script>
import SamplingProduct from '@pure-ui/components/Sampling/SamplingProduct/SamplingProduct.vue';
import { Logger as LOG } from '@shared/services/logger';
import { SamplingsService } from '@services/samplings';
import { mapGetters } from 'vuex';

export default {
  name: 'SamplingProductWrapper',
  components: {
    SamplingProduct,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    isDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('samplings', ['samples']),
  },
  methods: {
    openSampleConditions() {
      if (this.isDetails) {
        this.openSamplingOrderDetails();
        return;
      }

      const { product } = this;
      const sample = this.samples.find(e => e.id === product.sample_id);
      LOG.event('openSampleConditions', { sample, product }, '');
      if (!sample) return;
      const payload = {
        sample,
        addedProducts: [{ name: '...' }],
        additionalProducts: [],
        sampling: { ...product, finished: true },
      };
      LOG.event('openSampleConditions payload', { payload }, '');

      SamplingsService.openModal(payload);
    },
    openSamplingOrderDetails() {
      const payload = {
        sampling: { ...this.product },
        params: {
          source: 'details',
        },
      };
      SamplingsService.openModal(payload);
    },
  },
};
</script>

<style></style>

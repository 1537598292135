<template>
  <div class="p-4 bg-white mt-auto flex flex-col space-y-2">
    <!-- Total Price of cart -->
    <CheckoutText :disabled="disabled">
      <template #left> {{ $t('productsTotal') }} </template>
      <template #right> {{ productsPrice }} </template>
    </CheckoutText>

    <!-- Discount -->
    <CheckoutText v-if="discountText" :disabled="disabled">
      <template #left> {{ $t('promocode') }} </template>
      <template #right>
        <span :class="{ 'text-sirius-green-200': !disabled }">
          {{ discountText }}
        </span>
      </template>
    </CheckoutText>

    <!-- Delivery -->
    <CheckoutText :disabled="disabled" @click.native="onClick('delivery')">
      <template #left>
        {{ $t('delivery') }}
        <CheckoutTextConditions v-if="!!(delivery.from || delivery.to)" />
      </template>
      <template #right>
        <template v-if="$store.getters['cart/isFreeDeliveryPromocodeApplied']">
          <span class="text-sirius-gray-100 line-through">{{
            deliveryText
          }}</span>
          <span class="text-sirius-green-200 ml-2">{{ $t('free') }}</span>
        </template>
        <template v-else>
          {{ deliveryText }}
        </template>
      </template>
    </CheckoutText>

    <!-- Service Fee -->
    <CheckoutText
      v-if="serviceFee"
      :disabled="disabled"
      @click.native="onClick('serviceFee')"
    >
      <template #left>
        {{ $t('serviceFee') }}
        <CheckoutTextTooltip class="md:px-1">
          <template #tooltip>
            <p
              class="font-medium text-sm leading-4"
              v-html="$t('serviceFeeInfo1')"
            ></p>
            <p
              class="mt-2 font-medium text-sm leading-4"
              v-html="$t('serviceFeeInfo2')"
            ></p>
          </template>
        </CheckoutTextTooltip>
      </template>
      <template #right> {{ serviceFee.price }} ₸ </template>
    </CheckoutText>

    <!-- Delivery Time -->
    <CheckoutText
      :disabled="disabled || disableTime"
      @click.native="onClick('time')"
    >
      <template #left>
        {{ $t('deliveryTime') }}

        <CheckoutTextTooltip v-if="isDeliveryDistant">
          <template #tooltip>
            <p class="font-bold text-sm leading-4" v-html="$t('timeInfo1')"></p>
            <p
              class="mt-2 font-normal text-sm leading-4"
              v-html="$t('timeInfo2')"
            ></p>
          </template>
        </CheckoutTextTooltip>
      </template>
      <template #right> {{ deliveryTime }} </template>
    </CheckoutText>
  </div>
</template>

<script>
import CheckoutTextConditions from './CheckoutTextConditions.vue';
import CheckoutTextTooltip from './CheckoutTextTooltip.vue';
import CheckoutText from './CheckoutText.vue';

export default {
  name: 'CheckoutInfoList',
  components: {
    CheckoutTextConditions,
    CheckoutTextTooltip,
    CheckoutText,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    disableTime: Boolean,
    productsPrice: {
      type: String,
      default: '',
    },
    discount: {
      type: Number,
      default: 0,
    },
    deliveryTime: {
      type: String,
      default: '',
    },
    delivery: {
      type: Object,
      default: () => {
        return { price: 0 };
      },
    },
    isDeliveryDistant: {
      type: Boolean,
      default: false,
    },
    serviceFee: {
      type: Object,
      default: null,
    },
  },
  computed: {
    discountText() {
      return this.discount ? `- ${this.discount} ₸` : '';
    },
    deliveryText() {
      if (!this.delivery.price) return this.$t('free');
      if (this.disabled && !this.$store.getters['cart/isFreeDeliveryPromocodeApplied']) {
        return this.$t('discountFromTo', {
          from: this.delivery.from,
          to: this.delivery.to,
        });
      }
      return `${this.delivery.price} ₸`;
    },
  },
  methods: {
    onClick(value) {
      if (value === 'delivery' && (this.delivery.from || this.delivery.to)) {
        this.$emit('onConditionsClick', 'delivery');
      }

      if (this.$isDesktop) return;

      if (value === 'serviceFee') {
        this.$emit('onConditionsClick', 'serviceFee');
      }
      if (value === 'time' && this.isDeliveryDistant) {
        this.$emit('onConditionsClick', 'time');
      }
    },
  },
  i18n: {
    messages: {
      ru: {
        productsTotal: 'Товары на сумму',
        promocode: 'Промокод',
        delivery: 'Доставка',
        deliveryTime: 'Время доставки',
        free: 'бесплатно',
        discountFromTo: 'от {from} до {to} ₸',
        serviceFee: 'Сервисный сбор',
        serviceFeeInfo1: `Помогает доставлять быстрее, улучшать ассортимент товаров <span class="whitespace-nowrap"> и службу </span> заботы <span class="whitespace-nowrap"> о пользователях. </span>`,
        serviceFeeInfo2: `Стоимость фиксированная.`,

        timeInfo1: 'Расширенная зона доставки',
        timeInfo2: `Привезем заказ за
              <span class="whitespace-nowrap"> 30 – 40 минут,</span> так как
              адрес находится
              <span class="whitespace-nowrap"> не в основной зоне </span>
              доставки`,
        scoreCount: '0 баллов | {n} балл | {n} балла | {n} баллов',
      },
      kk: {
        productsTotal: 'Тауар сомасы',
        promocode: 'Промокод',
        delivery: 'Жеткізу',
        deliveryTime: 'Жеткізу уақыты',
        free: 'тегін',
        discountFromTo: 'от {from} до {to} ₸',
        serviceFee: 'Қызмет ақысы',
        serviceFeeInfo1:
          'Бұл, тапсырысыңызды тезірек жеткізуге, ассортиментімізді жақсартуға көмектеседі.',
        serviceFeeInfo2: 'Бағасы тұрақты.',

        timeInfo1: 'Расширенная зона доставки',
        timeInfo2: `Привезем заказ за
              <span class="whitespace-nowrap"> 30 – 40 минут,</span> так как
              адрес находится
              <span class="whitespace-nowrap"> не в основной зоне </span>
              доставки`,
        scoreCount: '{n} ұпай',
      },
    },
  },
};
</script>

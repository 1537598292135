import { client } from '../../clients/client';
import { isAuthorizedUrl } from '../../utils/is-authorized-url';
import { createCancelableRequestConfig } from '../../clients/utils';
/**
 * Выдача всех АБ тестов юзера
 */

// eslint-disable-next-line camelcase
export default function (isAuthorized = false, segmentId) {
  const { cancelToken, onFinish } = createCancelableRequestConfig();
  return client
    .get(`/lavka-ab/v2${isAuthorizedUrl(isAuthorized)}/get_user_group`, {
      params: {
        segmentId,
      },
      cancelToken,
    })
    .then(({ data }) => data)
    .finally(onFinish);
}

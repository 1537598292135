<template>
  <UserAgreementReminderMobile
    v-if="userAgreementReminder"
    @onClose="closeAgreement"
    @onReady="goToAgreement"
  />
</template>

<script>
import nativeBridge from '@shared/utils/native-bridge';
import {
  setShown,
  canShowReminder,
  logAnalytics,
  STATES,
} from '@components/user-agreement/lib/user-agreement';
import UserAgreementReminderMobile from './UserAgreementReminderMobile.vue';
export default {
  components: { UserAgreementReminderMobile },
  data() {
    return {
      userAgreementReminder: false,
      state: STATES.notViewed,
    };
  },
  created() {
    if (canShowReminder()) {
      this.openAgreement();
      setShown();
    }
  },
  beforeDestroy() {
    logAnalytics(this.state, 'mobile');
  },
  methods: {
    openAgreement() {
      this.state = STATES.viewed;
      this.userAgreementReminder = true;
    },
    closeAgreement(value) {
      this.state = value === 'crestik' ? STATES.crestik : STATES.close;
      nativeBridge.hapticSelection();
      this.userAgreementReminder = false;
    },
    goToAgreement() {
      this.state = STATES.look;
      nativeBridge.hapticSelection();
      this.$router.push({ name: 'docs' });
    },
  },
};
</script>

<style scoped></style>

<template>
  <MainLayout :with-footer="false">
    <template v-if="$isWebView" #header>
      <ToolBar> {{ $t('title') }} </ToolBar>
    </template>
    <ProfileLayout :container-width="788" :has-support="!$isDesktop">
      <FAQView
        :banners="banners"
        :faqs="faqs"
        :anchor-id="$route.query.id"
        @onStoryEnd="onStoryEnd"
      />
    </ProfileLayout>
  </MainLayout>
</template>

<script>
import { FAQService } from '@services/faq';
import nativeBridge from '@shared/utils/native-bridge';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { eventBus, BUS_EVENTS } from '@shared/utils/event-bus';
import { StaticBanners } from '@services/banners/static-banners';

import ProfileLayout from '@shared/components/layout/ProfileLayout.vue';
import FAQView from '@pure-ui/components/FAQ/FAQView.vue';
import MainLayout from '@layout/MainLayout.vue';
import ToolBar from '@layout/ToolBar.vue';

export default {
  name: 'FaqView',
  components: {
    ProfileLayout,
    MainLayout,
    ToolBar,
    FAQView,
  },
  inject: ['toast'],
  data() {
    return {
      banners: [],
      faqs: [],
    };
  },
  beforeDestroy() {
    eventBus.$off(BUS_EVENTS.LOCALE_CHANGE);
  },
  mounted() {
    Analitycs.logEvent(EVENTS.VIEWED_FAQ_PAGE);
    this.init();
    this.initLinkRedirectListener();
    eventBus.$on(BUS_EVENTS.LOCALE_CHANGE, this.onLocaleChange);
  },
  methods: {
    loadBanners() {
      this.banners = this.sortWatched([
        StaticBanners.referralBanner,
        ...Object.values(StaticBanners.faq),
      ]);
    },
    async loadFAQs() {
      this.faqs = await FAQService.fetchQuestions();
      if (!this.faqs.length) this.toast.show(this.$t('nothingFound'));
    },
    async init() {
      this.loadBanners();
      await this.loadFAQs();

      if (this.$route.query?.id) {
        this.handleAnchor();
      }
    },
    onLocaleChange() {
      this.init();
    },
    initLinkRedirectListener() {
      let faqContent = document.querySelector('#faq-content');
      faqContent.addEventListener('click', event => {
        let { target } = event;
        if (target.tagName !== 'A') return;
        event.preventDefault();

        let link = target.href;
        let isInnerLink = link.includes('https://ryadom.kz');
        let isMicromarket = link.includes('micromarket');
        let isFreedomLink = link.includes('https://loyalty.bankffin.kz');

        let isTel = link.includes('tel:');
        let isProfile = link.includes('profile');

        if (isInnerLink && !isMicromarket) {
          link = link.replace('https://ryadom.kz', '');

          if (isProfile && this.$isWebView) link = '/me';

          this.$router.push(link);
          return;
        }

        if (isTel) {
          window.open(link, '_self');
          return;
        }

        if (isFreedomLink) {
          Analitycs.logEvent(EVENTS.FAQ_DETAILED_TERMS_CLICKED, {
            partner: 'Freedom bank',
          });
        }

        if (this.$isWebView) {
          nativeBridge.openLink({ url: link });
          return;
        }

        window.open(link, '_blank');
      });
    },
    sortWatched(arr) {
      return [...arr].sort(a => (a.watched ? 1 : -1));
    },
    onStoryEnd(banner) {
      let copy = [...this.banners];
      copy.find(v => v.code === banner.code).watched = true;
      let sorted = this.sortWatched(copy);
      this.banners = sorted;
    },
    handleAnchor() {
      const question = document.getElementById(this.$route.query.id);
      if (!question) return;
      const y = question.getBoundingClientRect().top + 30; // 30px to consider fixed header
      setTimeout(() => {
        window.scrollTo({ top: y, behavior: 'smooth' });
      }, 100);
    },
  },
  i18n: {
    messages: {
      ru: {
        title: 'Ответы на частые вопросы',
        nothingFound: 'Ничего не найдено',
      },
      kk: {
        title: 'Жиі қойылатын сұрақтар',
        nothingFound: 'Ештеңе табылмады',
      },
    },
  },
};
</script>

<style lang="postcss"></style>
